import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { Modal } from 'react-bootstrap';
import { ImPencil } from "react-icons/im";
import { AiTwotoneDelete } from "react-icons/ai";

import { withRouter } from 'react-router';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import AccessControlOtpService from "../../services/access-control-otp.service"
import SelectOptionService from "../../services/select-options.service";
import { useNavigate, Link, Outlet } from 'react-router-dom';
import PrevisionService from "../../services/prevision.service";
import TransferDetailsService from "../../services/transfer-details.service";
import OrganizationDetailsService from "../../services/organization-details.service";
import logo from '../images/ananda.jpg'


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


const currentUser = AuthService.getCurrentUser();


export default function TransferDetailsList() {

  let navigate = useNavigate();
  const nav = useNavigate();


  if (JSON.parse(localStorage.getItem("user")) == null) {

    //return <Redirect to="/login" />;

    navigate('/login', { replace: true });

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    //return <Redirect to="/login" />;

    navigate('/login', { replace: true });

  }

  if (!currentUser.roles.includes("ROLE_ADMIN")) {
    //return <Redirect to="/unauthorized" />;

  }

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    country: "RWANDA",
    country_destination: "",
    instutution: "",
    orgin_transfer_currency: "RWF",
    fees: 0,
    destination_institution: "",
    partener_address: "",
    partener_charges: 0,
    transfer_currency: "",
    transfer_currency_rate: 0
  };

  const [tutorial, setData] = useState(initialDataState);

  const [currentData, setcurrentData] = useState(initialDataState);
  const [memberdetails, setMemberDetails] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategorySelectedValueType, setOptionCategorySelectedValueType] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [optionCategoryArrayDestinationCountry, setOptionCategoryArrayDestinationCountry] = useState([]);
  const [optionCategoryArrayDestinationInstitution, setOptionCategoryArrayDestinationInstitution] = useState([]);

  const [message, setMessage] = useState("");
  const [organizationDetails, setorganizationDetails] = useState([]);
  const [username, setUsername] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [messageNewPassword, setMessageNewPassword] = useState("");

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [300, 500, 1000];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });

  };

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);

    var data ={
      text1:"EXTERNAL_TRANSFER_INSTITUTION",
      text2:event.target.value
    }

  

  SelectOptionService.getAllSelectedOptionsWithData(data)
    .then((response) => {

    
      setOptionCategoryArrayDestinationInstitution(response.data);

    })
    .catch((e) => {
      console.log(e);
    });
    
  }

  const handleChangeoptionCategoriesType = (event) => {
    setOptionCategorySelectedValueType(event.target.value);

  }

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveData = () => {
    setPage(1);


    const params = getRequestParams(searchTitle, page, pageSize);

    TransferDetailsService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

      })
      .catch((e) => {
        console.log(e);
      });

   getSelectOption();
   getOrganizationDetails();

  };



  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };



  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {

    setLgShow2(true);
    setMessage("");

    setPage(1);

    setcurrentData(initialDataState);


    TransferDetailsService.get(id)
      .then(response => {

        setcurrentData(response.data);

      })
      .catch(e => {
        console.log(e);
      });

  };

  const getSelectOption = () => {

    setPage(1);
   

    var category = "LOCAL_TRANSFER_INSTITUTION";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);

      })
      .catch((e) => {
        console.log(e);
      });

      var category = "EXTERNAL_TRANSFER_COUNTRY";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArrayDestinationCountry(response.data);

      })
      .catch((e) => {
        console.log(e);
      });

  };

  const getOrganizationDetails = () => {
    setPage(1);

    OrganizationDetailsService.get(1)
      .then((response) => {

        setorganizationDetails(response.data);
        console.log(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }



  const remove = (id) => {

    setMessage("");
    setPage(1);

    setcurrentData(initialDataState);


    TransferDetailsService.remove(id)
      .then(response => {

        refreshList();

        setMessage(response.data);


      })
      .catch(e => {
        console.log(e);
      });

  };


  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);

  const registration = () => {
    //props.history.push("/registration/");
  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const updateData = () => {


    setPage(1);

    setMessage("");

    if(currentData.country.length < 4){
      setMessage("Orgin country < 4 letters");
    }else if(currentData.instutution.length < 4 || currentData.instutution===""){
      setMessage("Transfering institution < 4 letters");
    }else if(currentData.fees  >100 || currentData.fees < 0.1){
      setMessage("Fees is > 100 or missing");
    }else if(currentData.destination_institution  < 4|| currentData.destination_institution==="'"){
      setMessage("Destination institution is < 4 letters>g");
    }else if(currentData.partener_address.length < 4 || currentData.partener_address===""){
      setMessage("Partener address  < 4 letters");
    }else if(currentData.partener_charges < 1 || currentData.partener_charges < 0.1 ){
      setMessage("Partener charges  > 1 OR Missing");
    }else if(currentData.transfer_currency .length < 4 || currentData.transfer_currency ===""){
      setMessage("Transfer currency < 4 letters");
    }else if(currentData.transfer_currency_rate  >100|| currentData.transfer_currency_rate < 0.1){
      setMessage("Transfer currency Rate >100");
    }else{

      TransferDetailsService.update(currentData.id, currentData)
      .then(response => {

        setMessage(response.data);
        refreshList();
      })
      .catch(e => {
        console.log(e);
      });

    }

    


  };

  const updateDataReset = () => {


    setPage(1);

    setMessage("");

    

    PrevisionService.updateReset(currentData.id)
      .then(response => {

        setMessage(response.data);
        refreshList();
      })
      .catch(e => {
        console.log(e);
      });



  };

  const save = () => {

    setMessage("");

    

      var data = {
        id: tutorial.id,
        country: tutorial.country,
        country_destination: tutorial.country_destination,
        instutution: tutorial.instutution,
        orgin_transfer_currency: tutorial.orgin_transfer_currency,
        fees: tutorial.fees,
        destination_institution:  tutorial.destination_institution,
        country_destination :optionCategorySelectedValue,
        partener_address: tutorial.partener_address,
        partener_charges: tutorial.partener_charges,
        transfer_currency: tutorial.transfer_currency,
        transfer_currency_rate: tutorial.transfer_currency_rate
      }

      if(data.country.length < 4){
        setMessage("Orgin country < 4 letters");
      }else if(data.instutution.length < 4 || data.instutution===""){
        setMessage("Transfering institution < 4 letters");
      }else if(data.fees  >100 || data.fees < 0.1){
        setMessage("Fees is > 100 or missing");
      }else if(data.destination_institution  < 4|| data.destination_institution==="'"){
        setMessage("Destination institution is < 4 letters>g");
      }else if(data.partener_address.length < 4 || data.partener_address===""){
        setMessage("Partener address  < 4 letters");
      }else if(data.partener_charges < 0.1 || data.partener_charges < 0.1 ){
        setMessage("Partener charges  > 1 OR Missing");
      }else if(data.transfer_currency .length < 4 || data.transfer_currency ===""){
        setMessage("Transfer currency < 4 letters");
      }else if(data.transfer_currency_rate  >100|| data.transfer_currency_rate < 0.1){
        setMessage("Transfer currency Rate >100");
      }
      
      else{

        TransferDetailsService.create(data)
        .then(response => {
          setMessage(response.data);
          refreshList();
          newData();



        })
        .catch(e => {
          console.log(e);
        });

      }

  }

  const newData = () => {
    setSubmitted(true);

    setData(initialDataState);
    
  }

  const goToTransfers=()=>{
    navigate('/transfer', { replace: true });
  }
    

  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
                <tr>
                  <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>{memberdetails.firstname}</td>
                  <td>{memberdetails.lastname}</td>
                </tr>
                <tr>
                  <td>Branch:</td>
                  <td>{memberdetails.account}</td>
                </tr>
                <tr>
                  <td colSpan="2"> <input type="text" value={searchTitle}
                    onChange={onChangeSearchTitle} placeholder="Search" className="search-input"></input> </td>
                  <td><button onClick={findByTitle} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td>
                </tr>
              </tbody>
            </table>



          </div>

          <div className="col-md-3 tool-box">

            <table className=" table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  <td className="tg-0lax"><button onClick={() => setLgShow(true)} className="button-tools">New</button></td>
                  <td className="tg-0lax"><button onClick={goToTransfers} className="button-tools">Transfer</button></td>
                </tr>
                <tr>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
              </tbody>
            </table>
          </div>


          {/* end card box */}



          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>

              <div className="col-md-3 table-title">  TRANSFER DETAILS </div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>



            </div>
            <div className="details">

              <div className="table-wrap">

                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ID</th>
                      <th>COUNTRY</th>
                      <th>COUNTRY DESTINATION</th>
                      <th>INSTUTUTION</th>
                      <th>ORGIN TRANSFER CURRENCY</th>
                      <th>FEES</th>
                      <th>PARTENER</th>
                      <th>PARTENER ADDRESS</th>
                      <th>PARTENER CHARGES</th>
                      <th>TRANSFER CURRENCY</th>
                      <th>TRANSFER CURRENCY RATE</th>
                      <th>RATE LAST UPDATE</th>
                      <th>USERNAME</th>
                      <th>Action</th>


                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.country}</td>
                          <td>{tutorial.country_destination}</td>
                          <td>{tutorial.instutution}</td>
                          <td>{tutorial.orgin_transfer_currency}</td>
                          <td>{tutorial.fees}</td>
                          <td>{tutorial.destination_institution}</td>
                          <td>{tutorial.partener_address}</td>
                          <td>{tutorial.partener_charges}</td>
                          <td>{tutorial.transfer_currency}</td>
                          <td>{tutorial.transfer_currency_rate}</td>
                          <td>{tutorial.transfer_currency_rate_last_updated_date}</td>
                          <td>{tutorial.username}</td>
                          <td><div className="row">

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => openObject(tutorial.id)}
                            > <ImPencil />
                            </button>

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => remove(tutorial.id)}
                            > <AiTwotoneDelete />
                            </button>



                          </div></td>
                        </tr>

                      ))}
                  </tbody>

                </table>

              </div>

            </div>

          </div>
        </div>

      </div>

      <Modal
        size="lg"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        
        <Modal.Body className="modal-bg app-text-font">

        <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Transfer Details  </div></center>

          <div className="dataRow-with-one-column-center">

            <center>

              <div className="details-sm">

                <form className="form form-horizontal ">
                  <div className="form-body">
                    

                    <div className="row">
                      <div className="col-md-5"> <hr /> </div>
                      <div className="col-md-2"><center> <span className="modal-title">ORGIN</span> </center></div>
                      <div className="col-md-5"><hr /></div>
                    </div>

                    <div className="row">

                     <div className="col-md-4 form-label">
                        <label >Country of Orgin</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="country" className="form-control" name="country" placeholder="Country" onChange={handleInputChange} validations={[required]} value={tutorial.country} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Orgin Currency</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="orgin_transfer_currency" className="form-control" name="orgin_transfer_currency" placeholder="Orgin" onChange={handleInputChange} validations={[required]} value={tutorial.orgin_transfer_currency} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label className="app-text-font">Transfer Instutution</label>
                      </div>
                      <div className="col-md-8 form-group">                      
                        <select className="form-control select-option" id="instutution" name="instutution" onChange={handleInputChange} >
                          <option>Select Institution</option>
                          {optionCategoryArray.map((optionCategory) => (
                            <option key={optionCategory.value}>{optionCategory.value}</option>
                          ))}

                        </select>
                      </div>

                      

                      <div className="col-md-4 form-label">
                        <label>Fees in percentage</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="fees" className="form-control" name="fees" placeholder="Fees" onChange={handleInputChange} validations={[required]} value={tutorial.fees} />
                      </div>

                      <br/> <br/> <br/>

                      <hr/>
                      <center className="modal-title">TRANSFER DESTINATION</center>                      
                      <hr/>
                      
                      <div className="col-md-4 form-label">
                        <label>Destination Country</label>
                      </div>
                      <div className="col-md-8 form-group">                       
                                           
                        <select className="form-control select-option" id="country_destination" name="country_destination" onChange={handleChangeoptionCategories} >
                          <option>Select Country</option>
                          {optionCategoryArrayDestinationCountry.map((optionCategory) => (
                            <option key={optionCategory.value}>{optionCategory.value}</option>
                          ))}
                        </select>                      
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Destination Institution</label>
                      </div>
                      <div className="col-md-8 form-group">                       
                                           
                        <select className="form-control select-option" id="destination_institution" name="destination_institution" onChange={handleInputChange} >
                          <option>Select Institution</option>
                          {optionCategoryArrayDestinationInstitution.map((optionCategory) => (
                            <option key={optionCategory.value}>{optionCategory.value}</option>
                          ))}
                        </select>                      
                      </div>

                    
                      <div className="col-md-4 form-label">
                        <label> Address</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="partener_address" className="form-control" name="partener_address" placeholder="Address" onChange={handleInputChange} validations={[required]} value={tutorial.partener_address} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Partener Charges</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="partener_charges" className="form-control" name="partener_charges" placeholder="Charges" onChange={handleInputChange} validations={[required]} value={tutorial.partener_charges} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Transfer Currency</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="transfer_currency" className="form-control" name="transfer_currency" placeholder="Currency" onChange={handleInputChange} validations={[required]} value={tutorial.transfer_currency} />
                      </div>

                      
                      <div className="col-md-4 form-label">
                        <label>Transfer Currency rate</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="transfer_currency_rate" className="form-control" name="transfer_currency_rate" placeholder="Exchange Rate" onChange={handleInputChange} validations={[required]} value={tutorial.transfer_currency_rate} />
                      </div>

                       <div className="col-md-4 form-label">
                        <label>Transfer Currency rate</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="transfer_currency_rate" className="form-control" name="transfer_currency_rate" placeholder="Exchange Rate" onChange={handleInputChange} validations={[required]} value={tutorial.transfer_currency_rate} />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <label className="col-md-8">{message}</label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <div className="col-md-8 form-group">

                        {submitted ? (<button onClick={newData}
                          type="button" className="button-form"> Add</button>) : (<button onClick={save}
                            type="button" className="button-form"> Save</button>)}


                        <button onClick={() => setLgShow(false)}
                          type="button" className="button-form"> Close</button>
                      </div>

                    </div>
                  </div>
                </form>
              </div>

            </center>

          </div>

        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
                <center> <div>Ananda</div> </center>
            </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Body className="modal-bg">

        <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Transfer Details </div></center>

          <div className="dataRow">

            <center>

              <div className="details-sm">

                <form className="form form-horizontal ">
                  <div className="form-body">

                  <div className="row">
                      <div className="col-md-5"> <hr /> </div>
                      <div className="col-md-2"><center> <span className="modal-title">TRANSFER ORGIN</span> </center></div>
                      <div className="col-md-5"><hr /></div>
                    </div>

                    <div className="row">
                    
                                        
                     
                      <div className="col-md-4 form-label">
                        <label >Country of Orgin</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="country" className="form-control" name="country" placeholder="Country" onChange={handleInputChangeEdit} validations={[required]} value={currentData.country} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Orgin Currency</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="orgin_transfer_currency" className="form-control" name="orgin_transfer_currency" placeholder="Orgin" onChange={handleInputChangeEdit} validations={[required]} value={currentData.orgin_transfer_currency} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label className="app-text-font">Transfer Instutution</label>
                      </div>
                      <div className="col-md-8 form-group">                      
                        <select className="form-control select-option" id="instutution" name="instutution" onChange={handleInputChangeEdit} >
                          <option>{currentData.instutution}</option>
                          {optionCategoryArray.map((optionCategory) => (
                            <option key={optionCategory.value}>{optionCategory.value}</option>
                          ))}

                        </select>
                      </div>                      

                      <div className="col-md-4 form-label">
                        <label>Fees in percentage</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="fees" className="form-control" name="fees" placeholder="Fees" onChange={handleInputChangeEdit} validations={[required]} value={currentData.fees} />
                      </div>

                      <br/> <br/> <br/>

                      <hr/>
                      <center className="modal-title">TRANSFER DESTINATION</center>                      
                      <hr/>
                      
                      <div className="col-md-4 form-label">
                        <label>Destination Country</label>
                      </div>
                      <div className="col-md-8 form-group">                       
                                           
                        <select className="form-control select-option" id="country_destination" name="country_destination" onChange={handleChangeoptionCategories} >
                          <option>{currentData.country_destination}</option>
                          {optionCategoryArrayDestinationCountry.map((optionCategory) => (
                            <option key={optionCategory.value}>{optionCategory.value}</option>
                          ))}
                        </select>                      
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Destination Institution</label>
                      </div>
                      <div className="col-md-8 form-group">                       
                                           
                        <select className="form-control select-option" id="country_destination" name="country_destination" onChange={handleInputChangeEdit} >
                          <option>{currentData.destination_institution}</option>
                          {optionCategoryArrayDestinationInstitution.map((optionCategory) => (
                            <option key={optionCategory.value}>{optionCategory.value}</option>
                          ))}
                        </select>                      
                      </div>

                    
                      <div className="col-md-4 form-label">
                        <label> Address</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="partener_address" className="form-control" name="partener_address" placeholder="Address" onChange={handleInputChangeEdit} validations={[required]} value={currentData.partener_address} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Partener Charges</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="partener_charges" className="form-control" name="partener_charges" placeholder="Charges" onChange={handleInputChangeEdit} validations={[required]} value={currentData.partener_charges} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Transfer Currency</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="transfer_currency" className="form-control" name="transfer_currency" placeholder="Currency" onChange={handleInputChangeEdit} validations={[required]} value={currentData.transfer_currency} />
                      </div>

                      
                      <div className="col-md-4 form-label">
                        <label>Transfer Currency rate</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="transfer_currency_rate" className="form-control" name="transfer_currency_rate" placeholder="Exchange Rate" onChange={handleInputChangeEdit} validations={[required]} value={currentData.transfer_currency_rate} />
                      </div>


                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <label className="col-md-8">{message}</label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <div className="col-md-8 form-group">
                        {/* <button onClick={updateData}
                          type="button" disabled className="button-form"> Update</button> */}


                        <button onClick={updateData}
                          type="button" className="button-form"> Update</button>

                        <button onClick={() => setLgShow2(false)}
                          type="button" className="button-form"> Close</button>
                      </div>

                    </div>
                  </div>
                </form>

              </div>

            </center>

          </div>

        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
                <center> <div>Ananda</div> </center>
            </Modal.Footer>
      </Modal>
    </>
  );
};
