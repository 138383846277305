import http from "./http-common";
import authHeader from "./auth-header";

const getAll = (params) => {
  return http.get("/transfer-details", { params,headers: authHeader() });
};

const get = (id) => {
  return http.get(`/transfer-details/${id}`,{ headers: authHeader() });
};

const create = (data) => {
   
  return http.post("/transfer-details", data,{ headers: authHeader() });
};

const update = (id, data) => {
  return http.put(`/transfer-details/${id}`, data,{ headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/transfer-details/${id}`,{ headers: authHeader()});
};

const removeAll = () => {
  return http.delete(`/customers`);
};

const getByInstitution = (institution) => {
  return http.get(`/transfer-details-institution/${institution}`,{ headers: authHeader() });
};

/* const findByTitle = (title) => {
  return http.get(`/tutorials?title=${title}`);
}; */

const findByTitle = (title) => {
  return http.get(`/stock?code=${title}`);
};

const TransferDetailsService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  getByInstitution
  
};

export default TransferDetailsService;
