import React, { useState, useRef, useEffect } from "react";
import { render } from "react-dom";
import { useReactToPrint } from "react-to-print";

//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
import DeliveryService from "../../services/delivery.service";
import OrderService from "../../services/order.service";

import { useNavigate, Link, Outlet } from 'react-router-dom';



class ComponentToPrint extends React.Component {

  // // constructor(props) {
  // //   super(props);

  // //   this.getData = this.getData.bind(this);

  // //   this.state = {

  // //     currentOrderData: [],
  // //     currentOrderDataSummary: [],

  // //   };

  // //   this.getData();


  // }



  getData() {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');

    OrderService.getOrderByNoSummary(id)
      .then(response => {


        this.setState({
          currentOrderDataSummary: response.data
        });


      })
      .catch(e => {
        console.log(e);
      });

    DeliveryService.getResteByOrderNo(id)
      .then(response => {
        this.setState({
          currentOrderData: response.data
        });


      })
      .catch(e => {
        console.log(e);
      });

  }

  render() {


    return (
      <div className="printing-page">


        <div className="dataRow">
          <div className="details-printing">
            <center>
              <h2>GOLDEN FASHION</h2>
              {/* <h6>TIN / VAT 106637908</h6>
              <h6>Tel:0788644491 | 0788574942</h6><br/> */}
              <hr />
            </center>

            <table>
              <tr>
                <td><h5>Customer :{this.state.currentOrderDataSummary.customer}</h5></td>
              </tr>
              <tr>
                <td><h5>Phone :{this.state.currentOrderDataSummary.phone}</h5></td>
              </tr>
              <tr>
                <td><h5>Order :{this.state.currentOrderDataSummary.orderNo}</h5></td>
              </tr>
              <tr>
                <td><h5>Saller :{this.state.currentOrderDataSummary.saller}</h5></td>
              </tr>
              <tr>
                <td><h5>Cashier :{this.state.currentOrderDataSummary.cashier}</h5></td>
              </tr>
              <tr>
                <td><h5>Date :{this.state.currentOrderDataSummary.date}</h5></td>
              </tr>


            </table>
            <center>
              <h4>DELIVERY NOTE | RESTE</h4>
            </center>
            <hr />
            <div className="table-wrap">


              <table className="table ">
                <thead className="scroll-thead">
                  <tr>
                    <th>ITEM</th>
                    <th>CODE</th>
                    <th>SIZE</th>
                    <th>COLOR</th>
                    <th>ORDER</th>
                    <th>TO DELIVER</th>
                    <th>DELIVERED</th>
                    <th>REMAINING</th>
                    <th>LAST DELIVERY</th>
                    <th>STATUS</th>

                  </tr>
                </thead>
                <tbody>
                  {this.state.currentOrderData &&
                    this.state.currentOrderData.map((tutorial, index) => (

                      <tr>
                        <td>{tutorial.item}</td>
                        <td>{tutorial.code}</td>
                        <td>{tutorial.size}</td>
                        <td>{tutorial.color}</td>
                        <td>{tutorial.qtyOrdered}</td>
                        <td>{tutorial.qtyToDeliver}</td>
                        <td>{tutorial.qtyDelivered}</td>
                        <td>{tutorial.qtyRemaining}</td>
                        <td>{tutorial.lastQtyDelivered}</td>
                        <td><span className={"status " + tutorial.deliveryStatus}>{tutorial.deliveryStatus}</span></td>

                      </tr>

                    ))}
                </tbody>

              </table>

            </div>
          </div>
          <br />
          <br />
          <br />
          <p>
            {/* <h5> PREPARED BY:  </h5> */}
          </p>

        </div>
      </div>
    );
  }
}

const currentUser = AuthService.getCurrentUser();


export default function DeliveryNoteReste () {


  const currentUser = AuthService.getCurrentUser();


  let navigate = useNavigate();

  useEffect(() => {



      if (JSON.parse(localStorage.getItem("user")) == null) {

          //redirectToLogin();

          navigate('/login', { replace: true });

      } else {

          let decodedToken = jwt_decode(currentUser.accessToken);
          let currentDate = new Date();
          // JWT exp is in seconds redirect to login
          if (decodedToken.exp * 1000 < currentDate.getTime()) {

              AuthService.logout();
              navigate('/login', { replace: true });

          }


      }

      if (!currentUser.roles.includes("ROLE_DELIVERY")){
         
          navigate('/unauthorized', { replace: true });

      }

  }, []);



  /***************************************************************************************/


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const list = () => {
    // props.history.push("/deliveries");
    navigate('/deriveries', { replace: true });
  };

  const home = () => {
    // props.history.push("/home");
    navigate('/home', { replace: true });
  };

  return (

    <div className='pages'>
      <button onClick={handlePrint} className="button-form">Print</button>
      <button onClick={list} className="button-form">Deliveries</button>
      <button onClick={home} className="button-form">Home</button>
      <ComponentToPrint ref={componentRef} />



    </div>





  );
};
