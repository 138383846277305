import React, { useState, useRef, useEffect } from "react";
import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { Modal } from 'react-bootstrap';
import { IoIosRemoveCircleOutline } from "react-icons/io";
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import MemberService from "../../services/members.service";
import SelectOptionService from "../../services/select-options.service";
import { useLocation, useNavigate } from "react-router-dom";
import TransferService from "../../services/transfer.service";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { ImCancelCircle, ImPlus } from "react-icons/im";
import logo from '../images/ananda.jpg'
import TransferDetailsService from "../../services/transfer-details.service";
import { BsPersonBoundingBox } from "react-icons/bs";
import OrganizationDetailsService from "../../services/organization-details.service";


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const currentUser = AuthService.getCurrentUser();

export default function TransferReceipt() {
  let navigate = useNavigate();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user")) == null) {
      //redirectToLogin();
      navigate('/login', { replace: true });

    } else {

      let decodedToken = jwt_decode(currentUser.accessToken);
      let currentDate = new Date();
      // JWT exp is in seconds redirect to login
      if (decodedToken.exp * 1000 < currentDate.getTime()) {

        AuthService.logout();
        navigate('/login', { replace: true });
      }
    }
  }, []);

  const location = useLocation();
  const transferData = location.state;

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    senderName: "",
    senderId: "",
    sender_Phone: "",
    senderCountry: "",

    receiverCountry: "",
    receiverName: "",
    receiverPhone: "",
    amount: 0,

    destinationInstitution: "",
    local_transfer_fees: "",
    destination_transfer_fees: "",
    total_transfer_feee: "",

    total_amount: "",
    transfer_currency: "",
    exchange_rate: "",
    total_transfer: "",

    receiver_instutution_response: "",
    receiver_instutution_response_id: "",

    disburserName: "",
    disbursement_date: "",
    disbursement_currency: "",
    disbursement_amount: "",

    disbursementRecieverName: "",
    disbursementRecieverId: "",

    published: false
  };


  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [searchTitleType, setSearchTitleType] = useState("Name");
  const [memberdetails, setMemberDetails] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [optionCategoryArrayDestinationCountry, setOptionCategoryArrayDestinationCountry] = useState([]);
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArrayDestinationInstitution, setOptionCategoryArrayDestinationInstitution] = useState([]);
  const [optionCategorySelectedDestinationInstitution, setOptionCategorySelectedDestinationInstitution] = useState([]);
  const [destinationInstitionDetails, setdestinationInstitionDetails] = useState([]);
  const [organizationDetails, setorganizationDetails] = useState([]);

  const [message, setMessage] = useState("");
  const [messageNewPassword, setMessageNewPassword] = useState("");
  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const pageSizes = [300, 500, 1000];
  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
    handleSearch();
  };

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (searchTitleType) {
      params["type"] = searchTitleType;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveData = () => {

    setPage(1);

    const params = getRequestParams(searchTitle, page, pageSize);

    TransferService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        getMemberData();
      })
      .catch((e) => {
        console.log(e);
      });

    getSelectOption();
    getOrganizationDetails();
  };


  const handleSearch = () => {
    setSearchTitleType("Name");
    setPage(1);
    retrieveData();
  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {
        setMemberDetails(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const getSelectOption = () => {
    setPage(1);

    var category = "EXTERNAL_TRANSFER_COUNTRY";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArrayDestinationCountry(response.data);

      })
      .catch((e) => {
        console.log(e);
      });

  };

  const getOrganizationDetails = () => {
    setPage(1);

    OrganizationDetailsService.get(1)
      .then((response) => {

        setorganizationDetails(response.data);
        
      })
      .catch((e) => {
        console.log(e);
      });

  }

  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };

  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {
    setMessage("");
    setLgShow2(true);
    setPage(1);
    setcurrentData(initialDataState);

    TransferService.get(id)
      .then(response => {
        setcurrentData(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };


  const openObject2 = (id) => {
    setMessage("");
    setLgShow3(true);
    setPage(1);
    setcurrentData(initialDataState);

    TransferService.get(id)
      .then(response => {
        setcurrentData(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const openObject3 = (id) => {
    setMessage("");
    setLgShow4(true);
    setPage(1);
    setcurrentData(initialDataState);

    TransferService.get(id)
      .then(response => {
        setcurrentData(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const remove = (id) => {

    setMessage("");
    setPage(1);

    setcurrentData(initialDataState);


    TransferService.remove(id)
      .then(response => {

        refreshList();

        setMessage(response.data);


      })
      .catch(e => {
        console.log(e);
      });

  };


  // const openObject2 = (id) => {
  //   setMessage("");
  //   setPage(1);
  //   setLgShow2(true);

  //   MemberService.get(id)
  //     .then(response => {
  //       setcurrentData(response.data);
  //       getUserroles(response.data.email);

  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // };

  // const openData = (id) => {
  //   setMessage("");
  //   setLgShow4(true);
  //   setPage(1);
  //   setcurrentData(initialDataState);

  //   TransferService.get(id)
  //     .then(response => {
  //       setcurrentData(response.data);
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // };

  const getUserroles = (username) => {
    MemberService.getMemberdetailsRoles(username)
      .then(response => {
        setUserRoles(response.data.roles);
      })
      .catch(e => {
        console.log(e);
      });
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);
  const [lgShow3, setLgShow3] = useState(false);
  const [lgShow4, setLgShow4] = useState(false);

  const add = () => {
    // props.history.push("/registration/");
    navigate('/transfer-new', { replace: true });
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };


  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };


  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);

    var data = {
      text1: "EXTERNAL_TRANSFER_INSTITUTION",
      text2: event.target.value
    }

    SelectOptionService.getAllSelectedOptionsWithData(data)
      .then((response) => {


        setOptionCategoryArrayDestinationInstitution(response.data);

      })
      .catch((e) => {
        console.log(e);
      });

  }

  const handleInputChangeDestinationInstitution = (event) => {
    setOptionCategorySelectedDestinationInstitution(event.target.value);



    TransferDetailsService.getByInstitution(event.target.value)
      .then((response) => {

        setdestinationInstitionDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }

  const updateData = () => {
    setPage(1);
    setMessage("");

    TransferService.update(currentData.id, currentData)
      .then(response => {
        setMessage("Transfer updated successfully!");
        submitted(true);
      })
      .catch(e => {
        console.log(e);
      });
    refreshList();
  };

  const updateDataDisbursment = () => {
    setPage(1);
    setMessage("");

    TransferService.updateDataDisbursment(currentData.id, currentData)
      .then(response => {
        setMessage("Transfer updated successfully!");
        submitted(true);
      })
      .catch(e => {
        console.log(e);
      });
    refreshList();
  };

  const saveData = () => {

    var data = {
      senderName: tutorial.senderName,
      senderId: tutorial.senderId,
      sender_Phone: tutorial.sender_Phone,
      senderCountry: memberdetails.account,

      receiverCountry: tutorial.receiverCountry,
      receiverName: tutorial.receiverName,
      receiverPhone: tutorial.receiverPhone,
      amount: tutorial.amount,

      destinationInstitution: optionCategorySelectedDestinationInstitution,
      local_transfer_fees: destinationInstitionDetails.fees,
      destination_transfer_fees: destinationInstitionDetails.partener_charges,
      total_transfer_feee: parseFloat(destinationInstitionDetails.fees) + parseFloat(destinationInstitionDetails.partener_charges),

      total_amount: tutorial.amount - (tutorial.amount * (destinationInstitionDetails.partener_charges + destinationInstitionDetails.fees) / 100),
      transfer_currency: destinationInstitionDetails.transfer_currency,
      exchange_rate: destinationInstitionDetails.transfer_currency_rate,
      total_transfer: (tutorial.amount - (tutorial.amount * (destinationInstitionDetails.partener_charges + destinationInstitionDetails.fees) / 100)) / destinationInstitionDetails.transfer_currency_rate,

      receiver_instutution_response: tutorial.receiver_instutution_response,
      receiver_instutution_response_id: tutorial.receiver_instutution_response_id,
      disburserName: tutorial.disburserName,
      disbursement_date: tutorial.disbursement_date,
      disbursement_currency: tutorial.disbursement_currency,
      disbursement_amount: tutorial.disbursement_amount,

      disbursementRecieverName: tutorial.disbursementRecieverName,
      disbursementRecieverId: tutorial.disbursementRecieverId

    };



    TransferService.create(data)
      .then(response => {
        newData();
        setSubmitted(true);
        setMessage("Transfer done successfully.");

      })
      .catch(e => {
        console.log(e);
      });

  };


  const newData = () => {
    setData(initialDataState);
    setSubmitted(false);
    setMessage("");
    //props.history.push("/customers-new/");
    navigate('/transfer', { replace: true });

  };

  const receipt = (id) => {
   
    navigate(`/transfer-receipt?id=${id}`, { replace: true }, { state: { query: currentData } });
     
  }

  const Print = () =>{     
    //console.log('print');  
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
   document.body.innerHTML = originalContents; 
  }


  return (
    <>
      <div className='pages'>

        
        <div className="row">

          
          {/* cards  */}
          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <div className="table-account">
                <tbody>
                  <tr>
                    <td rowSpan="3"> <input type="text" value={searchTitle}
                      onChange={onChangeSearchTitle} placeholder="Search" className="search-input"></input> </td>
                    <td> <button onClick={handleSearch} className="button-tools"> Search</button></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td> </td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td> </td>
                  </tr>
                </tbody>
              </div>
            </table>
          </div>

          <div className="col-md-3 tool-box">
            <table className=" table-tools">
              <tbody>

                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  {/* <td className="tg-0lax"><button onClick={add} className="button-tools">New</button></td> */}
                  <td className="tg-0lax"><button onClick={() => setLgShow(true)} className="button-tools">Transfer</button></td>
                </tr>
                <tr>
                  <td className="tg-0lax"> <button type="button" onClick={Print}className="button-tools"> Print </button></td>
                  <td className="tg-0lax"></td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* end card box */}

          <div className="dataRow">
          <div className="dataRow-with-one-column-center">

{/* col 1 */}
<div className="details-small " id='printablediv'>

<div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

           

  <center>
    <form className="form form-horizontal ">
      <div className="form-body">

<br/>

        <div className="row">
          <div className="col-md-5"> <hr /> </div>
          <div className="col-md-2"><center> <span className="modal-title">SENDER</span> </center></div>
          <div className="col-md-5"><hr /></div>
        </div>
        <div className="row">

          <div className="col-md-8 form-label-left">
            <label>Sender Name : {transferData.senderName}</label>
          </div>

          <div className="col-md-8 form-label-left">
            <label>Sender Id : {transferData.senderId}</label>
          </div>

          <div className="col-md-8 form-label-left">
            <label>Sender Phone : {transferData.sender_Phone}</label>
          </div>

          <div className="col-md-8 form-label-left">
            <label>Sender Country : {transferData.senderCountry}</label>
          </div>
<br/>

          <div className="row">
            <div className="col-md-5"> <hr /> </div>
            <div className="col-md-2"><center> <span className="modal-title">RECEIVER</span> </center></div>
            <div className="col-md-5"><hr /></div>
          </div>
          <div className="col-md-8 form-label-left">
            <label>Receiver name : {transferData.receiverName}</label>
          </div>

          <div className="col-md-8 form-label-left">
            <label>Receiver country : {transferData.receiverCountry}</label>
          </div>

          <div className="col-md-8 form-label-left">
            <label>Receiver Phone : {transferData.receiverPhone}</label>
          </div>


          <div className="row">
            <div className="col-md-5"> <hr /> </div>
            <div className="col-md-2"><center> <span className="modal-title">DISBURSER</span> </center></div>
            <div className="col-md-5"><hr /></div>
          </div>


          <div className="col-md-8 form-label-left">
            <label>Disbursement Name : {transferData.disburserName}</label>
          </div>

          <div className="col-md-8 form-label-left">
            <label>Disbursement Date : {transferData.disbursement_date}</label>
          </div>

          <div className="col-md-8 form-label-left">
            <label>Disbursement Currency : {transferData.disbursement_currency}</label>
          </div>

          <div className="col-md-8 form-label-left">
            <label>Disbursement Amount : {transferData.disbursement_amount}</label>
          </div>

          <div className="col-md-8 form-label-left">
            <label>Disburser Receiver Name : {transferData.disbursementRecieverName}</label>
          </div>

          <div className="col-md-8 form-label-left">
            <label>Disburser Receiver Id : {transferData.disbursementRecieverId}</label>
          </div>

          <div className="col-md-4">
            <label> </label>
          </div>

          <label className="col-md-8">{message}</label>

          <label className="col-md-8"> </label>

          <div className="col-md-4">
            <label> </label>
          </div>

        </div>
      </div>
    </form>
  </center>
</div>
{/* col 2 */}
</div>
          </div>
        </div>
      </div>    

      
    </>
  );
};
