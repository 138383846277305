import React, { useState, useRef, useEffect } from "react";

import { GiTimeBomb } from 'react-icons/gi';
import DatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
//import OrderService from "../../services/order.service";
import ReportService from "../../services/report.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';
import MemberService from "../../services/members.service";
import { useNavigate } from 'react-router-dom';

const currentUser = AuthService.getCurrentUser();

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default function ReportsJournal (){

  let navigate = useNavigate();

  useEffect(() => {
     if (JSON.parse(localStorage.getItem("user")) == null) {

        navigate('/login', { replace: true });
        AuthService.reloadWindow();

     } else {

        let decodedToken = jwt_decode(currentUser.accessToken);
        let currentDate = new Date();
        // JWT exp is in seconds redirect to login
        if (decodedToken.exp * 1000 < currentDate.getTime()) {

           AuthService.logout();
           navigate('/login', { replace: true });
           AuthService.reloadWindow();

        }

     }

  }, []);

  const initialDataState = {
    id: null,
    orderId: "",
    orderTime: "",
    pid: "",
    qty: "",
    sie: "",
    color: "",
    price: "",
    saller: "",
    customer: "",
    phone: "",
    salesStatus: "",
    stand: "",

    username: "",
  };



  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [reportSalesData, setReportSalesData] = useState([]);
  const [reportPaymentData, setReportPaymentData] = useState([]);
  const [expenseData, setExpenseData] = useState("");
  const [cashingData, setCashingData] = useState([]);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [orderedData, setOrderedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [memberdetails, setMemberDetails] = useState([]);
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();
  const [isManager, setIsManager] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [role, setRole] = useState("Cashier");
  const [cashierName, setCashierName] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const [cash, setCash] = useState("");
  const [momo, setMomo] = useState("");
  const [visa, setVisa] = useState("");
  const [cheque, setCheque] = useState("");
  const [transfer, setTransfer] = useState("");

  const componentRef = useRef(null);
  const pageSizes = [100, 300, 500];
  tutorialsRef.current = tutorials;

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);
    setCashierName(event.target.value);

  }

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value);
  }

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (isManager == true) {
      setRole("Manager");
    }

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (role) {
      params["role"] = role;
    }

    if (memberdetails.account) {
      params["location"] = memberdetails.account;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    if (startDate) {
      params["date"] = format(startDate, 'yyyy-MM-dd');
    }

    if (currentUser.username) {
      params["username"] = currentUser.username;
    }

    if (optionCategorySelectedValue) {
      params["cashier"] = optionCategorySelectedValue;
    }

    if (isReport) {
      params["report"] = isReport;
    }
    return params;
  };


  const retrieveData = () => {

    setPage(1);

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setCashierName(optionCategorySelectedValue);

    } else {
      setCashierName(currentUser.username);
    }
    var data = {
      text1: format(startDate, 'yyyy-MM-dd') + " 00:00:00",
      text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
      text3: memberdetails.account,
      text4: cashierName,
      
    }

    ReportService.salesReport(data)
      .then((response) => {
        const { reportSalesData,reportPaymentData,cashing,expenses, totalPages } = response.data;
        setReportSalesData(reportSalesData);
        setReportPaymentData(reportPaymentData);
        setExpenseData(expenses);
        setCashingData(cashing);
        setCount(totalPages);
        //setCount(totalPages);
        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
    getSelectOption();
    getMemberData();
  };

  const getMemberData = () => {
    setPage(1);
    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {
        setMemberDetails(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setIsManager(true);
    }
  }

  const getSelectOption = () => {
    setPage(1);
    var category = "CASHIER";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {
        setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };

  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const handleChangeEndDate = (date) => {
    setEndDate(date);
    setPage(1);
  }

  const handleChangeStartDate = (date) => {
    setStartDate(date);
    setPage(1);
  }

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const cashierReports =(name) =>{
    // props.history.push("/reports-cashier/?x=$"+currentUser.username);
    navigate('/reports-cashier' + currentUser.username,{replace: true});
  }

  const reportSallers =() =>{
    // props.history.push("/reports-sallers");
    navigate('/reports-sallers',{replace: true});
  }

  const reportSales =() =>{
    // props.history.push("/reports-sales");
    navigate('/reports-sales',{replace: true});
  }

  const reportJournal =() =>{
    // props.history.push("/reports-journal");
    navigate('/reports-journal',{replace: true});
  }

  const reportCashing =() =>{
    // props.history.push("/reports-cashing");
    navigate('/reports-cashing',{replace: true});
  }

  const reportStand = () => {
    // props.history.push("/reports-stand");
    navigate('/reports-stand',{replace: true});
  }

  return (
    <>
      <div className='pages'>
        <div className="row">
          {/* cards  */}
          <div className="col-md-3 tool-box">
            <table className="table-tools">
            <div className="table-account">
              <tbody>
              <tr>
                <td><div className="table-name">{memberdetails.firstname}</div></td>
                <td><div className="table-name">{memberdetails.lastname}</div></td>
              </tr>
              <tr>
                <td>Branch:</td>
                <td><div className="table-name">{memberdetails.account}</div></td>
              </tr>
              </tbody>
              </div>
            </table>
          </div>
          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <tbody>
              <tr>
                <td rowSpan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
                <td> 
                {isManager ? (<DatePicker className="form-control datePicker-style"
                  selected={startDate}
                  onChange={handleChangeStartDate}
                  name="startDate"
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                />):(<DatePicker  className="form-control datePicker-style"
                  selected={startDate}
                  onChange={handleChangeStartDate}
                  name="startDate"
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                />)}
                  </td>
                <td>
                {isManager ? (<DatePicker  className="form-control datePicker-style"
                  selected={endDate}
                  onChange={handleChangeEndDate}
                  name="endDate"
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                />):(<DatePicker className="form-control datePicker-style"
                selected={endDate}
                onChange={handleChangeEndDate}
                name="endDate"
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd"
              />)}
                  </td>
              </tr>
              {isManager ? (<tr>
                <td>Cashier</td>
                <td>
                  <div className="col-md-8 form-group">
                    <select className="form-control" onChange={handleChangeoptionCategories} >
                      <option>Select</option>
                      {optionCategoryArray.map((optionCategory) => (
                        <option key={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select>
                  </div></td>
              </tr>) : (<tr>
                <td>Cashier</td>
                <td>
                  <div className="col-md-4 form-group">
                    <select disabled ="form-control" onChange={handleChangeoptionCategories} >
                      <option key={currentUser.username}>{currentUser.username}</option>
                      {optionCategoryArray.map((optionCategory) => (
                        <option key={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select>
                  </div></td>
              </tr>)}
            </tbody>
            </table>
          </div>

          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <tbody>
            <tr>
                <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                <td className="tg-0lax"><button onClick={reportSallers}  className="button-tools">Sallers</button></td>
                <td className="tg-0lax"><button onClick={reportSales}  className="button-tools">Sales</button></td>
              </tr>
              <tr>
                <td className="tg-0lax"><button onClick={reportJournal} className="button-tools">Journal</button></td>
                <td className="tg-0lax"><button onClick={reportCashing}  className="button-tools">Cashing</button></td>
                <td className="tg-0lax"><button onClick={reportStand} className="button-tools">Stand</button></td>
              </tr>
              </tbody>

            </table>
          </div>
          {/* end card box */}
          <div className="dataRow-with-two-column-center">
            <div className="details">
              <center className="table-title"> Journal </center>
              <hr></hr>
              <table className="table">
                <thead className="scroll-thead">
                  <tr>
                    <th>DESCRIPTION</th>
                    <th>AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Cash</td>
                    <td>{reportPaymentData.cash}</td>
                  </tr>
                  <tr>
                    <td>MoMo</td>
                    <td>{reportPaymentData.momo}</td>
                  </tr>
                  <tr>
                    <td>MoMo Charges</td>
                    <td>{reportPaymentData.momo_charges}</td>
                  </tr>
                  <tr>
                    <td>Visa</td>
                    <td>{reportPaymentData.visa}</td>
                  </tr>
                  <tr>
                    <td>Cheque</td>
                    <td>{reportPaymentData.cheque}</td>
                  </tr>
                  <tr>
                    <td>Tranfer</td>
                    <td>{reportPaymentData.transfer}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Total: {reportPaymentData.total}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Expenses: {expenseData}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>         
        </div>
      </div>

      <Modal ref={componentRef}
        size="lg"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">
              {/* <div className="col-md-6"> <label> {customerName} {customerPhone} </label></div> */}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">
          <div className="dataRow">
            <div className="details">
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};