import React, { useState, useEffect, useRef } from "react";
import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
import ProductsService from "../../services/products.service";
import MemberService from "../../services/members.service";
import SelectOptionService from "../../services/select-options.service";
import { useNavigate } from 'react-router-dom';

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const currentUser = AuthService.getCurrentUser();
export default function ProductView() {
    let navigate = useNavigate();
    useEffect(() => {
        if (JSON.parse(localStorage.getItem("user")) == null) {
            navigate('/login', { replace: true });
            AuthService.reloadWindow();
        } else {
            let decodedToken = jwt_decode(currentUser.accessToken);
            let currentDate = new Date();
            // JWT exp is in seconds redirect to login
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                AuthService.logout();
                navigate('/login', { replace: true });
                AuthService.reloadWindow();
            }
        }
    }, []);
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/

    const initialDataState = {
        id: null,
        code: "",
        name: "",
        description: "",
        min_price: "",
        max_price: "",
        suply_price: "",
        color: "",
        size: "",
        remark: "",
        username: "",
        goldenStand: "",
        classicStand: "",
        businesStand: "",
        economicStand: "",
        kidStand: "",
        shoesStand: "",
        watchStand: "",
        womenStand: "",
        confirmation: "",
        published: false
    };

    const [currentData, setcurrentData] = useState(initialDataState);
    const [message, setMessage] = useState("");
    const [memberdetails, setMemberDetails] = useState([]);
    const [optionCategoryArray, setOptionCategoryArray] = useState([]);
    const [optionCategoryArrayConfirmation, setOptionCategoryArrayConfirmation] = useState([]);
    const [optionCategoryArrayProduct, setOptionCategoryArrayProduct] = useState([]);
    const [optionCategoryArrayDescription, setOptionCategoryArrayDescription] = useState([]);
    const [isManager, setIsManager] = useState(false);

    const handleInputChangeEdit = event => {
        const { name, value } = event.target;
        setcurrentData({ ...currentData, [name]: value });
    };

    const [tutorials, setDatas] = useState([]);
    const tutorialsRef = useRef();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    tutorialsRef.current = tutorials;

    const retrieveData = () => {
        setPage(1);
        if (currentUser.roles.includes("ROLE_MANAGER")) {
            setIsManager(true);
        }

        openObject();
        getMemberData();
        getSelectOption();
    };

    const getSelectOption = () => {
        setPage(1);
        var category = "PRODUCT-STAND-PRESENCE";
        SelectOptionService.getAllSelectedOptions(category)
            .then((response) => {
                setOptionCategoryArray(response.data);
                // setOptionCategoryArrayTransfer(response.data);
            })
            .catch((e) => {
                console.log(e);
            });

        var category1 = "STOCK-CONFIRMATION";
        SelectOptionService.getAllSelectedOptions(category1)
            .then((response) => {
                setOptionCategoryArrayConfirmation(response.data);
                // setOptionCategoryArrayTransfer(response.data);
            })
            .catch((e) => {
                console.log(e);
            });

        var category2 = "PRODUCT-NAME";
        SelectOptionService.getAllSelectedOptions(category2)
            .then((response) => {
                setOptionCategoryArrayProduct(response.data);
                // setOptionCategoryArrayTransfer(response.data);
            })
            .catch((e) => {
                console.log(e);
            });

        var category2 = "DESCRIPTION-TYPE";
        SelectOptionService.getAllSelectedOptions(category2)
            .then((response) => {
                setOptionCategoryArrayDescription(response.data);
                // setOptionCategoryArrayTransfer(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getMemberData = () => {
        setPage(1);
        MemberService.getMemberdetails(currentUser.username)
            .then((response) => {
                setMemberDetails(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    useEffect(retrieveData, [page, pageSize]);

    const openObject = (rowIndex) => {
        setPage(1);
        setcurrentData(initialDataState);
        // const id = tutorialsRef.current[rowIndex].id;
        var id = 1;
        ProductsService.get(id)
            .then(response => {
                setcurrentData(response.data);
                //console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const updateData = () => {
        setPage(1);
        if (currentData.code === null || currentData.code === "") {
            setMessage("Product code can't be empty");
        } else if (currentData.name === null || currentData.name === "") {
            setMessage("Product name can't be empty");
        }
        else if (currentData.min_price === null || currentData.min_price === "") {
            setMessage("Minimum price can't be empty");
        } else if (currentData.max_price === null || currentData.max_price === "") {
            setMessage("Maximum can't be empty");
        } else if (currentData.suply_price === null || currentData.suply_price === "") {
            setMessage("Suply price can't be empty");
        }
        else if (currentData.color === null || currentData.color === "") {
            setMessage("Color can't be empty");
        } else if (currentData.size === null || currentData.size === "") {
            setMessage("Size can't be empty");
        }
        else {
            ProductsService.update(currentData.id, currentData)
                .then(response => {
                    setMessage(response.data)
                })
                .catch(e => {
                    console.log(e);
                });
        }
    };

    const deleteData = (rowIndex) => {
        const id = tutorialsRef.current[rowIndex].id;
        ProductsService.remove(id)
            .then((response) => {
                /// props.history.push("/product");
                navigate('/product', { replace: true });
                let newDatas = [...tutorialsRef.current];
                newDatas.splice(rowIndex, 1);
                setDatas(newDatas);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const list = () => {
        // props.history.push("/products/");
        navigate('/products', { replace: true });
    };

    const add = () => {
        // props.history.push("/products-new/");
        navigate('/products-new', { replace: true });
    };

    return (
        <div className='pages'>
            <div className="row">

                {/* cards  */}
                <div className="col-md-3 tool-box">
                    <table className="table-tools">
                        <tbody>
                            <tr>
                                <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{memberdetails.firstname}</td>
                                <td>{memberdetails.lastname}</td>
                            </tr>
                            <tr>
                                <td>{memberdetails.account}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colSpan="2"></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="col-md-3 tool-box">
                    <table>
                        <tbody>
                            <tr>
                                <td><span className="page-title-img"><FaIcons.FaProductHunt /> </span></td>
                                <td><span className="page-title"> Products</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="col-md-3 tool-box">
                    <table className="table-tools">
                        <tbody>
                            <tr>
                                <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                                <td className="tg-0lax"></td>
                                <td className="tg-0lax"></td>
                            </tr>
                            <tr>
                                <td className="tg-0lax"><button onClick={list} className="button-tools">Refresh</button></td>
                                <td className="tg-0lax"><button onClick={add} className="button-tools">New</button></td>
                            </tr>
                            <tr>
                                <td className="tg-0lax"></td>
                                <td className="tg-0lax"> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* end card box */}

                <div className="dataRow">
                    <center>
                        <div className="details-small">
                            <center>
                                <form className="form form-horizontal ">
                                    <div className="form-body">
                                        <div className="row">

                                            <div className="col-md-4">
                                                <label>CODE</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input disabled type="text" id="code" className="form-control" name="code" placeholder="model number" onChange={handleInputChangeEdit} validations={[required]} value={currentData.code} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Name</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <select className="form-control" id="name" name="name" onChange={handleInputChangeEdit} >
                                                    <option key={currentData.name}>{currentData.name}</option>
                                                    {optionCategoryArrayProduct.map((optionCategory) => (
                                                        <option key={optionCategory.value}>{optionCategory.value}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Description</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <select className="form-control" id="description" name="description" onChange={handleInputChangeEdit} >
                                                    <option key={currentData.description}>{currentData.description}</option>
                                                    {optionCategoryArrayDescription.map((optionCategory) => (
                                                        <option key={optionCategory.value}>{optionCategory.value}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Min price</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="number" id="min-price" className="form-control" name="min_price" placeholder="Minimum price" onChange={handleInputChangeEdit} validations={[required]} value={currentData.min_price} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>Max price</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="number" id="max_price" className="form-control" name="max_price" placeholder="Maximum price" onChange={handleInputChangeEdit} validations={[required]} value={currentData.max_price} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>Suply price</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="number" id="suply_price" className="form-control" name="suply_price" placeholder="Suply price" onChange={handleInputChangeEdit} validations={[required]} value={currentData.suply_price} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>color</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input disabled type="text" id="color" className="form-control" name="color" placeholder="Color" onChange={handleInputChangeEdit} validations={[required]} value={currentData.color} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>size</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input disable type="text" id="size" className="form-control" name="size" placeholder="Size" onChange={handleInputChangeEdit} validations={[required]} value={currentData.size} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>Remark</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="remark" className="form-control" name="remark" placeholder="Remark" onChange={handleInputChangeEdit} value={currentData.remark} />
                                            </div>


                                            {isManager ? (<><div className="col-md-4">
                                                <label>Confirmation</label>
                                            </div>
                                                <div className="col-md-8 form-group">
                                                    <select className="form-control" id="confirmation" name="confirmation" onChange={handleInputChangeEdit} >
                                                        <option key={currentData.confirmation}>{currentData.confirmation}</option>
                                                        {optionCategoryArrayConfirmation.map((optionCategory) => (
                                                            <option key={optionCategory.value}>{optionCategory.value}</option>
                                                        ))}

                                                    </select>
                                                </div></>) : (<> <div className="col-md-4">
                                                    <label>Confirmation</label>
                                                </div>
                                                    <div className="col-md-8 form-group">
                                                        <select disabled className="form-control" id="confirmation" name="confirmation" onChange={handleInputChangeEdit} >
                                                            <option key={currentData.confirmation}>{currentData.confirmation}</option>
                                                            {optionCategoryArrayConfirmation.map((optionCategory) => (
                                                                <option key={optionCategory.value}>{optionCategory.value}</option>
                                                            ))}

                                                        </select>
                                                    </div> </>)}

                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>
                                            <label className="col-md-8 danger-message">{message}</label>

                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>

                                            <div className="col-md-8 form-group">
                                                <button onClick={updateData}
                                                    type="button" className="button-form"> Save</button>


                                                <button onClick={list}
                                                    type="button" className="button-form">Close</button>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </center>
                        </div>
                    </center>
                </div>
            </div>
        </div>
    );
};
