import React, { useState, useEffect, useRef } from "react";
import { Modal } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import * as FcIcons from 'react-icons/fc';
import * as AiIcons from 'react-icons/ai';

//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import DeliveryService from "../../services/delivery.service";
import MemberService from "../../services/members.service";
import OrderService from "../../services/order.service";
import SelectOptionService from "../../services/select-options.service";

import { useNavigate, Link, Outlet } from 'react-router-dom';



export default function DeliveryListAll () {


    const currentUser = AuthService.getCurrentUser();


    let navigate = useNavigate();

    useEffect(() => {



        if (JSON.parse(localStorage.getItem("user")) == null) {

            //redirectToLogin();

            navigate('/login', { replace: true });

        } else {

            let decodedToken = jwt_decode(currentUser.accessToken);
            let currentDate = new Date();
            // JWT exp is in seconds redirect to login
            if (decodedToken.exp * 1000 < currentDate.getTime()) {

                AuthService.logout();
                navigate('/login', { replace: true });

            }


        }

        if (!currentUser.roles.includes("ROLE_DELIVERY")){
           
            navigate('/unauthorized', { replace: true });

        }

    }, []);

    const initialDataState = {
        id: null,
        code: "",
        name: "",
        description: "",
        min_price: "",
        max_price: "",
        color: "",
        size: "",
        remark: "",
        username: "",

        published: false
    };

    const [tutorial, setData] = useState(initialDataState);
    const [currentData, setcurrentData] = useState(initialDataState);
    const [message, setMessage] = useState("");
    const [memberdetails, setMemberDetails] = useState([]);
    const [currentOrderData, setCurrentOrderData] = useState([]);
    const [currentOrderDataSummary, setCurrentOrderDataSummary] = useState([]);

    const handleInputChange = event => {
        const { name, value } = event.target;
        setData({ ...tutorial, [name]: value });
    };

    const handleInputChangeEdit = event => {
        const { name, value } = event.target;
        setcurrentData({ ...currentData, [name]: value });
    };

    const saveData = () => {
        setPage(1);


        var data = {
            code: tutorial.code,
            name: tutorial.name,
            description: tutorial.description,
            min_price: tutorial.min_price,
            max_price: tutorial.max_price,
            color: tutorial.color,
            size: tutorial.size,
            remark: tutorial.remark,
            username: currentUser.username
        };



        DeliveryService.create(data)
            .then(response => {
                setData({
                    id: response.data.id,
                    code: response.data.code,
                    name: response.data.name,
                    description: response.data.description,
                    min_price: response.data.min_price,
                    max_price: response.data.max_price,
                    color: response.data.color,
                    size: response.data.size,
                    remark: response.data.remark,
                    code: response.data.code,
                    username: response.data.username

                });

                //console.log(response.data);

            })
            .catch(e => {
                console.log(e);
            });
    };



    const [tutorials, setDatas] = useState([]);
    const [searchTitle, setSearchTitle] = useState("");
    const tutorialsRef = useRef();



    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(100);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const handleChangeEndDate = (date) => {
        setEndDate(date);
    }

    const handleChangeStartDate = (date) => {
        setStartDate(date);
    }

    const pageSizes = [200, 500, 1000];

    tutorialsRef.current = tutorials;

    const onChangeSearchTitle = (e) => {
        const searchTitle = e.target.value;
        setSearchTitle(searchTitle);
    };



    const getRequestParams = (searchTitle, page, pageSize) => {
        let params = {};

        if (searchTitle) {
            params["code"] = searchTitle;
        }

        if (page) {
            params["page"] = page - 1;
        }

        if (pageSize) {
            params["size"] = pageSize;
        }

        return params;
    };

    const retrieveData = () => {
        setPage(1);

        getMemberData();

        const params = getRequestParams(searchTitle, page, pageSize);

        DeliveryService.getAll(params)
            .then((response) => {
                const { tutorials, totalPages } = response.data;

                setDatas(tutorials);
                setCount(totalPages);

                // console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            });


    };

    const getMemberData = () => {
        setPage(1);

        MemberService.getMemberdetails(currentUser.username)
            .then((response) => {

                setMemberDetails(response.data);


            })
            .catch((e) => {
                console.log(e);
            });

    }

    useEffect(retrieveData, [page, pageSize]);

    const refreshList = () => {
        setPage(1);
        retrieveData();
    };



    const handleSearch = () => {
        setPage(1);
        retrieveData();
    };

    const handleSearchByOrderNo = () => {


        setPage(1);

        refreshList();

        var data = {
            text1: "orderno",
            text2: searchTitle,

        }

        DeliveryService.getDeliverySearch(data)
            .then((response) => {


                setDatas(response.data);


            })
            .catch((e) => {
                console.log(e);
            });

    };

    const handleSearchByCustomer = () => {

        setPage(1);
        refreshList();

        var data = {
            text1: "customer",
            text2: searchTitle,

        }

        DeliveryService.getDeliverySearch(data)
            .then((response) => {

                setDatas(response.data);

            })
            .catch((e) => {
                console.log(e);
            });

    };

    const openObject = (id) => {
        //const id = tutorialsRef.current[rowIndex].id;
        //props.history.push("/deliveries/" + id);
        navigate('/deliveries'+ id , { replace: true });
    };

    const openObjectCurrentData = (id) => {
        setPage(1);

        OrderService.getOrderByNoSummary(id)
            .then(response => {
                setCurrentOrderDataSummary(response.data);


            })
            .catch(e => {
                console.log(e);
            });

        DeliveryService.getByOrderNo(id)
            .then(response => {
                setCurrentOrderData(response.data);


            })
            .catch(e => {
                console.log(e);
            });


    }




    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    const [lgShow, setLgShow] = useState(false);
    const [lgShow2, setLgShow2] = useState(false);

    const deliveries = () => {
        //props.history.push("/deliveries");
        navigate('/deliveries', { replace: true });
    }

    const report = () => {
        //props.history.push("/delivery-report");
        navigate('/delivery-report', { replace: true });
    }

    const reste = () => {
        //props.history.push("/delivery-reste");
        navigate('/delivery-reste', { replace: true });
    }

    const pending = () => {
        // props.history.push("/delivery-pending");
        navigate('/delivery-pending', { replace: true });
    }

    const delivered = () => {
        // props.history.push("/delivery-completed");
        navigate('/delivery-completed', { replace: true });
    }

    const deliveryNote = () => {
        // props.history.push(`/delivery-note/?id=${currentOrderDataSummary.orderNo}`);
        setLgShow(false);
    }

    const deliveryNoteReste = () => {
        // props.history.push(`/delivery-note-reste/?id=${currentOrderDataSummary.orderNo}`);
        setLgShow(false);
    }


    return (
        <>
            <div className='pages'>

                <div className="row">



                    {/* cards  */}

                    <div className="col-md-3 tool-box">

                        <table className="table-tools">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td><button onClick={() => setLgShow2(true)} className="button-tools">search</button></td>
                                </tr>
                                <tr>
                                    <td colSpan="2"> <input type="text" value={searchTitle}
                                        onChange={onChangeSearchTitle} placeholder="Search" className="search-input"></input> </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>


                    </div>
                    <div className="col-md-3 tool-box">
                        <table className="table-tools">
                            <tbody>

                                <tr>
                                    <td rowSpan="2"><h2><span className="page-title-img"><FaIcons.FaTruckMoving /></span></h2></td>
                                    <td><h4>Deliveries</h4></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td ></td>
                                </tr>
                            </tbody>

                        </table>




                    </div>

                    <div className="col-md-3 tool-box">

                        <table className="table-tools">
                            <tbody>

                                <tr>
                                    <td className="tg-0lax"><button onClick={deliveries} className="button-tools">All</button></td>
                                    <td className="tg-0lax"><button onClick={report} className="button-tools">Report</button></td>
                                    <td className="tg-0lax"><button onClick={reste} className="button-tools">Resté</button> </td>
                                </tr>
                                <tr>
                                    <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                                    <td className="tg-0lax"> <button onClick={pending} className="button-tools">Pending</button> </td>
                                    <td className="tg-0lax"><button onClick={delivered} className="button-tools">Delivered</button> </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>


                    {/* end card box */}



                    <div className="dataRow">
                        <div className="row pagination-box">

                            <div className="col-md-3 pagination-box">{"Items per Page : "}
                                <select onChange={handlePageSizeChange} value={pageSize}>
                                    {pageSizes.map((size) => (
                                        <option key={size} value={size}>
                                            {size}
                                        </option>
                                    ))}
                                </select></div>


                            <div className="col-md-4"><Pagination

                                count={count}
                                page={page}
                                siblingCount={1}
                                boundaryCount={1}
                                variant="outlined"
                                shape="rounded"
                                onChange={handlePageChange}
                            />

                            </div>



                        </div>
                        <div className="details">

                            <div className="table-wrap">

                                <table className="table">
                                    <thead className="scroll-thead">
                                        <tr>
                                            <th>DATE</th>
                                            <th>CUSTOMER</th>
                                            <th>ORDER NO</th>
                                            <th>PAYMENT</th>
                                            <th>STOCK</th>
                                            <th>ITEM</th>
                                            <th>CODE</th>
                                            <th>SIZE</th>
                                            <th>COLOR</th>
                                            <th>ORDER</th>
                                            <th>TO DELIVER</th>
                                            <th>DELIVERED</th>
                                            <th>REMAINING</th>
                                            <th>LAST DELIVERY</th>
                                            <th>STATUS</th>
                                            <th>AUTHORIZATION</th>
                                            <th>ACTION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tutorials &&
                                            tutorials.map((tutorial, index) => (

                                                <tr key={tutorial.id}>
                                                    <td>{tutorial.orderDate}</td>
                                                    <td>{tutorial.customer}</td>
                                                    <td>{tutorial.orderNo}</td>
                                                    <td><span className={"status " + tutorial.paymentStatus}>{tutorial.paymentStatus}</span></td>
                                                    <td>{tutorial.stock}</td>
                                                    <td>{tutorial.item}</td>
                                                    <td>{tutorial.code}</td>
                                                    <td>{tutorial.size}</td>
                                                    <td>{tutorial.color}</td>
                                                    <td>{tutorial.qtyOrdered}</td>
                                                    <td>{tutorial.qtyToDeliver}</td>
                                                    <td>{tutorial.qtyDelivered}</td>
                                                    <td>{tutorial.qtyRemaining}</td>
                                                    <td>{tutorial.lastQtyDelivered}</td>
                                                    <td><span className={"status " + tutorial.deliveryStatus}>{tutorial.deliveryStatus}</span></td>
                                                    <td>{tutorial.locked}</td>

                                                    <td><div className="table-icons-wrapper">

                                                        <button
                                                            type="button" className="table-buttons-l"
                                                            onClick={() => openObject(tutorial.id)}
                                                        > Edit
                                                        </button>

                                                        <button
                                                            type="button" className="table-buttons-l"
                                                            onClick={() => setLgShow(true)}
                                                        > <span onClick={() => openObjectCurrentData(tutorial.orderNo)}>Details</span>
                                                        </button>


                                                    </div></td>
                                                </tr>

                                            ))}
                                    </tbody>

                                </table>

                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <Modal
                size="xl"
                backdrop="static"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"

            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <div className="row">



                            {/* <div className="col-md-6"> <label> {customerName} {customerPhone} </label></div> */}



                        </div>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-bg">



                    <div className="dataRow">
                        <div className="details">


                            <table>
                                <tr>
                                    <td><h5>Customer :{currentOrderDataSummary.customer}</h5></td>
                                </tr>
                                <tr>
                                    <td><h5>Order :{currentOrderDataSummary.orderNo}</h5></td>
                                </tr>
                                <tr>
                                    <td><h5>Date :{currentOrderDataSummary.date}</h5></td> <td> <button onClick={deliveryNote} className="button-form-lg"><span className="table-tools-printer"> <AiIcons.AiFillPrinter /> </span> Delivery note</button> </td><td> <button onClick={deliveryNoteReste} className="button-form-lg"><span className="table-tools-printer"> <AiIcons.AiFillPrinter /> </span> Delivery (reste)</button> </td>
                                </tr>


                            </table>
                            <div className="table-wrap">


                                <table className="table ">
                                    <thead className="scroll-thead">
                                        <tr>
                                            <th>ITEM</th>
                                            <th>CODE</th>
                                            <th>SIZE</th>
                                            <th>COLOR</th>
                                            <th>ORDER</th>
                                            <th>TO DELIVER</th>
                                            <th>DELIVERED</th>
                                            <th>REMAINING</th>
                                            <th>LAST DELIVERY</th>
                                            <th>STATUS</th>
                                            <th>AUTHORIZATION</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentOrderData &&
                                            currentOrderData.map((tutorial, index) => (

                                                <tr>
                                                    <td>{tutorial.item}</td>
                                                    <td>{tutorial.code}</td>
                                                    <td>{tutorial.size}</td>
                                                    <td>{tutorial.color}</td>
                                                    <td>{tutorial.qtyOrdered}</td>
                                                    <td>{tutorial.qtyToDeliver}</td>
                                                    <td>{tutorial.qtyDelivered}</td>
                                                    <td>{tutorial.qtyRemaining}</td>
                                                    <td>{tutorial.lastQtyDelivered}</td>
                                                    <td><span className={"status " + tutorial.deliveryStatus}>{tutorial.deliveryStatus}</span></td>
                                                    <td>{tutorial.locked}</td>

                                                </tr>

                                            ))}
                                    </tbody>

                                </table>

                            </div>
                        </div>


                    </div>


                </Modal.Body>
            </Modal>

            <Modal
                size="md"
                backdrop="static"
                show={lgShow2}
                onHide={() => setLgShow2(false)}
                aria-labelledby="example-modal-sizes-title-lg"

            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">

                        Search <span className="table-tools-img-search"><FaIcons.FaSearch /></span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-bg">

                    <div className="dataRow">

                        <div className="details-dialog-sm">

                            <div className="row"><input type="text" value={searchTitle}
                                onChange={onChangeSearchTitle} placeholder="Search" className="search-input"></input></div>
                            <hr />
                            <div className="row"> <button onClick={handleSearchByOrderNo} className="button-tools">Search by Order No</button> </div>
                            <div className="row"> <button onClick={handleSearchByCustomer} className="button-tools">Search by Customer</button> </div>
                            <div className="row"> <button onClick={() => setLgShow2(false)} className="button-tools-red">Close</button> </div>


                        </div>


                    </div>


                </Modal.Body>
            </Modal>
        </>
    );
};