import axios from "axios";

const API_URL = "https://qpay.anandaapp.com:9117/api/auth/";
// const API_URL = "http://localhost:9117/api/auth/";


const register = (username, email, password,firstname,lastname,phone,account,user_group,password_expiration_date,report_duration,disabled,otp_enabled) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
    firstname,
    lastname,
    phone,
    account,
    user_group,
    password_expiration_date,
    report_duration,
    disabled,
    otp_enabled
  });
};

const resetPasswordForm = (token) => {
  //return axios.get(API_URL +'reset_password/${token}', { token });
  return axios.get(API_URL +`/reset_password/${token}`);
};

const resetPassword = (password,token) => {
  return axios.post(API_URL + "reset_password", {
    password,
    token,
    
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const forgetPassword = (username) => {
  return axios.post(API_URL + `forgot_password/${username}`);
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export default {
  register,
  login,
  logout,
  getCurrentUser,
  forgetPassword,
  resetPassword,
  resetPasswordForm
};
