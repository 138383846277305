import React, { useState, useRef, useEffect } from "react";
import { Button, Modal } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import { BsPersonBoundingBox } from 'react-icons/bs';
import { AiFillFolderOpen, AiOutlinePhone } from 'react-icons/ai';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';

import AuthService from "../../services/auth.service";
import CustomersService from "../../services/customers.service";
import SelectOptionService from "../../services/select-options.service";
import MemberService from "../../services/members.service";

import { useNavigate, Link, Outlet } from 'react-router-dom';
import logo from '../images/ananda.jpg'





const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default function CustomerSearch() {


  const currentUser = AuthService.getCurrentUser();


  let navigate = useNavigate();

  useEffect(() => {



    if (JSON.parse(localStorage.getItem("user")) == null) {

      //redirectToLogin();

      navigate('/login', { replace: true });

    } else {

      let decodedToken = jwt_decode(currentUser.accessToken);
      let currentDate = new Date();
      // JWT exp is in seconds redirect to login
      if (decodedToken.exp * 1000 < currentDate.getTime()) {

        AuthService.logout();
        navigate('/login', { replace: true });

      }


    }

    if (!currentUser.roles.includes("ROLE_USER")) {

      navigate('/unauthorized', { replace: true });

    }

  }, []);

  const initialDataState = {
    id: null,
    fullname: "",
    phone: "",
    contact: "",
    phone: "",
    email: "",
    other: "",
    remark: "",
    celebrated_day: "",
    celebrated_day_description: "",
    username: "",

    published: false
  };

  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [memberdetails, setMemberDetails] = useState([]);
  const [searchTitleType, setSearchTitleType] = useState("Name");

  const [optionCategorySelectedValueDealer, setOptionCategorySelectedValueDealr] = useState("");
  const [optionCategoryArrayDealer, setOptionCategoryArrayDealer] = useState([]);

  const [isValidSaller, setIsValidSaller] = useState("false");
  const [sallerAccessCode, setSallerAccessCode] = useState("");
  const [savedAccessCode, setSavedAccessCode] = useState("");

  const handleChangeoptionCategoriesDealer = (event) => {
    setOptionCategorySelectedValueDealr(event.target.value);

  }

  const handleChangeoptionCategories = (event) => {

    setMessage("");

    if (customerName === null || customerName === "") {

      setMessage("Customer name is missing!");

    } else if (customerPhone === null || customerPhone === "") {

      setMessage("Customer phone is missing!");

    } else {
      setOptionCategorySelectedValue(event.target.value);

      console.log(event.target.value);

      getSelectedSallerValidCode(event.target.value);

      setLgShow(true);

    }


  }

  const getSelectedSallerValidCode = (saller) => {

    SelectOptionService.getSallerDetails(saller)
      .then((response) => {


        setSavedAccessCode(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

  }

  const handleInputChangePhone = event => {
    const phone = event.target.value;
    setCustomerPhone(phone);

  };

  const handleInputChangeName = event => {
    const name = event.target.value;

    setCustomerName(name);
  };

  const handleInputChangeSallerAccessCode = (e) => {
    setSallerAccessCode(e.target.value);
  }

  const handleInputChangeDealer = event => {
    const name = event.target.value;

    setDealer(name);
  };

  const handleInputChangeCelebratedDay = event => {
    const name = event.target.value;

    setCustomerCelebratedDay(name);
  };

  const handleInputChangeCelebratedDayDescrption = event => {
    const name = event.target.value;

    setCustomerCelebratedDayDescription(name);
  };

  const handleInputChangeDealerOnSiteOnPhone = event => {
    const name = event.target.value;
    set_dealer_on_site_phone(name);
  };



  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };



  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();
  const [customerName, setCustomerName] = useState("");
  const [customerCelebratedDay, setCustomerCelebratedDay] = useState("");
  const [customerCelebratedDayDescription, setCustomerCelebratedDayDescription] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");

  const [dealer, setDealer] = useState("");
  const [dealer_on_site_phone, set_dealer_on_site_phone] = useState("");

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [200, 300, 500];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };



  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (searchTitleType) {
      params["type"] = searchTitleType;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveData = () => {

    setPage(1);

    const params = getRequestParams(searchTitle, page, pageSize);

    CustomersService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    getMemberData();




  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);

        getSelectOption(response.data.account);


      })
      .catch((e) => {
        console.log(e);
      });

  }

  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };



  const findByTitle = () => {
    setSearchTitleType("Name");
    setPage(1);
    retrieveData();
  };

  const findByTitlePhone = () => {
    setSearchTitleType("Phone");
    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {

    setPage(1);

    setcurrentData(initialDataState);

    CustomersService.get(id)
      .then(response => {
        setcurrentData(response.data);
        //  console.log(response.data);
        setCustomerName(response.data.fullname);
        setCustomerPhone(response.data.phone);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const getSelectOption = (location) => {
    setPage(1);
    var category = "SALLER";
    // SelectOptionService.getAllSelectedOptions(category)

    SelectOptionService.getSelectionSallersByBranch(location)
      .then((response) => {

        setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);

      })
      .catch((e) => {
        console.log(e);
      });

    setPage(1);
    var category = "DEAL_TYPE";
    // SelectOptionService.getAllSelectedOptions(category)

    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArrayDealer(response.data);
        // setOptionCategoryArrayTransfer(response.data);

      })
      .catch((e) => {
        console.log(e);
      });
  };

  const customerData = {
    fullname: customerName,
    phone: customerPhone,
    saller: optionCategorySelectedValue,
    dealer: dealer,
    deal_type: dealer_on_site_phone,

  }

  const nextPage = () => {
    setPage(1);

    if (customerName === null || customerName === "") {
      //props.history.push(`/sales-order-customer`);
      navigate('/sales-order-customer', { replace: true })
      setMessage("Customer name is missing!");

    } else if (customerPhone === null || customerPhone === "") {
      //props.history.push(`/sales-order-customer`);
      navigate('/sales-order-customer', { replace: true })
      setMessage("Customer phone is missing!");

    } else if (optionCategorySelectedValue === null || optionCategorySelectedValue === "Select" || optionCategorySelectedValue === "") {
      setMessage("Saller name is missing!");

    }

    // else if(optionCategorySelectedValueDealer===null || optionCategorySelectedValueDealer==="Select Dealer" || optionCategorySelectedValueDealer === ""){
    //   setMessage("Dealer name is missing!");
    // }
    // else {
    //   //props.history.push(`/sales-order-new/?name=${customerName}&phone=${customerPhone}&saller=${optionCategorySelectedValue}&dealer=${dealer} &deal_type=${dealer_on_site_phone}`);
    //   navigate('/sales-order-new/?name=${customerName}&phone=${customerPhone}&saller=${optionCategorySelectedValue}&dealer=${dealer} &deal_type=${dealer_on_site_phone},{replace:true}');
    // }


    //console.log(sallerAccessCode+" "+savedAccessCode.saller_access_code);

    if (sallerAccessCode === null || sallerAccessCode === "") {
      setMessage("Access code missing.");

    } else if (sallerAccessCode === savedAccessCode.saller_access_code) {

      //props.history.push(`/sales-order-new/?name=${customerName}&phone=${customerPhone}&saller=${optionCategorySelectedValue}&dealer=${dealer} &deal_type=${dealer_on_site_phone}`);
      // navigate('/sales-order-new/?name=${customerName}&phone=${customerPhone}&saller=${optionCategorySelectedValue}&dealer=${dealer} &deal_type=${dealer_on_site_phone},{replace:true}');
      navigate('/sales-order-new', { replace: true }, { state: { query: customerData } });


    }

    else {
      setMessage("Incorrect code.");
    }




  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };



  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };



  const stockOut = () => {
    setPage(1);
    //props.history.push("/stock-out");
    navigate('/stock-out', { replace: true })
  }

  const add = () => {
    setPage(1);
    //props.history.push("/customers-new");
    navigate('/customer-new', { replace: true })
  }

  const list = () => {
    //props.history.push("/customers");
    navigate('/customers', { replace: true })
  }

  const [lgShow, setLgShow] = useState(false);


  return (
    <>
      <div className='pages'>

        <div className="row">
          <div className="transfer-institution">
            <table className=" table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="logo-img" /></td>
                  <td className="tg-0lax">ANANDA MONEY TRANSFER</td>
                  <td className="tg-0lax"></td>
                </tr>

                <tr>
                  <td className="tg-0lax">{memberdetails.user_group} </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax">Tel: +250785390822 </td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>


        <div className="row">

          <div className="dataRow-with-two-column ">


            <div className="details">

              <div className="table-wrap">
                <table>
                  <thead className="scroll-thead">
                    <tr>

                      <th>ID</th>
                      <th>FULL NAME</th>
                      <th>PHONE</th>
                      <th>CONTACT</th>
                      <th>EMAIL</th>
                      <th>CELEBRATED DAY</th>
                      <th>CELEBRATED DESCRIPTION</th>
                      <th>OTHER</th>
                      <th>#</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.fullname}</td>
                          <td>{tutorial.phone}</td>
                          <td>{tutorial.contact}</td>
                          <td>{tutorial.email}</td>
                          <td>{tutorial.celebrated_day}</td>
                          <td>{tutorial.celebrated_day_description}</td>
                          <td>{tutorial.other}</td>
                          <td><div className="table-icons-wrapper">

                            <button
                              type="button" className="table-buttons-l"
                              onClick={() => openObject(tutorial.id)}
                            > Name
                            </button>

                          </div></td>
                        </tr>

                      ))}
                  </tbody>

                </table>
              </div>
            </div>

            <div className="details">
              <form className="form form-horizontal ">
                <div className="form-body">
                  <div className="row">


                    <div className="col-md-4">
                      <label>Customer</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input type="text" id="fullname" className="form-control" name="fullname" placeholder="Customer name" onChange={handleInputChangeName} value={customerName} />
                    </div>

                    {/* <div className="col-md-4">
                      <label>Celebrated Day</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input type="text" id="celebrated_day" className="form-control" name="celebrated_day" placeholder=" Celebrated day" onChange={handleInputChangeCelebratedDay} value={customerCelebratedDay} />
                    </div>

                    <div className="col-md-4">
                      <label>Celebrated Description</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input type="text" id="celebrated_day_description" className="form-control" name="celebrated_day_description" placeholder=" Celebrated DESC" onChange={handleInputChangeCelebratedDay} value={customerCelebratedDayDescription} />
                    </div> */}

                    <div className="col-md-4">
                      <label>Phone</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <input type="text" id="comment" className="form-control" name="phone" placeholder="Phone" onChange={handleInputChangePhone} value={customerPhone} />
                    </div>

                    <div className="col-md-4">
                      <label>Dealer</label>
                    </div>

                    <div className="col-md-8 form-group">
                      <input type="text" id="comment" className="form-control" name="dealer" placeholder="Dealer" onChange={handleInputChangeDealer} value={dealer} />

                    </div>

                    <div className="col-md-4">
                      <label>Type</label>
                    </div>

                    <div className="col-md-8 form-group">

                      <select className="form-control" onChange={handleInputChangeDealerOnSiteOnPhone} >
                        <option>Select Type</option>
                        {optionCategoryArrayDealer.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div>

                    <div className="col-md-4">
                      <label>Saller</label>
                    </div>
                    <div className="col-md-8 form-group">
                      <select className="form-control" onChange={handleChangeoptionCategories} >
                        <option>Select</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div>


                    <div className="col-md-4">
                      <label> </label>
                    </div>

                    <label className="col-md-8 danger-message">{message}</label>

                    <div className="col-md-4">
                      <label> </label>
                    </div>

                    <div className="col-md-8 form-group">

                      {isValidSaller ? ("") : (<><button onClick={() => setLgShow(true)}
                        type="button" className="button-form">Next</button></>)}

                    </div>
                  </div>
                </div>
              </form>

            </div>

          </div>
        </div>

      </div>

      <Modal
        size="sm"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Saller Access Code

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>
              <label className="col-md-8 danger-message">{message}</label>
              <hr />
              <div className="col-md-8 form-group">
                <input type="password" autoComplete="off" id="sallerAccessCode" className="form-control" name="sallerAccessCode" placeholder="Access Code" onChange={handleInputChangeSallerAccessCode} />
              </div>
              <hr />
              <div className="row">  <Link onClick={nextPage}
                type="button" className="button-tools" to="/sales-order-new" state={customerData}>Next</Link></div>
              <div className="row"> <button onClick={() => setLgShow(false)} className="button-form-modal-sm">Close</button> </div>


            </center>





          </div>


        </Modal.Body>
      </Modal>
    </>
  );
};