import React, { useState, useEffect, useRef } from "react";
import { Modal } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import { FcServices, FcCustomerSupport } from 'react-icons/fc';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";
import { BsPersonBoundingBox } from 'react-icons/bs';
import { AiOutlinePhone, AiOutlinePrinter } from 'react-icons/ai';
import CurrencyFormat from 'react-currency-format';

//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import CustomersService from "../../services/customers.service";
import MemberService from "../../services/members.service";
import OrderService from "../../services/order.service";

import { useNavigate, Link, Outlet } from 'react-router-dom';

import { FaMoneyBillTransfer } from "react-icons/fa6";
import logo from '../images/ananda.jpg'
import OrganizationDetailsService from "../../services/organization-details.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const currentUser = AuthService.getCurrentUser();


export default function CustomersList() {
  let navigate = useNavigate();

  useEffect(() => {

    if (JSON.parse(localStorage.getItem("user")) == null) {

      navigate('/login', { replace: true });
      AuthService.reloadWindow();

    } else {

      let decodedToken = jwt_decode(currentUser.accessToken);
      let currentDate = new Date();
      // JWT exp is in seconds redirect to login
      if (decodedToken.exp * 1000 < currentDate.getTime()) {

        AuthService.logout();
        navigate('/login', { replace: true });
        AuthService.reloadWindow();

      }

    }

  }, []);

  const initialDataState = {
    id: null,
    fullname: "",
    phone: "",
    contact: "",
    phone: "",
    email: "",
    other: "",
    remark: "",
    username: "",

    published: false
  };

  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [memberdetails, setMemberDetails] = useState([]);
  const [organizationDetails, setorganizationDetails] = useState([]);
  const [currentOrderData, setCurrentOrderData] = useState([]);
  const [currentOrderDataSummary, setCurrentOrderDataSummary] = useState([]);
  const [cash, setCash] = useState("");
  const [momo, setMomo] = useState("");
  const [visa, setVisa] = useState("");
  const [cheque, setCheque] = useState("");
  const [transfer, setTransfer] = useState("");
  const [comment, setComment] = useState("");

  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);
  const [lgShow3, setLgShow3] = useState(false);
  const [lgShow4, setLgShow4] = useState(false);


  const componentRef = useRef(null);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  // const saveData = () => {

  //   setPage(1);

  //   var data = {
  //     code: tutorial.code,
  //     name: tutorial.name,
  //     description: tutorial.description,
  //     min_price: tutorial.min_price,
  //     max_price: tutorial.max_price,
  //     color: tutorial.color,
  //     size: tutorial.size,
  //     celebrated_day: tutorial.celebrated_day,
  //     celebrated_day_description: tutorial.celebrated_day_description,
  //     remark: tutorial.remark,
  //     username: currentUser.username
  //   };



  //   CustomersService.create(data)
  //     .then(response => {
  //       setData({
  //         id: response.data.id,
  //         code: response.data.code,
  //         name: response.data.name,
  //         description: response.data.description,
  //         min_price: response.data.min_price,
  //         max_price: response.data.max_price,
  //         color: response.data.color,
  //         size: response.data.size,
  //         celebrated_day: response.data.celebrated_day,
  //         celebrated_day_description: response.data.celebrated_day_description,
  //         remark: response.data.remark,
  //         code: response.data.code,
  //         username: response.data.username

  //       });
  //       setSubmitted(true);
  //       //console.log(response.data);

  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // };

  // const newData = () => {
  //   setData(initialDataState);
  //   setSubmitted(false);
  // };


  const saveData = () => {

    var data = {
      fullname: tutorial.fullname,
      contact: tutorial.contact,
      phone: tutorial.phone,
      email: tutorial.email,
      other: tutorial.other,
      remark: tutorial.remark,
      celebrated_day: tutorial.celebrated_day,
      celebrated_day_description: tutorial.celebrated_day_description,
      username: currentUser.username
    };



    CustomersService.create(data)
      .then(response => {
        setData({
          id: response.data.id,
          fullname: response.data.fullname,
          contact: response.data.contact,
          phone: response.data.phone,
          email: response.data.email,
          other: response.data.other,
          remark: response.data.remark,
          celebrated_day: response.data.celebrated_day,
          celebrated_day_description: response.data.celebrated_day_description,
          username: response.data.username

        });
        setSubmitted(true);
        setMessage("Data saved successfully.");
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

  };

  const newData = () => {
    setData(initialDataState);
    setSubmitted(false);
    setMessage("");
    //props.history.push("/customers-new/");
    navigate('/customers-new', { replace: true });

  };

  const [tutorials, setDatas] = useState([]);
  const [tutorialsSales, setDatasSales] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchTitleType, setSearchTitleType] = useState("Name");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [200, 300, 500];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };




  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (searchTitleType) {
      params["type"] = searchTitleType;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveData = () => {

    setPage(1);

    const params = getRequestParams(searchTitle, page, pageSize, searchTitleType);

    CustomersService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        getMemberData();
      })
      .catch((e) => {
        console.log(e);
      });

      getOrganizationDetails();
  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }

  const getOrganizationDetails = () => {
    setPage(1);

    OrganizationDetailsService.get(1)
      .then((response) => {

        setorganizationDetails(response.data);
        console.log(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }


  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };



  const handleSearch = () => {
    setSearchTitleType("Name");
    setPage(1);
    retrieveData();
  };

  const handleSearchPhone = () => {
    setPage(1);

    setSearchTitleType("Phone");

    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {
    setPage(1);

    setcurrentData(initialDataState);
    // const id = tutorialsRef.current[rowIndex].id;


    CustomersService.get(id)
      .then(response => {
        setcurrentData(response.data);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

    setLgShow3(true);
  };

  const updateData = () => {
    CustomersService.update(currentData.id, currentData)
      .then(response => {
        // console.log(response.data);
        setMessage("The Data was updated successfully!");
      })
      .catch(e => {
        console.log(e);
      });
  };

  const deleteData = (rowIndex) => {
    const id = tutorialsRef.current[rowIndex].id;

    CustomersService.remove(id)
      .then((response) => {
        /// props.history.push("/product");

        let newDatas = [...tutorialsRef.current];
        newDatas.splice(rowIndex, 1);

        setDatas(newDatas);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const add = () => {
    //props.history.push("/customers-new");
    navigate('/customers-new', { replace: true })
  }

  const openData = (id) => {
    setPage(1);

    console.log("=====cash====" + id + " ");

    OrderService.getOrderByNo(id)
      .then(response => {

        const { tutorials, totalPages, cash, momo, visa, cheque, transfer } = response.data;
        setCurrentOrderData(tutorials);
        // console.log("=====cash===="+cashAmount+" ");
        setCash(cash);
        setMomo(momo);
        setVisa(visa);
        setCheque(cheque);
        setTransfer(transfer);


      })
      .catch(e => {
        console.log(e);
      });

    OrderService.getOrderByNoSummary(id)
      .then(response => {
        setCurrentOrderDataSummary(response.data);



      })
      .catch(e => {
        console.log(e);
      });

    setLgShow2(true);

  }

  const searchOrderPhone = (searchText) => {

    setLgShow(true);
    setPage(1);
    OrderService.searchSalesPhone(searchText)
      .then(response => {

        const { tutorials, totalPages } = response.data;

        setDatasSales(tutorials);
        setCount(totalPages);


      })
      .catch(e => {
        console.log(e);
      });
  }

  const list = () => {
    //props.history.push("/customers/");
    navigate('/customers', { replace: true })
  };


  return (
    <>
      <div className='pages'>

        <div className="row">
          {/* cards  */}

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <div className="table-account">
                <tbody>
                  <tr>
                    <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>{memberdetails.firstname}</td>
                    <td>{memberdetails.lastname}</td>
                  </tr>
                  <tr>
                    <td>Branch:</td>
                    <td>{memberdetails.account}</td>
                  </tr>

                  <tr>
                    <td colSpan="2"> <input type="text" value={searchTitle}
                      onChange={onChangeSearchTitle} placeholder="Search" className="search-input"></input> </td>
                    <td><button onClick={handleSearch} className="button-tools-sm"> <span className="table-tools-img-search"><BsPersonBoundingBox /></span></button><button onClick={handleSearchPhone} className="button-tools-sm"> <span className="table-tools-img-search"><AiOutlinePhone /></span></button></td>
                  </tr>
                </tbody>
              </div>
            </table>


          </div>
          <div className="col-md-3 tool-box">
            <table>
              <tbody>
                <tr>
                  <td><span className="page-title-img"><FcCustomerSupport /> </span></td>
                  <td><span className="page-title"> Customer</span></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  <td className="tg-0lax"><button onClick={() => setLgShow4(true)} className="button-tools">New</button></td>
                </tr>
                <tr>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"> </td>
                </tr>
              </tbody>

            </table>
          </div>


          {/* end card box */}

          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>

              <div className="col-md-3 table-title">  CUSTOMERS LIST  </div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>

            </div>
            <div className="details">

              <div className="table-wrap">

                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ID</th>
                      <th>FULL NAME</th>
                      <th>CONTACT</th>
                      <th>PHONE</th>
                      <th>EMAIL</th>
                      <th>CELEBRATED DAY</th>
                      <th>CELEBRATED DESCRIPTION</th>
                      <th>OTHER</th>
                      <th>#</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.fullname}</td>
                          <td>{tutorial.contact}</td>
                          <td>{tutorial.phone}</td>
                          <td>{tutorial.email}</td>
                          <td>{tutorial.celebrated_day}</td>
                          <td>{tutorial.celebrated_day_description}</td>
                          <td>{tutorial.other}</td>
                          <td>
                            <div className="table-icons-wrapper">

                              <button
                                type="button" className="table-buttons-l"
                                onClick={() => openObject(tutorial.id)}
                              >
                                Edit

                              </button>

                              <button
                                type="button" className="table-buttons-l"
                                onClick={() => searchOrderPhone(tutorial.phone)}
                              >
                                Historic

                              </button>

                            </div></td>
                        </tr>

                      ))}
                  </tbody>

                </table>

              </div>

            </div>

          </div>
        </div>
      </div>


      {/* View Customer Historic */}
      <Modal
        size="xl"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >


        <Modal.Body className="modal-bg">

        <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Historic  </div></center>
          <div className="dataRow-with-two-column-center">

            {/* col 1 */}
            <div className="details-small">

              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">
                    

                      
                        <table className="table ">
                          <thead className="scroll-thead">
                            <tr>

                              <th className="table-header-size-lg">Customer</th>
                              <th className="table-header-size-md">Phone</th>
                              <th className="table-header-size-lg">Saller</th>

                              <th>Total</th>
                              <th >Payed</th>
                              <th >Balance</th>
                              <th className="table-header-size-md">Date</th>
                              <th className="table-header-size-md">Status</th>

                              <th>#</th>

                            </tr>
                          </thead>
                          <tbody>
                            {tutorialsSales &&
                              tutorialsSales.map((tutorial, index) => (
                                <tr>

                                  <td>{tutorial.customer}</td>
                                  <td>{tutorial.phone}</td>
                                  <td>{tutorial.saller}</td>

                                  <td>{<CurrencyFormat value={tutorial.amount} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />}</td>
                                  <td>{<CurrencyFormat value={tutorial.amount_payed} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />}</td>
                                  <td> {<CurrencyFormat value={tutorial.amount - tutorial.amount_payed} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />}</td>
                                  <td>{tutorial.orderTime}</td>
                                  <td><span className={"status " + tutorial.status}>{tutorial.status}</span></td>


                                  <td><div className="row">

                                    <button
                                      type="button" className="col-md-1 table-buttons"
                                      onClick={() => openData(tutorial.orderid)}
                                    > <AiOutlinePrinter />
                                    </button>

                                  </div></td>
                                </tr>

                              ))}
                          </tbody>

                        </table>

                        <div className="col-md-4">
                          <label> </label>
                        </div>
                        <div className="col-md-8 form-group">
                          <button
                            type="button" onClick={() => setLgShow(false)} className="button-tools-lg"><ImCancelCircle /> Close</button>
                          <br /> <br />
                        </div>
                      </div>
                    
                  
                </form>
              </center>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
          <center> <div>Ananda</div> </center>
        </Modal.Footer>
      </Modal>

      {/* Add Comment */}
      <Modal ref={componentRef}
        size="lg"
        backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">
              Add commments
            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">



          <div className="dataRow">
            <div className="details">


              <table>
                <tbody>
                  <tr>
                    <td><h5>Customer :{currentOrderDataSummary.customer}</h5></td>

                  </tr>
                  <tr>
                    <td><h5>Order :{currentOrderDataSummary.orderNo}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Date :{currentOrderDataSummary.date}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Total :{currentOrderDataSummary.total}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Payed :{currentOrderDataSummary.payed}</h5></td>
                  </tr>
                  <tr>
                    <td><h5>Balance :{currentOrderDataSummary.balance}</h5></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div className="table-wrap">


                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ITEM</th>
                      <th>CODE</th>
                      <th>PRICE</th>
                      <th>QTY</th>
                      <th>TOTAL</th>


                    </tr>
                  </thead>
                  <tbody>
                    {currentOrderData &&
                      currentOrderData.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.product}</td>
                          <td>{tutorial.code}</td>
                          <td>{<CurrencyFormat value={tutorial.price} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />}</td>
                          <td>{tutorial.qty}</td>
                          <td>{<CurrencyFormat value={tutorial.total} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />}</td>

                        </tr>

                      ))}
                  </tbody>

                </table>

                <div className="row">

                  <div className="col-md-3">
                    Cash:{cash} <br></br>
                    MoMo:{momo} <br></br>
                    Visa:{visa} <br></br>
                    Cheque:{cheque} <br></br>
                    Transfer:{transfer} <br></br>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>



      {/* EDITING CUSTOMER */}
      <Modal
        size="md"
        backdrop="static"
        show={lgShow3}
        onHide={() => setLgShow3(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >


        <Modal.Body className="modal-bg">
        <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Customer Edit </div></center>
          <div className="dataRow-with-one-column-center">

            {/* col 1 */}
            <div className="details-small">

              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">

                    <div className="row">
                      <div className="col-md-4 form-label">
                        <label>Fullname</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="fullname" className="form-control" name="fullname" placeholder="Full name" onChange={handleInputChangeEdit} validations={[required]} value={currentData.fullname} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Contact</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="contact" className="form-control" name="contact" placeholder="Contact" onChange={handleInputChangeEdit} validations={[required]} value={currentData.contact} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Phone</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="phone" className="form-control" name="phone" placeholder="Phone" onChange={handleInputChangeEdit} validations={[required]} value={currentData.phone} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Email</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="email" id="email" className="form-control" name="email" placeholder="Email" onChange={handleInputChangeEdit} validations={[required]} value={currentData.email} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Celebrated day</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="celebrated_day" className="form-control" name="celebrated_day" placeholder="Celebration Day" onChange={handleInputChangeEdit} validations={[required]} value={currentData.celebrated_day} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Celebrated description </label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="celebrated_day_description" className="form-control" name="celebrated_day_description" placeholder="Celebration Description" onChange={handleInputChangeEdit} validations={[required]} value={currentData.celebrated_day_description} />
                      </div>


                      <div className="col-md-4 form-label">
                        <label>Other</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="other" className="form-control" name="other" placeholder="Other" onChange={handleInputChangeEdit} validations={[required]} value={currentData.other} />
                      </div>

                      <div className="col-md-4">
                        <label>Remark</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="remark" className="form-control" name="remark" placeholder="remark" onChange={handleInputChangeEdit} validations={[required]} value={currentData.remark} />
                      </div>


                      <div className="col-md-4 form-label">
                        <label> </label>
                      </div>
                      <div className="col-md-8 form-group">


                        <button onClick={updateData}
                          type="button" className="button-tools"><ImFloppyDisk /> Update</button>

                        <button
                          type="button" onClick={() => setLgShow3(false)} className="button-tools"><ImCancelCircle /> Close</button>


                        <br /> <br />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8">{message}</label>

                    </div>
                  </div>
                </form>
              </center>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
          <center> <div>Ananda</div> </center>
        </Modal.Footer>
      </Modal>



      {/* Add new customers */}
      <Modal
        size="md"
        backdrop="static"
        show={lgShow4}
        onHide={() => setLgShow4(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >


        <Modal.Body className="modal-bg">

          <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Customer </div></center>
          <div className="dataRow-with-one-column-center">

            {/* col 1 */}
            <div className="details-small">

              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">


                    <div className="row">

                      <div className="col-md-5"> <hr /> </div>

                      <div className="col-md-2"><center> <span className="modal-title"> New </span> </center></div>
                      <div className="col-md-5"><hr /></div>

                    </div>
                    <div className="row">
                      <div className="col-md-4 form-label">
                        <label>Full name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="fullname" className="form-control" name="fullname" placeholder="customer name" onChange={handleInputChange} validations={[required]} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Contact</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="contact" className="form-control" name="contact" placeholder="Contact" onChange={handleInputChange} validations={[required]} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Phone</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="phone" className="form-control" name="phone" placeholder="Phone" onChange={handleInputChange} validations={[required]} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Email</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="email" id="email" className="form-control" name="email" placeholder="email" onChange={handleInputChange} validations={[required]} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Celebrated day</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="celebrated_day" className="form-control" name="celebrated_day" placeholder="Celebrated day" onChange={handleInputChange} validations={[required]} />
                      </div>

                      <div className="col-md-4 form-label" >
                        <label>Celebrated Descrption</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="celebrated_day_description" className="form-control" name="celebrated_day_description" placeholder="Celebrated descrption" onChange={handleInputChange} validations={[required]} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Other</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="other" className="form-control" name="other" placeholder="Other" onChange={handleInputChange} validations={[required]} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Remark</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="remark" className="form-control" name="remark" placeholder="Remark" onChange={handleInputChange} validations={[required]} />
                      </div>


                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <div className="col-md-8 form-group">

                        {submitted ? (<button onClick={newData}
                          className="button-form"><ImPlus /> Add</button>) : (<button onClick={saveData}
                            type="button" className="button-tools"><ImFloppyDisk /> Save</button>)}


                        <button
                          type="button" onClick={() => setLgShow4(false)} className="button-tools"><ImCancelCircle /> Close</button>
                        <br /> <br />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8">{message}</label>


                    </div>
                  </div>
                </form>
              </center>
            </div>


          </div>
        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
          <center> <div>Ananda</div> </center>
        </Modal.Footer>
      </Modal>
    </>
  );
};
