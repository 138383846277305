import http from "./http-common";
import authHeader from "./auth-header";



  
  const getReport = (data) => {
    return http.post("/dashboard",data, { headers: authHeader() });
  };
  
  const DashboardService = {
         
      getReport,
     
  
  }
   export default DashboardService;