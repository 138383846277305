import React, { useState, useRef, useEffect } from "react";
import { GiTimeBomb } from 'react-icons/gi';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
//import OrderService from "../../services/order.service";
import ReportService from "../../services/report.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';
import MemberService from "../../services/members.service";
import { useNavigate } from 'react-router-dom';

const currentUser = AuthService.getCurrentUser();

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default function ReportStandProfit(){
  let navigate = useNavigate();

  useEffect(() => {
     if (JSON.parse(localStorage.getItem("user")) == null) {
        navigate('/login', { replace: true });
        AuthService.reloadWindow();

     } else {

        let decodedToken = jwt_decode(currentUser.accessToken);
        let currentDate = new Date();
        // JWT exp is in seconds redirect to login
        if (decodedToken.exp * 1000 < currentDate.getTime()) {

           AuthService.logout();
           navigate('/login', { replace: true });
           AuthService.reloadWindow();

        }
     }
  }, []);

  const initialDataState = {
    id: null,
    orderId: "",
    orderTime: "",
    pid: "",
    qty: "",
    sie: "",
    color: "",
    price: "",
    saller: "",
    customer: "",
    phone: "",
    salesStatus: "",
    stand: "",

    username: "",
  };



  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [profit, setProfit] = useState([]);
  const [profit_total, setProfit_total] = useState("");
  const [standSoldAmount, setStandSoldAmount] = useState("");
  const [standSoldTotalItem, setsSandSoldTotalItem] = useState("");
  const [soldProductsData, setSoldProductsData] = useState([]);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [orderedData, setOrderedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [memberdetails, setMemberDetails] = useState([]);
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategorySelectedValueDescription, setOptionCategorySelectedValueDescription] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [optionCategoryArrayDescription, setOptionCategoryArrayDescription] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();
  const [isManager, setIsManager] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [role, setRole] = useState("Cashier");
  const [cashierName, setCashierName] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const [cash, setCash] = useState("");
  const [momo, setMomo] = useState("");
  const [visa, setVisa] = useState("");
  const [cheque, setCheque] = useState("");
  const [transfer, setTransfer] = useState("");
  const componentRef = useRef(null);
  const pageSizes = [100, 300, 500];
  tutorialsRef.current = tutorials;

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);
    setCashierName(event.target.value);
  }

  const handleChangeoptionCategoriesDescription = (event) => {
    setOptionCategorySelectedValueDescription(event.target.value);
  }

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value);
  }

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (isManager == true) {
      setRole("Manager");
    }

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (role) {
      params["role"] = role;
    }

    if (memberdetails.account) {
      params["location"] = memberdetails.account;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    if (startDate) {
      params["date"] = format(startDate, 'yyyy-MM-dd');
    }

    if (currentUser.username) {
      params["username"] = currentUser.username;
    }

    if (optionCategorySelectedValue) {
      params["cashier"] = optionCategorySelectedValue;
    }

    if (isReport) {
      params["report"] = isReport;
    }



    return params;
  };


  const retrieveData = () => {
    setPage(1);
    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setCashierName(optionCategorySelectedValue);
    } else {
      setCashierName(memberdetails.account);
    }
    var data = {
      text1: format(startDate, 'yyyy-MM-dd') + " 00:00:00",
      text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
      text3: memberdetails.account,
      text4: cashierName,
      text5: cashierName,
      text6: cashierName,
      text7: optionCategorySelectedValueDescription,
    }

    ReportService.salesReportProfit(data)
      .then((response) => {
         const { profit,profit_total } = response.data;
         setProfit(profit);
         setProfit_total(profit_total);
        //  console.log(profit_total);    
      })
      .catch((e) => {
        console.log(e);
      });
    getSelectOption();
    getMemberData();
  };

  

  const getMemberData = () => {
    setPage(1);
    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {
        setMemberDetails(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setIsManager(true);
    }
  }

  const getSelectOption = () => {
    setPage(1);
    var category = "STOCK-NAME";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {
        setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

      var category1 = "DESCRIPTION-TYPE";
    SelectOptionService.getAllSelectedOptions(category1)
      .then((response) => {
        setOptionCategoryArrayDescription(response.data);
        // setOptionCategoryArrayTransfer(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };

  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const handleChangeEndDate = (date) => {
    setEndDate(date);
  }

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  }

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const cashierReports =(name) =>{
    // props.history.push("/reports-cashier/?x=$"+currentUser.username);
  }

  const reportSallers =() =>{
    // props.history.push("/reports-sallers");
    navigate('/reports-sallers',{replace: true});
  }

  const reportSales =() =>{
    // props.history.push("/reports-sales");
    navigate('/reports-sales',{replace: true});
  }

  const reportJournal =() =>{
    // props.history.push("/reports-journal");
    navigate('/reports-journal',{replace: true});
  }

  const reportCashing =() =>{
    // props.history.push("/reports-cashing");
    navigate('/reports-cashing',{replace: true});
  }

  const reportStand = () => {
    // props.history.push("/reports-stand");
    navigate('/reports-stand',{replace: true});
  }
  

  return (
    <>
      <div className='pages'>
        <div className="row">
          {/* cards  */}
          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <tbody>
              <tr>
                <td rowSpan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
                <td> 
                {isManager ? (<DatePicker className="form-control datePicker-style"
                  selected={startDate}
                  onChange={handleChangeStartDate}
                  name="startDate"
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                />):(<DatePicker className="form-control datePicker-style"
                  selected={startDate}
                  onChange={handleChangeStartDate}
                  name="startDate"
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                />)}
                  </td>
                <td>
                {isManager ? (<DatePicker  className="form-control datePicker-style"
                  selected={endDate}
                  onChange={handleChangeEndDate}
                  name="endDate"
                  maxDate={new Date()}
                  dateFormat="yyyy-MM-dd"
                />):(<DatePicker className="form-control datePicker-style"
                selected={endDate}
                onChange={handleChangeEndDate}
                name="endDate"
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd"
              />)}
                  </td>
              </tr>
              <tr>
               {isManager ?(<>
               <td>
                <div className="col-md-8 form-group">
                    <select className="form-control" onChange={handleChangeoptionCategories} >
                      <option>Branch</option>
                      {optionCategoryArray.map((optionCategory) => (
                        <option key={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select>
                  </div>
                  </td>
                  <div className="col-md-8 form-group">
                    {/* <select className="form-control" onChange={handleChangeoptionCategoriesDescription} >
                      <option>Description</option>
                      {optionCategoryArrayDescription.map((optionCategory) => (
                        <option key={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select> */}
                  </div>
                  </>):(<>
                  <td>
                  <div className="col-md-8 form-group">
                    <select disabled className="form-control" onChange={handleChangeoptionCategories} >
                      <option>{memberdetails.account}</option>
                      {optionCategoryArray.map((optionCategory) => (
                        <option key={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select>
                  </div>
                  </td>
                  <td>
                  <div className="col-md-8 form-group">
                    {/* <select  className="form-control" onChange={handleChangeoptionCategories} >
                      <option>Description</option>
                      {optionCategoryArrayDescription.map((optionCategory) => (
                        <option key={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select> */}
                  </div>
                  </td>
                  </>)} 
              </tr>
              {/* {isManager ? (<tr>
                <td>Stand</td>
                <td>
                  <div className="col-md-8 form-group">
                    <select className="form-control" onChange={handleChangeoptionCategories} >
                      <option>Select</option>
                      {optionCategoryArray.map((optionCategory) => (
                        <option key={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select>
                  </div></td>
              </tr>) : (<tr>
                <td>Stand</td>
                <td>
                  <div className="col-md-8 form-group">
                    <select disabled className="form-control" onChange={handleChangeoptionCategories} >
                      <option>{memberdetails.account}</option>
                      {optionCategoryArray.map((optionCategory) => (
                        <option key={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select>
                  </div></td>
              </tr>)} */}
            </tbody>
            </table>
          </div>

          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <tbody>
             <tr>
                <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                <td className="tg-0lax"><button onClick={reportSallers}  className="button-tools">Sallers</button></td>
                <td className="tg-0lax"><button onClick={reportSales}  className="button-tools">Sales</button></td>
              </tr>
              <tr>
                <td className="tg-0lax"><button onClick={reportJournal} className="button-tools">Journal</button></td>
                <td className="tg-0lax"><button onClick={reportCashing}  className="button-tools">Cashing</button></td>
                <td className="tg-0lax"><button onClick={reportStand} className="button-tools">Stand</button></td>
              </tr>
              </tbody>              
            </table>
          </div>
          {/* end card box */}
          <div className="dataRow">
            <div className="details">            
              <center className="table-title"> INCOME | Total: {profit_total} </center>
              <hr></hr>
              <table className="table">
                <thead className="scroll-thead">
                  <tr>
                    <th>CODE</th>
                    <th>PRODUCT</th>
                    <th>PURCHASE</th>
                    <th>SALE</th>
                    <th>QTY</th>
                    <th>T.PURCHASE</th>
                    <th>T.SALE</th>
                    <th>INCOME</th>
                  </tr>
                </thead>
                <tbody>
                    {profit &&
                      profit.map((tutorial, index) => (
                        <tr >
                          <td>{tutorial.code}</td>
                          <td>{tutorial.product}</td>
                          <td>{tutorial.purchase}</td>
                          <td>{tutorial.sales}</td>

                          <td>{tutorial.qty}</td>
                          <td>{tutorial.total_purchase}</td>
                          <td>{tutorial.total_sales}</td>
                          <td>{tutorial.total_income}</td>
                        </tr>
                      ))}
                  </tbody>
              </table>
              Total:{standSoldAmount}
            </div>
          </div>        
        </div>
      </div>

      <Modal ref={componentRef}
        size="lg"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="row">
              {/* <div className="col-md-6"> <label> {customerName} {customerPhone} </label></div> */}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">
          <div className="dataRow">
            <div className="details">
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
