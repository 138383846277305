import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { Modal } from 'react-bootstrap';
import { ImPencil } from "react-icons/im";
import { AiTwotoneDelete } from "react-icons/ai";

import { withRouter } from 'react-router';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import AccessControlOtpService from "../../services/access-control-otp.service"
import SelectOptionService from "../../services/select-options.service";

import { useNavigate, Link, Outlet } from 'react-router-dom';


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


const currentUser = AuthService.getCurrentUser();


export default function  AccessOtpList() {

  let navigate = useNavigate();
  const nav = useNavigate();


  if (JSON.parse(localStorage.getItem("user")) == null) {

    //return <Redirect to="/login" />;

    navigate('/login', { replace: true });

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    //return <Redirect to="/login" />;

    navigate('/login', { replace: true });

  }

  if (!currentUser.roles.includes("ROLE_ADMIN")) {
    //return <Redirect to="/unauthorized" />;

  }

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    user: "",
    phone: "",
    accessCode: "",
    type: "",
    other: "",
    date: "",
    username: "",


  };

  const [tutorial, setData] = useState(initialDataState);

  const [currentData, setcurrentData] = useState(initialDataState);
  const [memberdetails, setMemberDetails] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategorySelectedValueType, setOptionCategorySelectedValueType] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [optionCategoryArrayType, setOptionCategoryArrayType] = useState([]);

  const [message, setMessage] = useState("");
  const [username, setUsername] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [messageNewPassword, setMessageNewPassword] = useState("");

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [300, 500, 1000];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });

  };

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);
    setUsername(event.target.value);
  }

  const handleChangeoptionCategoriesType = (event) => {
    setOptionCategorySelectedValueType(event.target.value);

  }

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveData = () => {
    setPage(1);


    const params = getRequestParams(searchTitle, page, pageSize);

    AccessControlOtpService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

      })
      .catch((e) => {
        console.log(e);
      });

    getSelectOption();

  };



  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };



  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {

    setLgShow2(true);
    setMessage("");

    setPage(1);

    setcurrentData(initialDataState);


    AccessControlOtpService.get(id)
      .then(response => {

        setcurrentData(response.data);

      })
      .catch(e => {
        console.log(e);
      });

  };

  const getSelectOption = () => {

    setPage(1);


    SelectOptionService.getAllUsernames()
      .then((response) => {

        setOptionCategoryArray(response.data);

      })
      .catch((e) => {
        console.log(e);
      });

    var category = "OTP-TYPE";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArrayType(response.data);

      })
      .catch((e) => {
        console.log(e);
      });


  };



  const remove = (id) => {

    setMessage("");
    setPage(1);

    setcurrentData(initialDataState);


    AccessControlOtpService.remove(id)
      .then(response => {

        refreshList();

        setMessage(response.data);


      })
      .catch(e => {
        console.log(e);
      });

  };


  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);

  const registration = () => {
    //props.history.push("/registration/");
  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const updateData = () => {


    setPage(1);

    setMessage("");

    if (currentData.macAddress === null || currentData.macAddress === "") {
      setMessage("Mac address is missing!");
    } else if (currentData.user === null || currentData.user === "") {
      setMessage("user is missing!");
    } else {

      AccessControlOtpService.update(currentData.id, currentData)
        .then(response => {

          setMessage(response.data);
          refreshList();
        })
        .catch(e => {
          console.log(e);
        });

    }

  };

  const updateDataReset = () => {


    setPage(1);

    setMessage("");

    console.log("=====270====" + currentData.id);

    AccessControlOtpService.updateReset(currentData.id)
      .then(response => {

        setMessage(response.data);
        refreshList();
      })
      .catch(e => {
        console.log(e);
      });



  };

  const save = () => {

    setMessage("");

    if (tutorial.phone === null || tutorial.phone === "") {
      setMessage("Phone is missing!");
    } else if (tutorial.type === null || tutorial.type === "") {
      setMessage("Type is missing!");
    }

    else {

      var data = {
        id: tutorial.id,
        user: tutorial.user,
        phone: tutorial.phone,
        accessCode: tutorial.accessCode,
        type: tutorial.type,
        other: tutorial.other,
        username: currentUser.username
      }

     // console.log(data);

      AccessControlOtpService.create(data)
        .then(response => {

          if (response.data === 1) {
            setMessage("Saved sucessfully.");
            refreshList();
            newData();
          } else {
            setMessage("Not saved!");
          }


        })
        .catch(e => {
          console.log(e);
        });

    }



  }

  const newData = () => {
    setSubmitted(true);
    setMessage("");
    setData(initialDataState);

  }

  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
                <tr>
                  <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>{memberdetails.firstname}</td>
                  <td>{memberdetails.lastname}</td>
                </tr>
                <tr>
                  <td>Branch:</td>
                  <td>{memberdetails.account}</td>
                </tr>
                <tr>
                  <td colSpan="2"> <input type="text" value={searchTitle}
                    onChange={onChangeSearchTitle} placeholder="Search" className="search-input"></input> </td>
                  <td><button onClick={findByTitle} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td>
                </tr>
              </tbody>
            </table>



          </div>

          <div className="col-md-3 tool-box">

            <table className=" table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  <td className="tg-0lax"><button onClick={() => setLgShow(true)} className="button-tools">New</button></td>
                </tr>
                <tr>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
              </tbody>
            </table>
          </div>


          {/* end card box */}



          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>

              <div className="col-md-3 table-title">  OTP </div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>



            </div>
            <div className="details">

              <div className="table-wrap">

                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ID</th>
                      <th>USER</th>
                      <th>PHONE</th>
                      <th>ACCESS CODE</th>
                      <th>TYPE</th>
                      <th>OTHER</th>
                      <th>DATE</th>
                      <th>USERNAME</th>
                      <th>TOOLS</th>

                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.user}</td>
                          <td>{tutorial.phone}</td>
                          <td>{tutorial.accessCode}</td>
                          <td>{tutorial.type}</td>
                          <td>{tutorial.other}</td>
                          <td>{tutorial.date}</td>
                          <td>{tutorial.username}</td>
                          <td><div className="row">

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => openObject(tutorial.id)}
                            > <ImPencil />
                            </button>

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => remove(tutorial.id)}
                            > <AiTwotoneDelete />
                            </button>



                          </div></td>
                        </tr>

                      ))}
                  </tbody>

                </table>

              </div>

            </div>

          </div>
        </div>

      </div>

      <Modal
        size="lg"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add OTP access

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>

              <div className="details-sm">

                <form className="form form-horizontal ">
                  <div className="form-body">
                    <div className="row">

                      <div className="col-md-4">
                        <label>User </label>
                      </div>
                      <div className="col-md-8 form-group">
                        <select className="form-control select-option" id="user" name="user" onChange={handleInputChange} >
                          <option>Select</option>
                          {optionCategoryArray.map((optionCategory) => (
                            <option key={optionCategory.email}>{optionCategory.email}</option>
                          ))}

                        </select>
                      </div>
                      <div className="col-md-4">
                        <label>Phone</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="phone" className="form-control" name="phone" placeholder="Phone" onChange={handleInputChange} validations={[required]} value={tutorial.phone} />
                      </div>

                      <div className="col-md-4">
                        <label>OTP</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="accessCode" className="form-control" name="accessCode" placeholder="Access-code" onChange={handleInputChange} validations={[required]} value={tutorial.accessCode} />
                      </div>

                      <div className="col-md-4">
                        <label>TYPE</label>
                      </div>
                      <div className="col-md-8 form-group">                        
                        <select className="form-control select-option" id="type" name="type" onChange={handleInputChange} >
                          <option>Select</option>
                          {optionCategoryArrayType.map((optionCategory) => (
                            <option key={optionCategory.value}>{optionCategory.value}</option>
                          ))}

                        </select>
                      </div>
                      <div className="col-md-4">
                        <label>OTHER</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="other" className="form-control" name="other" placeholder="Other" onChange={handleInputChange} validations={[required]} value={tutorial.other} />
                      </div>


                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <label className="col-md-8">{message}</label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>


                      <div className="col-md-8 form-group">

                        {submitted ? (<button onClick={newData}
                          type="button" className="button-form"> Add</button>) : (<button onClick={save}
                            type="button" className="button-form"> Save</button>)}



                        <button onClick={() => setLgShow(false)}
                          type="button" className="button-form"> Close</button>
                      </div>

                    </div>
                  </div>
                </form>


              </div>

            </center>





          </div>


        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit Access

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>

              <div className="details-sm">

                <form className="form form-horizontal ">
                  <div className="form-body">
                    <div className="row">

                      <div className="col-md-4">
                        <label>User</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="user" className="form-control" name="user" placeholder="User" onChange={handleInputChangeEdit} validations={[required]} value={currentData.user} />
                      </div>
                      <div className="col-md-4">
                        <label>Phone</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="phone" className="form-control" name="phone" placeholder="Phone" onChange={handleInputChangeEdit} validations={[required]} value={currentData.phone} />
                      </div>
                      <div className="col-md-4">
                        <label>OTP</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="accessCode" className="form-control" name="accessCode" placeholder="OTP" onChange={handleInputChangeEdit} validations={[required]} value={currentData.accessCode} />
                      </div>
                      <div className="col-md-4">
                        <label>TYPE</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="type" className="form-control" name="type" placeholder="Type" onChange={handleInputChangeEdit} validations={[required]} value={currentData.type} />
                      </div>
                      <div className="col-md-4">
                        <label>OTHER</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="other" className="form-control" name="other" placeholder="Other" onChange={handleInputChangeEdit} validations={[required]} value={currentData.other} />
                      </div>


                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <label className="col-md-8">{message}</label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <div className="col-md-8 form-group">
                        {/* <button onClick={updateData}
                          type="button" disabled className="button-form"> Update</button> */}


                        <button onClick={updateDataReset}
                          type="button" className="button-form"> Reset</button>

                        <button onClick={() => setLgShow2(false)}
                          type="button" className="button-form"> Close</button>
                      </div>

                    </div>
                  </div>
                </form>


              </div>

            </center>





          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};
