import React, { useState, useEffect, useRef } from "react"
import * as FaIcons from 'react-icons/fa';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";
import { FcServices } from 'react-icons/fc';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
import ProductsService from "../../services/products.service";
import MemberService from "../../services/members.service";
import SelectOptionService from "../../services/select-options.service"
import { useNavigate } from 'react-router-dom';

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const currentUser = AuthService.getCurrentUser();

export default function ProductNew() {
    let navigate = useNavigate();
    useEffect(() => {
        if (JSON.parse(localStorage.getItem("user")) == null) {
            navigate('/login', { replace: true });
            AuthService.reloadWindow();
        } else {
            let decodedToken = jwt_decode(currentUser.accessToken);
            let currentDate = new Date();
            // JWT exp is in seconds redirect to login
            if (decodedToken.exp * 1000 < currentDate.getTime()) {

                AuthService.logout();
                navigate('/login', { replace: true });
                AuthService.reloadWindow();
            }
        }
    }, []);

    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/

    const initialDataState = {
        id: null,
        code: "",
        name: "",
        description: "",
        min_price: "",
        max_price: "",
        suply_price: "",
        color: "",
        size: "",
        remark: "",
        username: "",
        golden: "",
        classic: "",
        business: "",
        economic: "",
        kid: "",
        shoes: "",
        watch: "",
        women: "",
        barcode: "",
        published: false
    };

    const [tutorial, setData] = useState(initialDataState);
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");
    const [memberdetails, setMemberDetails] = useState([]);
    const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
    const [optionCategoryArray, setOptionCategoryArray] = useState([]);
    const [optionCategoryArrayProduct, setOptionCategoryArrayProduct] = useState([]);
    const [optionCategoryArrayDescription, setOptionCategoryArrayDescription] = useState([]);

    const handleChangeoptionCategories = (event) => {
        setOptionCategorySelectedValue(event.target.value);

    }


    const handleInputChange = event => {
        const { name, value } = event.target;
        setData({ ...tutorial, [name]: value });
    };


    const saveData = () => {
        setPage(1);
        if (tutorial.code === null || tutorial.code === "") {
            setMessage("Product code can't be empty");
        } else if (tutorial.name === null || tutorial.name === "") {
            setMessage("Product name can't be empty");
        }
        else if (tutorial.min_price === null || tutorial.min_price === "") {
            setMessage("Minimum price can't be empty");
        } else if (tutorial.max_price === null || tutorial.max_price === "") {
            setMessage("Maximum can't be empty");
        } else if (tutorial.color === null || tutorial.color === "") {
            setMessage("Color can't be empty");
        } else if (tutorial.size === null || tutorial.size === "") {
            setMessage("Size can't be empty");
        }
        else {
            var data = {
                code: tutorial.code,
                name: tutorial.name,
                description: tutorial.description,
                min_price: tutorial.min_price,
                max_price: tutorial.max_price,
                suply_price: tutorial.suply_price,
                color: tutorial.color,
                size: tutorial.size,
                remark: tutorial.remark,
                goldenStand: tutorial.golden,
                classicStand: tutorial.classic,
                businesStand: tutorial.business,
                economicStand: tutorial.economic,
                kidStand: tutorial.kid,
                shoesStand: tutorial.shoes,
                watchStand: tutorial.watch,
                womenStand: tutorial.women,
                barcode: tutorial.barcode,
                username: currentUser.username
            };

            ProductsService.create(data)
                .then(response => {
                    setMessage(response.data);
                    // setMessage("Saved successfully.");
                })
                .catch(e => {
                    console.log(e);
                });
        }
    };


    const [tutorials, setDatas] = useState([]);
    const tutorialsRef = useRef();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    tutorialsRef.current = tutorials;

    const retrieveData = () => {
        setPage(1);
        getMemberData();
        getSelectOption();
    };

    const getMemberData = () => {
        setPage(1);
        MemberService.getMemberdetails(currentUser.username)
            .then((response) => {
                setMemberDetails(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const getSelectOption = () => {
        setPage(1);
        var category = "PRODUCT-STAND-PRESENCE";
        SelectOptionService.getAllSelectedOptions(category)
            .then((response) => {
                setOptionCategoryArray(response.data);
                //  console.log(response.data);
                // setOptionCategoryArrayTransfer(response.data);
            })
            .catch((e) => {
                console.log(e);
            });

        var category1 = "PRODUCT-NAME";
        SelectOptionService.getAllSelectedOptions(category1)
            .then((response) => {
                setOptionCategoryArrayProduct(response.data);
                // setOptionCategoryArrayTransfer(response.data);
            })
            .catch((e) => {
                console.log(e);
            });

        var category2 = "DESCRIPTION-TYPE";
        SelectOptionService.getAllSelectedOptions(category2)
            .then((response) => {
                setOptionCategoryArrayDescription(response.data);
                // setOptionCategoryArrayTransfer(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(retrieveData, [page, pageSize]);
    const list = () => {
        // props.history.push("/products/");
        navigate('/products', { replace: true });
    };

    const add = () => {
        // props.history.push("/products-new/");
        navigate('/products-new', { replace: true });
    };

    return (
        <div className='pages'>
            <div className="row">
                {/* cards  */}

                <div className="col-md-3 tool-box">
                    <table className="table-tools">
                        <div className="table-account">
                            <tbody>
                                <tr>
                                    <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><div className="table-name">{memberdetails.firstname}</div></td>
                                    <td><div className="table-name">{memberdetails.lastname}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="table-name">{memberdetails.account}</div></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan="2"></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </div>
                    </table>
                </div>
                <div className="col-md-3 tool-box">
                    <table>
                        <tbody>
                            <tr>
                                <td><span className="page-title-img"><FaIcons.FaProductHunt /> </span></td>
                                <td><span className="page-title"> Products</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="col-md-3 tool-box">
                    <table className="table-tools">
                        <tbody>
                            <tr>
                                <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                                <td className="tg-0lax"></td>
                                <td className="tg-0lax"></td>
                            </tr>
                            <tr>
                                <td className="tg-0lax"><button onClick={list} className="button-tools">Refresh</button></td>
                                <td className="tg-0lax"><button onClick={add} className="button-tools">New</button></td>
                            </tr>
                            <tr>
                                <td className="tg-0lax"></td>
                                <td className="tg-0lax"> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* end card box */}
                <div className="dataRow">
                    <center>
                        <div className="details-small">
                            <center>
                                <form className="form form-horizontal ">
                                    <div className="form-body">
                                        <div className="row">

                                            <div className="col-md-4">
                                                <label>CODE</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="code" className="form-control" name="code" placeholder="model number" onChange={handleInputChange} validations={[required]} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Name</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <select className="form-control" id="name" name="name" onChange={handleInputChange} >
                                                    <option>Select</option>
                                                    {optionCategoryArrayProduct.map((optionCategory) => (
                                                        <option key={optionCategory.value}>{optionCategory.value}</option>
                                                    ))}

                                                </select>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Description</label>
                                            </div>

                                            <div className="col-md-8 form-group">
                                                <select className="form-control" id="description" name="description" onChange={handleInputChange} >
                                                    <option key={tutorial.description}>Select</option>
                                                    {optionCategoryArrayDescription.map((optionCategory) => (
                                                        <option key={optionCategory.value}>{optionCategory.value}</option>
                                                    ))}

                                                </select>
                                            </div>

                                            <div className="col-md-4">
                                                <label>Min price</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="number" id="min-price" className="form-control" name="min_price" placeholder="Minimum price" onChange={handleInputChange} validations={[required]} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>Max price</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="number" id="max_price" className="form-control" name="max_price" placeholder="Maximum price" onChange={handleInputChange} validations={[required]} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>Suply price</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="number" id="suply_price" className="form-control" name="suply_price" placeholder="Suply price" onChange={handleInputChange} validations={[required]} />
                                            </div>


                                            <div className="col-md-4">
                                                <label>color</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="color" className="form-control" name="color" placeholder="Color" onChange={handleInputChange} validations={[required]} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>size</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="size" className="form-control" name="size" placeholder="Size" onChange={handleInputChange} validations={[required]} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>Remark</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="remark" className="form-control" name="remark" placeholder="Remark" onChange={handleInputChange} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Barcode</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="barcode" className="form-control" name="barcode" placeholder="Barcode" onChange={handleInputChange} />
                                            </div>
                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>

                                            <label className="col-md-8 danger-message">{message}</label>

                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>

                                            <div className="col-md-8 form-group">
                                                {submitted ? (<button onClick={add}
                                                    className="btn button-form"><ImPlus /> Add</button>) : (<button onClick={saveData}
                                                        type="button" className="button-form"><ImFloppyDisk /> Save</button>)}
                                                <button onClick={list}
                                                    type="button" className="button-form"><ImCancelCircle /> Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </center>
                        </div>
                    </center>
                </div>
            </div>
        </div>
    );
};
