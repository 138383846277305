import http from "./http-common";
import authHeader from "./auth-header";

const getAll = (params) => {
  return http.get("/transfer", { params,headers: authHeader() });
};

const get = (id) => {
  return http.get(`/transfer/${id}`,{ headers: authHeader() });
};

const create = (data) => {
   
  return http.post("/transfer", data,{ headers: authHeader() });
};

const update = (id, data) => {
  return http.put(`/transfer/${id}`, data,{ headers: authHeader() });
};

const updateDataDisbursment = (id, data) => {
  return http.put(`/disbursement/${id}`, data,{ headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/transfer/${id}`,{ headers: authHeader()});
};

const removeAll = () => {
  return http.delete(`/customers`);
};

/* const findByTitle = (title) => {
  return http.get(`/tutorials?title=${title}`);
}; */

const findByTitle = (title) => {
  return http.get(`/stock?code=${title}`);
};

const getTranferByStatus = (data) => {    
  return http.post("/transferbystatus", data,{ headers: authHeader() });
};

const getTranferByCountry = (data) => {    
  return http.post("/transferbycountry", data,{ headers: authHeader() });
};

const TransferService = {
  getAll,
  get,
  create,
  update,
  updateDataDisbursment,
  remove,
  removeAll,
  findByTitle,
  getTranferByStatus,
  getTranferByCountry
};

export default TransferService;
