import React, { useState, useRef, useEffect } from "react";
import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { Modal } from 'react-bootstrap';
import { IoIosRemoveCircleOutline } from "react-icons/io";
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import MemberService from "../../services/members.service";
import SelectOptionService from "../../services/select-options.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { useNavigate } from "react-router-dom";
import logo from '../images/ananda.jpg';
import OrganizationDetailsService from "../../services/organization-details.service";



var pass1;

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};


const currentUser = AuthService.getCurrentUser();

export default function UsersLIst() {
  let navigate = useNavigate();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user")) == null) {
      //redirectToLogin();
      navigate('/login', { replace: true });

    } else {

      let decodedToken = jwt_decode(currentUser.accessToken);
      let currentDate = new Date();
      // JWT exp is in seconds redirect to login
      if (decodedToken.exp * 1000 < currentDate.getTime()) {

        AuthService.logout();
        navigate('/login', { replace: true });
      }
    }
  }, []);

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    email: "",
    firstname: "",
    lastname: "",
    account: "",
    phone: "",
    joiningdate: "",
    roleName: "",
    user_group: "",
    password_expiration_date: "",
    report_duration: "",
    disabled: "",
    otp_enabled: "",

  };


  const form = useRef();
  const checkBtn = useRef();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [account, setAccount] = useState("");
  const [user_group, setUser_group] = useState("GUEST");
  const [password_expiration_date, setPassword_expiration_date] = useState("");
  const [report_duration, setReport_duration] = useState("0");
  const [disabled, setDisabled] = useState("YES");
  const [otp_enabled, setOtp_enabled] = useState("YES");

  const [successful, setSuccessful] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [organizationDetails, setorganizationDetails] = useState([]);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    pass1 = e.target.value;
    setPassword(password);
  };

  const onChangePassword2 = (e) => {
    const password2 = e.target.value;
    setPassword2(password2);
  };


  const onChangeFirstname = (e) => {
    const firstname = e.target.value;
    setFirstname(firstname);
  };

  const onChangeLastname = (e) => {
    const lastname = e.target.value;
    setLastname(lastname);
  };

  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);
  };

  const onChangeAccount = (e) => {
    const account = e.target.value;
    setAccount(account);
  };

  const onChangeUser_group = (e) => {
    const user_group = e.target.value;
    setUser_group(user_group);
  };

  const onChangePassword_expiration_date = (e) => {
    const password_expiration_date = e.target.value;
    setPassword_expiration_date(password_expiration_date);
  };

  const onChangeReport_duration = (e) => {
    const report_duration = e.target.value;
    setReport_duration(report_duration);
  };

  const onChangeDisabled = (e) => {
    const disabled = e.target.value;
    setDisabled(disabled);
  };

  const onChangeOtp_enabled = (e) => {
    const otp_enabled = e.target.value;
    setOtp_enabled(otp_enabled);
  };


  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {


      AuthService.register(username, email, password, firstname, lastname, phone, account, user_group, password_expiration_date, report_duration, disabled, otp_enabled).then(
        (response) => {
          setMessage(response.data.message);
          setSuccessful(true);
          setSubmitted(true);

        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  const vpassword2 = (value) => {
    if (pass1 != value) {
      return (
        <div className="alert alert-danger" role="alert">
          Passwords not matching!!!
        </div>
      );
    }
  };




  const [tutorial, setData] = useState(initialDataState);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [memberdetails, setMemberDetails] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [optionCategoryArrayDisabled, setOptionCategoryArrayDisabled] = useState([]);
  const [optionCategoryArrayOtp_enabled, setOptionCategoryArrayOtp_enabled] = useState([]);
  const [optionCategoryArrayUser_group, setOptionCategoryArrayUser_group] = useState([]);

  const [message, setMessage] = useState("");
  const [messageNewPassword, setMessageNewPassword] = useState("");
  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const pageSizes = [300, 500, 1000];
  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });

  };

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };




  const retrieveData = () => {
    setPage(1);
    getMemberData();

    const params = getRequestParams(searchTitle, page, pageSize);

    MemberService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;
        setDatas(tutorials);
        setCount(totalPages);

      })
      .catch((e) => {
        console.log(e);
      });

    getSelectOption();

    getOrganizationDetails();
  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {
        setMemberDetails(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }


  const getOrganizationDetails = () => {
    setPage(1);

    OrganizationDetailsService.get(1)
      .then((response) => {

        setorganizationDetails(response.data);
        console.log(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }

  const getSelectOption = () => {
    setPage(1);

    MemberService.getUserRoles()
      .then((response) => {
        setOptionCategoryArray(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    var category1 = "OTP-OPTION";
    SelectOptionService.getAllSelectedOptions(category1)
      .then((response) => {

        setOptionCategoryArrayOtp_enabled(response.data);
        // setOptionCategoryArrayTransfer(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    var category2 = "DISABLED";
    SelectOptionService.getAllSelectedOptions(category2)
      .then((response) => {
        setOptionCategoryArrayDisabled(response.data);
        // setOptionCategoryArrayTransfer(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    var category2 = "USER_GROUP";
    SelectOptionService.getAllSelectedOptions(category2)
      .then((response) => {
        setOptionCategoryArrayUser_group(response.data);
        // setOptionCategoryArrayTransfer(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };


  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };

  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {
    setMessage("");
    setLgShow(true);
    setPage(1);
    setcurrentData(initialDataState);

    MemberService.get(id)
      .then(response => {
        setcurrentData(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const removeReole = (id) => {
    setMessage("");
    setPage(1);
    setcurrentData(initialDataState);

    var data = {
      text1: currentData.id,
      text2: id,
    }

    MemberService.removeRole(data)
      .then(response => {
        setMessage(response.data);

      })
      .catch(e => {
        console.log(e);
      });
    refreshList();

  };


  const openObject2 = (id) => {
    setMessage("");
    setPage(1);
    setLgShow2(true);

    MemberService.get(id)
      .then(response => {
        setcurrentData(response.data);
        getUserroles(response.data.email);

      })
      .catch(e => {
        console.log(e);
      });
  };

  const getUserroles = (username) => {
    MemberService.getMemberdetailsRoles(username)
      .then(response => {
        setUserRoles(response.data.roles);
      })
      .catch(e => {
        console.log(e);
      });
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);
  const [lgShow3, setLgShow3] = useState(false);

  const registration = () => {
    // props.history.push("/registration/");
    navigate('/registration', { replace: true });
  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const updateData = () => {
    setPage(1);
    setMessage("");

    MemberService.update(currentData.id, currentData)
      .then(response => {
        setMessage("The Data was updated successfully!");
      })
      .catch(e => {
        console.log(e);
      });
    refreshList();
  };

  const save = () => {
    setMessage("");

    if (tutorial.roleName === null || tutorial.roleName === "") {
      setMessage("Select valid role.");
    } else {

      var data = {
        text1: currentData.id,
        text2: tutorial.roleName,
      }

      MemberService.createRole(data)
        .then(response => {
          setMessage(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  const changePassword = (username) => {
    setMessage("");

    var data = {
      text4: username,
    }

    MemberService.changePasswordAdmin(data)
      .then((response) => {
        setMessageNewPassword(response.data);

      })
      .catch(e => {
        console.log(e);
      });

  }

  const closeWindow = (e) => {
    e.preventDefault();
    setLgShow3(false);

  }

  return (
    <>
      <div className='pages'>
        <div className="row">
          {/* cards  */}
          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <div className="table-account">
                <tbody>
                  <tr>
                    <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td><div className="table-name">{memberdetails.firstname}</div></td>
                    <td><div className="table-name">{memberdetails.lastname}</div></td>
                  </tr>
                  <tr>
                    <td>Branch:</td>
                    <td><div className="table-name">{memberdetails.account}</div></td>
                  </tr>
                </tbody>
              </div>
            </table>
          </div>

          <div className="col-md-3 tool-box">
            <table className=" table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  {/* <td className="tg-0lax"><button onClick={registration} className="button-tools">New</button></td> */}
                  <td className="tg-0lax"><button onClick={() => setLgShow3(true)} className="button-tools">New</button></td>
                </tr>
                <tr>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* end card box */}

          <div className="dataRow">
            <div className="row pagination-box">
              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>
              <div className="col-md-3 table-title">  USERS </div>
              <div className="col-md-4"><Pagination
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
              </div>
            </div>
            <div className="details">
              <div className="table-wrap">
                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ID</th>
                      <th>FIRSTNAME</th>
                      <th>LASTNAME</th>
                      <th>PHONE</th>
                      <th>LOCATION</th>
                      <th>EAMIL</th>
                      <th>GROUP</th>
                      <th>EXPIRATION</th>
                      <th>REPORT DURATION</th>
                      <th>DISABLED</th>
                      <th>OTP ENABLED</th>
                      <th>TOOLS</th>

                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.firstname}</td>
                          <td>{tutorial.lastname}</td>
                          <td>{tutorial.phone}</td>
                          <td>{tutorial.account}</td>
                          <td>{tutorial.email}</td>
                          <td>{tutorial.user_group}</td>
                          <td>{tutorial.password_expiration_date}</td>
                          <td>{tutorial.report_duration}</td>
                          <td>{tutorial.disabled}</td>
                          <td>{tutorial.otp_enabled}</td>
                          <td><div className="table-icons-wrapper">

                            <button
                              type="button" className="table-buttons-l"
                              onClick={() => openObject(tutorial.id)}
                            > Edit
                            </button>


                            <button
                              type="button" className="table-buttons-l"
                              onClick={() => openObject2(tutorial.id)}
                            >  Roles
                            </button>

                          </div></td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Editing */}

      <Modal
        size="md"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >

        <Modal.Body className="modal-bg">

        <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Expenses  </div></center>

          <div className="dataRow-with-one-column-center">

            {/* col 1 */}
            <div className="details">

              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">

                    <div className="row">
                      <div className="col-md-4"> <hr /> </div>
                      <div className="col-md-4"><center> <span className="modal-title"> Register user </span> </center></div>
                      <div className="col-md-4"><hr /></div>
                    </div>


                    <div className="row">
                      <div className="col-md-4">
                        <label>FIrst Name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="firstname" className="form-control" name="firstname" placeholder="Firstname" onChange={handleInputChangeEdit} validations={[required]} value={currentData.firstname} />
                      </div>
                      <div className="col-md-4">
                        <label>Lastname</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="lastname" className="form-control" name="lastname" placeholder="Lastname" onChange={handleInputChangeEdit} validations={[required]} value={currentData.lastname} />
                      </div>
                      <div className="col-md-4">
                        <label>Phone</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="phone" className="form-control" name="phone" placeholder="Phone" onChange={handleInputChangeEdit} validations={[required]} value={currentData.phone} />
                      </div>
                      <div className="col-md-4">
                        <label>Account</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="account" className="form-control" name="account" placeholder="Account" onChange={handleInputChangeEdit} validations={[required]} value={currentData.account} />
                      </div>
                      <div className="col-md-4">
                        <label>Email</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="email" id="email" className="form-control" readOnly name="email" placeholder="Email" onChange={handleInputChangeEdit} validations={[required]} value={currentData.email} />
                      </div>

                      <div className="col-md-4">
                        <label>Group</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <select className="form-control" id="user_group" name="user_group" onChange={handleInputChangeEdit} >
                          <option>{currentData.user_group}</option>
                          {optionCategoryArrayUser_group.map((optionCategory) => (
                            <option key={optionCategoryArrayUser_group.value}>{optionCategory.value}</option>
                          ))}

                        </select>
                      </div>

                      <div className="col-md-4">
                        <label>Expiration</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="password_expiration_date" className="form-control" name="password_expiration_date" placeholder="Expiration" onChange={handleInputChangeEdit} validations={[required]} value={currentData.password_expiration_date} />
                      </div>

                      <div className="col-md-4">
                        <label>Report Duration</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="report_duration" className="form-control" name="report_duration" placeholder="Report Duration" onChange={handleInputChangeEdit} validations={[required]} value={currentData.report_duration} />
                      </div>

                      <div className="col-md-4">
                        <label>Disabled</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <select className="form-control" id="disabled" name="disabled" onChange={handleInputChangeEdit} >
                          <option>{currentData.disabled}</option>
                          {optionCategoryArrayDisabled.map((optionCategory) => (
                            <option key={optionCategoryArrayDisabled.value}>{optionCategory.value}</option>
                          ))}

                        </select>
                      </div>

                      <div className="col-md-4">
                        <label>OTP Enabled</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <select className="form-control" id="otp_enabled" name="otp_enabled" onChange={handleInputChangeEdit} >
                          <option>{currentData.otp_enabled}</option>
                          {optionCategoryArrayOtp_enabled.map((optionCategory) => (
                            <option key={optionCategoryArrayOtp_enabled.value}>{optionCategory.value}</option>
                          ))}

                        </select>
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <label className="col-md-8">{message}</label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <div className="col-md-8 form-group">
                        <button onClick={updateData}
                          type="button" className="button-form"> Update</button>

                        <button onClick={() => setLgShow(false)}
                          type="button" className="button-form"> Close</button>
                      </div>

                    </div>
                  </div>
                </form>
              </center>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
                <center> <div>Ananda</div> </center>
            </Modal.Footer>
      </Modal>

      

      <Modal
        size="lg"
        backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >
        
        <Modal.Body className="modal-bg">

        <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Expenses  </div></center>

          <div className="dataRow-with-one-column-center">
            <center>
              
                <form className="form form-horizontal ">
                  <div className="form-body">
                 
                    <div className="row">
                      <div className="col-md-4"> <hr /> </div>
                      <div className="col-md-4"><center> <span className="modal-title">  User Details </span> </center></div>
                      <div className="col-md-4"><hr /></div>
                    </div>
             

                    <div className="row">
                      <div className="col-md-4">
                        <label>FIrst Name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="firstname" className="form-control" name="firstname" placeholder="Firstname" onChange={handleInputChangeEdit} validations={[required]} value={currentData.firstname + " " + currentData.lastname} />
                      </div>
                      <div className="col-md-4">
                        <label>Account</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="account" className="form-control" name="account" placeholder="Account" onChange={handleInputChangeEdit} validations={[required]} value={currentData.account} />
                      </div>
                      <div className="col-md-4">
                        <label>Email</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="email" id="email" className="form-control" readOnly name="email" placeholder="Email" onChange={handleInputChangeEdit} validations={[required]} value={currentData.email} />
                      </div>
                      <div className="col-md-4">
                        <label>Role</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <select className="form-control" id="roleName" name="roleName" onChange={handleInputChange} >
                          <option>Select</option>
                          {optionCategoryArray.map((optionCategory) => (
                            <option key={optionCategoryArray}>{optionCategory}</option>
                          ))}

                        </select>
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <label className="col-md-8">{message}</label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <div className="col-md-8 form-group">
                        <button onClick={save}
                          type="button" className="button-form"> Add</button>

                        <button onClick={() => setLgShow2(false)}
                          type="button" className="button-form"> Close</button>
                      </div>

                    </div>
                  </div>
                </form>
<br/>
                <div className="row">
                      <div className="col-md-4"> <hr /> </div>
                      <div className="col-md-4"><center> <span className="modal-title">  User Roles </span> </center></div>
                      <div className="col-md-4"><hr /></div>
                    </div>
                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ROLE ID</th>
                      <th>ROLES</th>
                      <th>REMOVE</th>

                    </tr>
                  </thead>

                  {userRoles &&
                    userRoles.map((tutorial, index) => (
                      <tbody>
                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.name}</td>
                          <td><div className="table-icons-wrapper">

                            <button
                              type="button" className="table-buttons-l"
                              onClick={() => removeReole(tutorial.id)}
                            > <IoIosRemoveCircleOutline />Remove
                            </button>
                          </div></td>
                        </tr>
                      </tbody>
                    ))}
                </table>
             
              <hr />
              <button className="newpassword-btn" onClick={() => changePassword(currentData.email)}> reset password </button> <span className="newpassword"> {messageNewPassword} </span>
              <button onClick={() => setLgShow2(false)}
                          type="button" className="button-form"> Close</button>
            </center>
          </div>
        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
                <center> <div>Ananda</div> </center>
            </Modal.Footer>
      </Modal>

      {/* New users */}
      <Modal
        size="md"
        backdrop="static"
        show={lgShow3}
        onHide={() => setLgShow3(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >

        <Modal.Body className="modal-bg">

        <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Expenses  </div></center>

          <div className="dataRow-with-one-column-center">

            {/* col 1 */}
            <div className="details">

              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">

                    <div className="row">
                      <div className="col-md-4"> <hr /> </div>
                      <div className="col-md-4"><center> <span className="modal-title"> Register user </span> </center></div>
                      <div className="col-md-4"><hr /></div>
                    </div>


                    <Form className="form" onSubmit={handleRegister} ref={form}>

                      <div className="form-body">
                        <div className="row">

                          <div className="col-md-3 form-label-text">
                            <label>First name</label>

                          </div>
                          <div className="col-md-8">
                            <Input
                              type="text"
                              placeholder="First name"
                              className="form-control form-input-text"
                              name="firstname"
                              value={firstname}
                              onChange={onChangeFirstname}
                              validations={[required]}
                            />

                          </div>
                          <div className="col-md-3 form-label-text">
                            <label>Last name</label>
                          </div>
                          <div className="col-md-8">
                            <Input
                              type="text"
                              placeholder="Last names"
                              className="form-control form-input-text"
                              name="lastname"
                              value={lastname}
                              onChange={onChangeLastname}
                              validations={[required]}
                            />
                          </div>

                          <div className="col-md-3 form-label-text">
                            <label>Username</label>
                          </div>
                          <div className="col-md-8">
                            <Input
                              type="text"
                              placeholder="Username (Must be email)"
                              className="form-control form-input-text"
                              name="username"
                              value={username}
                              onChange={onChangeUsername}
                              validations={[required, validEmail]}
                            />
                          </div>
                          <div className="col-md-3 form-label-text">
                            <label>Phone</label>
                          </div>
                          <div className="col-md-8">
                            <Input
                              type="text"
                              placeholder="Phone"
                              className="form-control form-input-text"
                              name="phone"
                              value={phone}
                              onChange={onChangePhone}
                              validations={[required]}
                            />
                          </div>
                          <div className="col-md-3 form-label-text">
                            <label>Location</label>
                          </div>
                          <div className="col-md-8">
                            <Input
                              type="text"
                              placeholder="Account"
                              className="form-control form-input-text"
                              name="account"
                              value={account}
                              onChange={onChangeAccount}
                              validations={[required]}
                            />
                          </div>

                          <div className="col-md-3 form-label-text">
                            <label>Password</label>
                          </div>
                          <div className="col-md-8">
                            <Input
                              type="password"
                              placeholder="Password"
                              className="form-control form-input-text"
                              name="password"
                              value={password}
                              onChange={onChangePassword}
                              validations={[required, vpassword]}
                            />
                          </div>

                          <div className="col-md-3 form-label-text">
                            <label>Confirm passord</label>
                          </div>
                          <div className="col-md-8">
                            <Input
                              type="password"
                              placeholder="Confirm password"
                              className="form-control form-input-text"
                              name="password2"
                              value={password2}
                              onChange={onChangePassword2}
                              validations={[required, vpassword2]}
                            />
                          </div>

                          <div className="col-md-3 form-label-text">
                            <label>Expiration</label>
                          </div>

                          <div className="col-md-8">
                            <Input
                              type="text"
                              placeholder="Expiration"
                              className="form-control form-input-text"
                              name="password_expiration_date"
                              value={password_expiration_date}
                              onChange={onChangePassword_expiration_date}
                              validations={[required]}
                            />
                          </div>

                          <div className="col-md-3 form-label-text">
                            <label>Group</label>
                          </div>

                          <div className="col-md-8">
                            <Input
                              type="text"
                              placeholder="Group"
                              className="form-control form-input-text"
                              name="user_group"
                              disabled
                              value={user_group}
                              onChange={onChangeUser_group}
                              validations={[required]}
                            />
                          </div>

                          <div className="col-md-3 form-label-text">
                            <label>Report duration</label>
                          </div>

                          <div className="col-md-8">
                            <Input
                              type="text"
                              placeholder="Report duration"
                              className="form-control form-input-text"
                              name="report_duration"
                              disabled
                              value={report_duration}
                              onChange={onChangeReport_duration}
                              validations={[required]}
                            />
                          </div>

                          <div className="col-md-3 form-label-text">
                            <label>Disabled</label>
                          </div>

                          <div className="col-md-8">
                            <Input
                              type="text"
                              placeholder="Disabled"
                              className="form-control form-input-text"
                              name="disabled"
                              disabled="true"
                              value={disabled}
                              onChange={onChangeDisabled}
                              validations={[required]}
                            />
                          </div>

                          <div className="col-md-3 form-label-text">
                            <label>OTP Enabled</label>
                          </div>

                          <div className="col-md-8">
                            <Input
                              type="text"
                              placeholder="OTP Enabled"
                              className="form-control form-input-text"
                              name="otp_enabled"
                              disabled
                              value={otp_enabled}
                              onChange={onChangeOtp_enabled}
                              validations={[required]}
                            />
                          </div>
                          <div className="col-md-3 form-label-text">
                            <label> </label>
                          </div>
                          <div className="col-md-8">

                            <button   onClick={handleRegister} className="button-tools"> Register</button>

                            <button  onClick={closeWindow} className="button-tools"> Close</button>

                            {/* <button type="submit" className="button-form">Submit</button> */}
                            {message}
                          </div>
                        </div>
                      </div>


                      {message && (
                        <div className="form-group">
                          <div
                            className={
                              successful ? "alert-success" : "alert-danger"
                            }
                            role="alert"
                          >
                            {message}
                          </div>
                        </div>
                      )}
                      <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                  </div>
                </form>
              </center>
            </div>


          </div>
        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
                <center> <div>Ananda</div> </center>
            </Modal.Footer>
      </Modal>

    </>
  );
};
