import React, { useState, useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
import MemberService from "../../services/members.service";
import ReportService from "../../services/report.service";

import { useNavigate } from 'react-router-dom';

class ComponentToPrint extends React.Component {

  // constructor(props) {
  //   super(props);

  //   this.getData = this.getData.bind(this);

  //   this.state = {

  //     reportSalesData: [],
  //     cashingData: [],
  //     cashingData: [],
  //     reportPaymentData:[],
  //     expenseData:[],
  //     memberDetails:[],
  //     gTotal:"",
  //     expenses:"",
  //     currentDate: format(new Date(), 'yyyy-MM-dd HH:MM:SS'),
  //   };
  //   this.getData();
  // }

  getData() {
    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {
        this.setState({
          memberDetails: response.data
        });
      })
      .catch((e) => {
        console.log(e);
      });

    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('x');
    const startDate = queryParams.get('x1');
    const endDate = queryParams.get('x2');
    var data = {
      text1: startDate,
      text2: endDate,
      text3: this.state.memberDetails.account,
      text4: id,
    }
    ReportService.salesReport(data)
      .then((response) => {
        const { reportSalesData, reportPaymentData, cashing, expenses, gtotal, totalPages } = response.data;
        this.setState({
          reportSalesData: reportSalesData,
          reportPaymentData: reportPaymentData,
          cashingData: cashing,
          expenses: expenses,
          gTotal: gtotal,
        });
        //setCount(totalPages);
        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    return (
      <div className="printing-page">
        <center>
          <h2>YES-PHONE</h2>
          <h6></h6>
          <hr />
        </center>
        <div className="dataRow">
          <div className="details-printing">
            <table className="table2">
              <tr>
                <td>Name :{this.state.memberDetails.firstname} {this.state.memberDetails.lastname}</td>

              </tr>
              <tr>
                <td>Branch :{this.state.memberDetails.account}</td>
              </tr>
              <tr>
                <td>Date :{this.state.currentDate}</td>
              </tr>

            </table>
            <center>
              <h4>CASHIER REPORT</h4>
            </center>
            <hr />
            <table className="table table2">
              <thead className="scroll-thead">
                <tr>
                  <th>DESCRIPTION</th>
                  <th>AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>TOTAL SALES</td>
                  <td>{this.state.reportSalesData.reportSales_totalSales}</td>
                </tr>

                <tr>
                  <td>NEW SALES</td>
                  <td>{this.state.reportSalesData.reportSales_newSales}</td>
                </tr>
                <tr>
                  <td>DEPOSIT</td>
                  <td>{this.state.reportSalesData.reportSales_salesDeposit}</td>
                </tr>
                <tr>
                  <td>CREDIT</td>
                  <td>{this.state.reportSalesData.reportSales_salesCredit}</td>
                </tr>
              </tbody>
            </table>
            <center className="table-title"> Journal </center>
            <hr></hr>
            <table className="table table2">
              <thead className="scroll-thead">
                <tr>
                  <th>DESCRIPTION</th>
                  <th>AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Cash</td>
                  <td>{this.state.reportPaymentData.cash}</td>
                </tr>
                <tr>
                  <td>MoMo</td>
                  <td>{this.state.reportPaymentData.momo}</td>
                </tr>
                <tr>
                  <td>Visa</td>
                  <td>{this.state.reportPaymentData.visa}</td>
                </tr>
                <tr>
                  <td>Cheque</td>
                  <td>{this.state.reportPaymentData.cheque}</td>
                </tr>
                <tr>
                  <td>Tranfer</td>
                  <td>{this.state.reportPaymentData.transfer}</td>
                </tr>
                <tr>
                  <td>Expenses:</td>
                  <td> {this.state.expenses}</td>
                </tr>
                <tr>
                  <td>Total:</td>
                  <td> {this.state.gTotal}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <center>
            *******END*******
          </center>
        </div>
      </div>
    );
  }
}

const currentUser = AuthService.getCurrentUser();
export default function PrintReportCashier() {
  let navigate = useNavigate();
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user")) == null) {

      navigate('/login', { replace: true });
      AuthService.reloadWindow();

    } else {

      let decodedToken = jwt_decode(currentUser.accessToken);
      let currentDate = new Date();
      // JWT exp is in seconds redirect to login
      if (decodedToken.exp * 1000 < currentDate.getTime()) {

        AuthService.logout();
        navigate('/login', { replace: true });
        AuthService.reloadWindow();

      }
    }

  }, []);

  /***************************************************************************************/


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const list = () => {
    // props.history.push("/reports");
    navigate('/reports', { replace: true });
  };

  const home = () => {
    // props.history.push("/home");
    navigate('/home', { replace: true });
  };

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const pageSizes = [100, 300, 100];

  const retrieveData = () => {
    setPage(1);
  }

  useEffect(retrieveData, [page, pageSize]);
  return (
    <div className='pages'>
      <button onClick={handlePrint} className="button-form">Print</button>
      <button onClick={list} className="button-form">Report</button>
      <button onClick={home} className="button-form">Home</button>
      <ComponentToPrint ref={componentRef} />
    </div>
  );
};
