import React, { useState, useEffect, useRef } from "react";
import * as FaIcons from 'react-icons/fa';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";
import { FcServices } from 'react-icons/fc';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
import ExpensesService from "../../services/expense.service";
import MemberService from "../../services/members.service";
import { useNavigate, Link, Outlet } from 'react-router-dom'

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

export default function ExpenseView () {

    const currentUser = AuthService.getCurrentUser();

    let navigate = useNavigate();

    useEffect(() => {

        if (JSON.parse(localStorage.getItem("user")) == null) {

            //redirectToLogin();

            navigate('/login', { replace: true });

        } else {

            let decodedToken = jwt_decode(currentUser.accessToken);
            let currentDate = new Date();
            // JWT exp is in seconds redirect to login
            if (decodedToken.exp * 1000 < currentDate.getTime()) {

                AuthService.logout();
                navigate('/login', { replace: true });

            }
        }

    }, []);

    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/

    const initialDataState = {
        id: null,
        expenseType: "",
        amount: "",
        other: "",

        username: "",

    };
    const [currentData, setcurrentData] = useState(initialDataState);
    const [message, setMessage] = useState("");
    const [memberdetails, setMemberDetails] = useState([]);
    const [isManager, setIsManager] = useState(false);

    const handleInputChangeEdit = event => {
        const { name, value } = event.target;
        setcurrentData({ ...currentData, [name]: value });
    };

    const [tutorials, setDatas] = useState([]);
    const tutorialsRef = useRef();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const pageSizes = [100, 300, 500];
    tutorialsRef.current = tutorials;
    const retrieveData = () => {

        setPage(1);

        openObject();
        getMemberData();
    };

    const getMemberData = () => {
        setPage(1);

        MemberService.getMemberdetails(currentUser.username)
          .then((response) => {
    
            setMemberDetails(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
    
        if (currentUser.roles.includes("ROLE_MANAGER")) {
          setIsManager(true);
    
        }
    
      }

    useEffect(retrieveData, [page, pageSize]);

    const openObject = (rowIndex) => {
        setPage(1);

        setcurrentData(initialDataState);
        // const id = tutorialsRef.current[rowIndex].id;
        var id = 1;

        ExpensesService.get(id)
            .then(response => {
                setcurrentData(response.data);
                //  console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const updateData = () => {
        ExpensesService.update(currentData.id, currentData)
            .then(response => {
                // console.log(response.data);
                setMessage("The Data was updated successfully!");
            })
            .catch(e => {
                console.log(e);
            });
    };

    const deleteData = (rowIndex) => {
        const id = tutorialsRef.current[rowIndex].id;

        ExpensesService.remove(id)
            .then((response) => {
                /// props.history.push("/product");

                let newDatas = [...tutorialsRef.current];
                newDatas.splice(rowIndex, 1);

                setDatas(newDatas);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const list = () => {
        // props.history.push("/expense-list/");
    };

    const add = () => {
        // props.history.push("/expense-new/");
    };


    return (
        <div className='pages'>

            <div className="row">



                {/* cards  */}

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>
                        <tr>
                            <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>{memberdetails.firstname}</td>
                            <td>{memberdetails.lastname}</td>
                        </tr>
                        <tr>
                            <td>Branch:</td>
                            <td>{memberdetails.account}</td>
                        </tr>
                        </tbody>
                    </table>


                </div>
                <div className="col-md-3 tool-box">

                    <table>
                        <tbody>
                        <tr>
                            <td><span className="page-title-img"><FaIcons.FaProductHunt /> </span></td>
                            <td><span className="page-title"> Products</span></td>
                        </tr>
                        </tbody>
                    </table>

                </div>

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>
                        <tr>
                            <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                            <td className="tg-0lax"></td>
                            <td className="tg-0lax"></td>
                        </tr>
                        <tr>
                            <td className="tg-0lax"><button onClick={list} className="button-tools">Refresh</button></td>
                            {/* <td className="tg-0lax"><button onClick={add} className="button-tools">New</button></td> */}
                        </tr>
                        <tr>
                            <td className="tg-0lax"></td>
                            <td className="tg-0lax"> </td>
                        </tr>
                        </tbody>

                    </table>
                </div>


                {/* end card box */}



                <div className="dataRow">

                    <center>
                        <div className="details-small">

                            <center>
                                <form className="form form-horizontal ">
                                    <div className="form-body">
                                        <div className="row">

                                            <div className="col-md-4">
                                                <label>Expense Type</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input readOnly="readonly" type="text" id="expenseType" className="form-control" name="expenseType" placeholder="Expense type" onChange={handleInputChangeEdit} validations={[required]} value={currentData.expenseType} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Amount</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input readOnly="readonly" type="number" id="amount" className="form-control" name="amount" placeholder="Amount" onChange={handleInputChangeEdit} validations={[required]} value={currentData.amount} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Other</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input readOnly="readonly" type="text" id="other" className="form-control" name="other" placeholder="Remark" onChange={handleInputChangeEdit} validations={[required]} value={currentData.other} />
                                            </div>


                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>
                                            <label className="col-md-8">{message}</label>

                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>


                                            <div className="col-md-8 form-group">


                                                <button
                                                    type="button" onClick={list} className="button-form"><ImCancelCircle /> Close</button>

                                            </div>

                                        </div>
                                    </div>
                                </form>

                            </center>



                        </div>
                    </center>


                </div>
            </div>

        </div>
    );
};
