import React, { useState, useEffect, useRef } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';

//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
import DeliveryService from "../../services/delivery.service";
import MemberService from "../../services/members.service";

import { useNavigate, Link, Outlet } from 'react-router-dom';

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};




const currentUser = AuthService.getCurrentUser();


export default function DeliveryView () {



    const currentUser = AuthService.getCurrentUser();


    let navigate = useNavigate();
  
    useEffect(() => {
  
  
  
        if (JSON.parse(localStorage.getItem("user")) == null) {
  
            //redirectToLogin();
  
            navigate('/login', { replace: true });
  
        } else {
  
            let decodedToken = jwt_decode(currentUser.accessToken);
            let currentDate = new Date();
            // JWT exp is in seconds redirect to login
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
  
                AuthService.logout();
                navigate('/login', { replace: true });
  
            }
  
  
        }
  
        if (!currentUser.roles.includes("ROLE_DELIVERY")){
           
            navigate('/unauthorized', { replace: true });
  
        }
  
    }, []);

    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/

    const initialDataState = {
        id: null,
        orderDate: "",
        customer: "",
        orderNo: "",
        stock: "",
        item: "",
        qtyOrdered: "",
        qtyToDeliver: "",
        qtyDelivered: "",
        lastQtyDelivered: "",
        qtyRemaining: "",
        paymentStatus: "",
        deliveryStatus: "",
        locked: "",
        deliveryDate: "",
        createdBy: "",
        deliveredBy: "",
    };

    const [currentData, setcurrentData] = useState(initialDataState);
    const [message, setMessage] = useState("");
    const [memberdetails, setMemberDetails] = useState([]);
    const [isCompleted, setIsCompleted] = useState(true);



    const handleInputChangeEdit = event => {
        const { name, value } = event.target;
        setcurrentData({ ...currentData, [name]: value });
    };





    const [tutorials, setDatas] = useState([]);
    const tutorialsRef = useRef();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [isLocked, setIsLocked] = useState(true);



    tutorialsRef.current = tutorials;



    const retrieveData = () => {
        setPage(1);

        openObject();
        getMemberData();
    };

    const getMemberData = () => {
        setPage(1);

        MemberService.getMemberdetails(currentUser.username)
            .then((response) => {

                setMemberDetails(response.data);


            })
            .catch((e) => {
                console.log(e);
            });

    }

    useEffect(retrieveData, [page, pageSize]);




    const openObject = (rowIndex) => {
        setPage(1);

        setcurrentData(initialDataState);

        var id = 1;

        DeliveryService.get(id)
            .then(response => {
                setcurrentData(response.data);
                if (response.data.locked === "Authorized") {
                    setIsLocked(false);
                } else {
                    setIsLocked(true);
                }

                if (response.data.qtyRemaining < 1) {
                    setIsCompleted(false);

                } else {
                    setIsCompleted(true);
                }
            })
            .catch(e => {
                console.log(e);
            });

            setcurrentData({ ...currentData, deliveredBy: currentUser.username });


    };

    const updateData = () => {
        setPage(1);

        //validation
        if (currentData.qtyToDeliver > currentData.qtyRemaining) {

            setMessage("You can't deliver more that remaining!");

        } else if (currentData.lastQtyDelivered > currentData.qtyToDeliver) {

            setMessage("You can't deliver more than authorized qty!");

        }else if(currentData.paymentStatus!== "PAYED"){
            setMessage("This order is not payed!");
        }
        else {
            
            DeliveryService.update(currentData.id, currentData)
            .then(response => {
                // console.log(response.data);
                setMessage("The Data was updated successfully!");
            })
            .catch(e => {
                console.log(e);
            });
        }


    };

    const deleteData = (rowIndex) => {
        const id = tutorialsRef.current[rowIndex].id;

        DeliveryService.remove(id)
            .then((response) => {
                /// props.history.push("/product");

                let newDatas = [...tutorialsRef.current];
                newDatas.splice(rowIndex, 1);

                setDatas(newDatas);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const list = () => {
        // props.history.push("/products/");
        navigate('/products', { replace: true });
    };

    const add = () => {
        // props.history.push("/products-new/");
        navigate('/products-new', { replace: true });
    };

    const deliveries = () => {
        //props.history.push("/deliveries");
        navigate('/deliveries', { replace: true });
    }

    const report = () => {
        //props.history.push("/delivery-report");
        navigate('/delivery-report', { replace: true });
    }

    const reste = () => {
        //props.history.push("/delivery-reste");
        navigate('/delivery-reste', { replace: true });
    }

    const pending = () => {
        // props.history.push("/delivery-pending");
        navigate('/delivery-pending', { replace: true });
    }
    const delivered = () => {
        // props.history.push("/delivery-completed");
        navigate('/delivery-completed', { replace: true });
    }
    const close = () => {
    //    s//etSmShow(false);
        // props.history.push("/delivery-completed");
        navigate('/delivery-completed', { replace: true });
    }
    const lock = (id) => {
        setPage(1);
        DeliveryService.lockDelivery(currentData.id).then((response) => {
            if (response.data == 1) {
                window.location.reload();
                setMessage("Locked successfully.");
            }

        })
            .catch((e) => {
                console.log(e);
            });
    };

    const authorize = (id) => {
        setPage(1);
        DeliveryService.authorizeDelivery(currentData.id).then((response) => {
            if (response.data == 1) {
                window.location.reload();
                setMessage("Authorized successfully.");

            }

        })
            .catch((e) => {
                console.log(e);
            });
    };


    return (
        <div className='pages'>

            <div className="row">



                {/* cards  */}

                <div className="col-md-3 tool-box">

                    <table className="table table-tools">
                        <tr>
                            <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>{memberdetails.firstname}</td>
                            <td>{memberdetails.lastname}</td>
                        </tr>
                        <tr>
                            <td>{memberdetails.account}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                            <td></td>
                        </tr>
                    </table>


                </div>
                <div className="col-md-3 tool-box">

                    <table>
                        <tr>
                            <td><span className="page-title-img"><FaIcons.FaTruckMoving /> </span></td>
                            <td><span className="page-title"> Deliveries</span></td>
                        </tr>
                    </table>

                </div>

                <div className="col-md-3 tool-box">

                    <table className="table table-tools">
                        <tr>
                            <td class="tg-0lax" rowspan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                            <td class="tg-0lax"></td>
                            <td class="tg-0lax"></td>
                        </tr>
                        <tr>
                            <td class="tg-0lax"><button onClick={list} className="button-tools">Refresh</button></td>
                            <td class="tg-0lax"><button onClick={add} className="button-tools">New</button></td>
                        </tr>
                        <tr>
                            <td class="tg-0lax"></td>
                            <td class="tg-0lax"> </td>
                        </tr>

                    </table>
                </div>


                {/* end card box */}



                <div className="dataRow">

                    <center>
                        <div className="details-small">

                            <center>
                                <form className="form form-horizontal ">
                                    <div className="form-body">
                                        <div className="row">

                                            <div class="col-md-4">
                                                <label>Authorization</label>
                                            </div>
                                            <div class="col-md-8 form-group">
                                                <input readOnly type="text" id="locked" class="form-control" name="id" placeholder="Authorization" value={currentData.locked} />
                                            </div>
                                            <div class="col-md-4">
                                                <label>Date</label>
                                            </div>
                                            <div class="col-md-8 form-group">
                                                <input readOnly type="text" id="orderDate" class="form-control" name="orderDate" placeholder="model number" value={currentData.orderDate} />
                                            </div>
                                            <div class="col-md-4">
                                                <label>Customer</label>
                                            </div>
                                            <div class="col-md-8 form-group">
                                                <input readOnly type="customer" id="name" class="form-control" name="customer" placeholder="Product name" value={currentData.customer} />
                                            </div>
                                            <div class="col-md-4">
                                                <label>Order No</label>
                                            </div>
                                            <div class="col-md-8 form-group">
                                                <input readOnly type="text" id="orderNo" class="form-control" name="orderNo" placeholder="Description" value={currentData.orderNo} />
                                            </div>
                                            <div class="col-md-4">
                                                <label>Payment</label>
                                            </div>
                                            <div class="col-md-8 form-group">
                                                <input readOnly type="text" id="payment" class="form-control" name="payment" placeholder="Payment" value={currentData.paymentStatus} />
                                            </div>
                                            <div class="col-md-4">
                                                <label>Ordered</label>
                                            </div>
                                            <div class="col-md-8 form-group">
                                                <input readOnly type="number" id="qtyOrdered" class="form-control" name="qtyOrdered" placeholder="Minimum price" value={currentData.qtyOrdered} />
                                            </div>

                                            <div class="col-md-4">
                                                <label>Item</label>
                                            </div>
                                            <div class="col-md-8 form-group">
                                                <input readOnly type="text" id="item" class="form-control" name="item" placeholder="item" value={currentData.item} />
                                            </div>

                                            <div class="col-md-4">
                                                <label>Delivered</label>
                                            </div>
                                            <div class="col-md-8 form-group">
                                                <input readOnly type="number" id="qtyDelivered" class="form-control" name="qtyDelivered" placeholder="Maximum price" value={currentData.qtyDelivered} />
                                            </div>

                                            <div class="col-md-4">
                                                <label>Remaining</label>
                                            </div>
                                            <div class="col-md-8 form-group">
                                                <input readOnly type="text" id="qtyRemaining" class="form-control" name="qtyRemaining" placeholder="Color" value={currentData.qtyRemaining} />
                                            </div>

                                            <div class="col-md-4">
                                                <label>To deliver</label>
                                            </div>
                                            <div class="col-md-8 form-group">
                                                <input type="text" id="qtyToDeliver" class="form-control" name="qtyToDeliver" placeholder="To Deliver" onChange={handleInputChangeEdit} value={currentData.qtyToDeliver} />
                                            </div>

                                            {isCompleted ? (
                                                <>
                                                <div class="col-md-4">
                                                    <label>Last delivery</label>
                                                </div>
                                                <div class="col-md-8 form-group">
                                                    <input  type="text" id="lastQtyDelivered" class="form-control" name="lastQtyDelivered" placeholder="Current delivered" onChange={handleInputChangeEdit} validations={[required]} value={currentData.lastQtyDelivered} />
                                                </div>
                                            </>
                                            ) : (<>
                                                <div class="col-md-4">
                                                    <label>Last delivery</label>
                                                </div>
                                                <div class="col-md-8 form-group">
                                                    <input readOnly type="text" id="lastQtyDelivered" class="form-control" name="lastQtyDelivered" placeholder="Current delivered" onChange={handleInputChangeEdit} validations={[required]} />
                                                </div>
                                            </>)}



                                            <div class="col-md-4">
                                                <label>Delivery Date</label>
                                            </div>
                                            <div class="col-md-8 form-group">
                                                <input readOnly type="text" id="deliveryData" class="form-control" name="deliveryDate" placeholder="Deliery Date" value={currentData.deliveryDate} />
                                            </div>

                                            <div class="col-md-4">
                                                <label>Saller</label>
                                            </div>
                                            <div class="col-md-8 form-group">
                                                <input readOnly type="text" id="createdBy" class="form-control" name="createdBy" placeholder="Saller" value={currentData.createdBy} />
                                            </div>

                                            <div class="col-md-4">
                                                <label> Delivered By</label>
                                            </div>
                                            <div class="col-md-8 form-group">
                                                <input readOnly type="text" id="deliveredBy" class="form-control" name="deliveredBy" placeholder="Delivered By" value={currentData.deliveredBy} />
                                            </div>


                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>
                                            <label className="col-md-8">{message}</label>

                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>

                                            <div className="col-md-8 form-group">
                                                {isLocked ? ("") : (<button onClick={updateData}
                                                    type="button" className="button-form"> Save</button>)}


                                                {isLocked ? (<button onClick={authorize}
                                                    type="button" className="button-form">Authorize</button>) : (<button onClick={lock}
                                                        type="button" className="button-form">Lock</button>)}


                                                <button onClick={close}
                                                    type="button" className="button-form">Close</button>

                                            </div>



                                        </div>
                                    </div>
                                </form>

                            </center>



                        </div>
                    </center>


                </div>
            </div>

        </div>
    );
};
