import React, { useState, useRef, useEffect } from "react";
import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { Modal } from 'react-bootstrap';
import { IoIosRemoveCircleOutline } from "react-icons/io";
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import MemberService from "../../services/members.service";
import SelectOptionService from "../../services/select-options.service";
import { Link, useNavigate } from "react-router-dom";
import TransferService from "../../services/transfer.service";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { ImCancelCircle, ImPlus } from "react-icons/im";
import logo from '../images/ananda.jpg'
import TransferDetailsService from "../../services/transfer-details.service";
import { BsPersonBoundingBox } from "react-icons/bs";
import OrganizationDetailsService from "../../services/organization-details.service";


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const currentUser = AuthService.getCurrentUser();

export default function TransferList() {
  let navigate = useNavigate();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user")) == null) {
      //redirectToLogin();
      navigate('/login', { replace: true });

    } else {

      let decodedToken = jwt_decode(currentUser.accessToken);
      let currentDate = new Date();
      // JWT exp is in seconds redirect to login
      if (decodedToken.exp * 1000 < currentDate.getTime()) {

        AuthService.logout();
        navigate('/login', { replace: true });
      }
    }
  }, []);

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    senderName: "",
    senderId: "",
    sender_Phone: "",
    senderCountry: "",

    receiverCountry: "",
    receiverName: "",
    receiverPhone: "",
    amount: 0,

    destinationInstitution: "",
    local_transfer_fees: "",
    destination_transfer_fees: "",
    total_transfer_feee: "",

    total_amount: "",
    transfer_currency: "",
    exchange_rate: "",
    total_transfer: "",

    receiver_instutution_response: "",
    receiver_instutution_response_id: "",

    disburserName: "",
    disbursement_date: "",
    disbursement_currency: "",
    disbursement_amount: "",

    disbursementRecieverName: "",
    disbursementRecieverId: "",

    published: false
  };


  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [searchTitleType, setSearchTitleType] = useState("Name");
  const [memberdetails, setMemberDetails] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [optionCategoryArrayDestinationCountry, setOptionCategoryArrayDestinationCountry] = useState([]);
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArrayDestinationInstitution, setOptionCategoryArrayDestinationInstitution] = useState([]);
  const [optionCategorySelectedDestinationInstitution, setOptionCategorySelectedDestinationInstitution] = useState([]);
  const [destinationInstitionDetails, setdestinationInstitionDetails] = useState([]);
  const [organizationDetails, setorganizationDetails] = useState([]);

  const [message, setMessage] = useState("");
  const [messageNewPassword, setMessageNewPassword] = useState("");
  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const pageSizes = [300, 500, 1000];
  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
    handleSearch();
  };

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (searchTitleType) {
      params["type"] = searchTitleType;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveData = () => {

    setPage(1);

    const params = getRequestParams(searchTitle, page, pageSize);

    TransferService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        getMemberData();
      })
      .catch((e) => {
        console.log(e);
      });

    getSelectOption();
    getOrganizationDetails();
  };


  const handleSearch = () => {
    setSearchTitleType("Name");
    setPage(1);
    retrieveData();
  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {
        setMemberDetails(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const getSelectOption = () => {
    setPage(1);

    var category = "EXTERNAL_TRANSFER_COUNTRY";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArrayDestinationCountry(response.data);

      })
      .catch((e) => {
        console.log(e);
      });

  };

  const getOrganizationDetails = () => {
    setPage(1);

    OrganizationDetailsService.get(1)
      .then((response) => {

        setorganizationDetails(response.data);

      })
      .catch((e) => {
        console.log(e);
      });

  }

  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };

  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {
    setMessage("");
    setLgShow2(true);
    setPage(1);
    setcurrentData(initialDataState);

    TransferService.get(id)
      .then(response => {
        setcurrentData(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };


  const openObject2 = (id) => {
    setMessage("");
    setLgShow3(true);
    setPage(1);
    setcurrentData(initialDataState);

    TransferService.get(id)
      .then(response => {
        setcurrentData(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const openObject3 = (id) => {
    setMessage("");
    setLgShow4(true);
    setPage(1);
    setcurrentData(initialDataState);

    TransferService.get(id)
      .then(response => {
        setcurrentData(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const remove = (id) => {

    setMessage("");
    setPage(1);

    setcurrentData(initialDataState);


    TransferService.remove(id)
      .then(response => {

        refreshList();

        setMessage(response.data);


      })
      .catch(e => {
        console.log(e);
      });

  };


  // const openObject2 = (id) => {
  //   setMessage("");
  //   setPage(1);
  //   setLgShow2(true);

  //   MemberService.get(id)
  //     .then(response => {
  //       setcurrentData(response.data);
  //       getUserroles(response.data.email);

  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // };

  // const openData = (id) => {
  //   setMessage("");
  //   setLgShow4(true);
  //   setPage(1);
  //   setcurrentData(initialDataState);

  //   TransferService.get(id)
  //     .then(response => {
  //       setcurrentData(response.data);
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // };

  const getUserroles = (username) => {
    MemberService.getMemberdetailsRoles(username)
      .then(response => {
        setUserRoles(response.data.roles);
      })
      .catch(e => {
        console.log(e);
      });
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);
  const [lgShow3, setLgShow3] = useState(false);
  const [lgShow4, setLgShow4] = useState(false);

  const add = () => {
    // props.history.push("/registration/");
    navigate('/transfer-new', { replace: true });
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };


  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };


  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);

    var data = {
      text1: "EXTERNAL_TRANSFER_INSTITUTION",
      text2: event.target.value
    }

    SelectOptionService.getAllSelectedOptionsWithData(data)
      .then((response) => {


        setOptionCategoryArrayDestinationInstitution(response.data);

      })
      .catch((e) => {
        console.log(e);
      });

  }

  const handleInputChangeDestinationInstitution = (event) => {
    setOptionCategorySelectedDestinationInstitution(event.target.value);



    TransferDetailsService.getByInstitution(event.target.value)
      .then((response) => {

        setdestinationInstitionDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }

  const updateData = () => {
    setPage(1);
    setMessage("");

    TransferService.update(currentData.id, currentData)
      .then(response => {
        setMessage("Transfer updated successfully!");
        submitted(true);
      })
      .catch(e => {
        console.log(e);
      });
    refreshList();
  };

  const updateDataDisbursment = () => {
    setPage(1);
    setMessage("");

    TransferService.updateDataDisbursment(currentData.id, currentData)
      .then(response => {
        setMessage("Transfer updated successfully!");
        submitted(true);
      })
      .catch(e => {
        console.log(e);
      });
    refreshList();
  };

  const saveData = () => {

    var data = {
      senderName: tutorial.senderName,
      senderId: tutorial.senderId,
      sender_Phone: tutorial.sender_Phone,
      senderCountry: memberdetails.account,

      receiverCountry: tutorial.receiverCountry,
      receiverName: tutorial.receiverName,
      receiverPhone: tutorial.receiverPhone,
      amount: tutorial.amount,

      destinationInstitution: optionCategorySelectedDestinationInstitution,
      local_transfer_fees: destinationInstitionDetails.fees,
      destination_transfer_fees: destinationInstitionDetails.partener_charges,
      total_transfer_feee: parseFloat(destinationInstitionDetails.fees) + parseFloat(destinationInstitionDetails.partener_charges),

      total_amount: tutorial.amount - (tutorial.amount * (destinationInstitionDetails.partener_charges + destinationInstitionDetails.fees) / 100),
      transfer_currency: destinationInstitionDetails.transfer_currency,
      exchange_rate: destinationInstitionDetails.transfer_currency_rate,
      total_transfer: (tutorial.amount - (tutorial.amount * (destinationInstitionDetails.partener_charges + destinationInstitionDetails.fees) / 100)) / destinationInstitionDetails.transfer_currency_rate,

      receiver_instutution_response: tutorial.receiver_instutution_response,
      receiver_instutution_response_id: tutorial.receiver_instutution_response_id,
      disburserName: tutorial.disburserName,
      disbursement_date: tutorial.disbursement_date,
      disbursement_currency: tutorial.disbursement_currency,
      disbursement_amount: tutorial.disbursement_amount,

      disbursementRecieverName: tutorial.disbursementRecieverName,
      disbursementRecieverId: tutorial.disbursementRecieverId

    };



    TransferService.create(data)
      .then(response => {
        newData();
        setSubmitted(true);
        setMessage("Transfer done successfully.");

      })
      .catch(e => {
        console.log(e);
      });

  };


  const newData = () => {
    setData(initialDataState);
    setSubmitted(false);
    setMessage("");
    //props.history.push("/customers-new/");
    navigate('/transfer', { replace: true });

  };

  const receipt = () => {
    // navigate(`/transfer-receipt?id=${id}`, { replace: true }, { state: { query: currentData } });
    navigate('/transfer-receipt', { replace: true }, { state: { query: currentData } });

  }



  const Print = () => {
    //console.log('print');  
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }


  return (
    <>
      <div className='pages'>
        <div className="row">
          {/* cards  */}
          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <div className="table-account">
                <tbody>
                  <tr>
                    <td rowSpan="3"> <input type="text" value={searchTitle}
                      onChange={onChangeSearchTitle} placeholder="Search" className="search-input"></input> </td>
                    <td> <button onClick={handleSearch} className="button-tools"> Search</button></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td> </td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td> </td>
                  </tr>
                </tbody>
              </div>
            </table>
          </div>

          <div className="col-md-3 tool-box">
            <table className=" table-tools">
              <tbody>

                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  {/* <td className="tg-0lax"><button onClick={add} className="button-tools">New</button></td> */}
                  <td className="tg-0lax"><button onClick={() => setLgShow(true)} className="button-tools">Transfer</button></td>
                </tr>
                <tr>
                  <td className="tg-0lax"> </td>
                  <td className="tg-0lax"></td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* end card box */}

          <div className="dataRow">
            <div className="row pagination-box">
              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>
              <div className="col-md-3 table-title">  TRANSFER LIST </div>
              <div className="col-md-4"><Pagination
                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
              </div>
            </div>
            <div className="details" id='printablediv'>
              <div className="table-wrap">
                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>  </th>
                      <th>Transfer status</th>
                      <th>Disbursement status</th>
                      <th className="table-header-size-lg">  Sender Name </th>
                      <th className="table-header-size-md">Sender Country </th>
                      <th className="table-header-size-md">Receiver name</th>
                      <th className="table-header-size-md">Receiver Country</th>
                      <th className="table-header-size-md">Amount </th>
                      <th className="table-header-size-lg">Destinantion instutution</th>

                      <th className="table-header-size-lg">Sender Id</th>
                      <th className="table-header-size-md">Sender Phone</th>

                      <th className="table-header-size-md">Receiver phone</th>
                      <th className="table-header-size-md">Disburser Currency</th>
                      <th className="table-header-size-lg">Disburser Receiver Name</th>
                      <th className="table-header-size-lg">Disburser Amount</th>

                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td><div className="table-icons-wrapper">

                            <button
                              type="button" className="table-buttons-l"
                              onClick={() => openObject(tutorial.id)}
                            > Edit
                            </button>

                            <button
                              type="button" className="table-buttons-l"
                              onClick={() => openObject2(tutorial.id)}
                            > Disburser
                            </button>

                            <button
                              type="button" className="table-buttons-l"
                              onClick={() => openObject3(tutorial.id)}
                            > View
                            </button>
                          </div>
                          </td>

                          <td><span className={"status " + tutorial.transferStatus}>{tutorial.transferStatus}</span></td>
                          <td><span className={"status " + tutorial.disbursementStatus}>{tutorial.disbursementStatus}</span></td>
                          <td>{tutorial.senderName}</td>
                          <td>{tutorial.senderCountry}</td>
                          <td>{tutorial.receiverName}</td>
                          <td>{tutorial.receiverCountry}</td>
                          <td>{tutorial.amount}</td>
                          <td>{tutorial.destinationInstitution}</td>

                          <td>{tutorial.senderId}</td>
                          <td>{tutorial.sender_Phone}</td>
                          <td>{tutorial.receiverPhone}</td>
                          <td>{tutorial.disbursement_currency}</td>
                          <td>{tutorial.disbursementRecieverName}</td>
                          <td>{tutorial.disbursement_amount}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="xl"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >


        <Modal.Body className="modal-bg">

          <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Transfer  </div></center>

          <div className="dataRow-with-two-column-center">

            {/* col 1 */}
            <div className="details-small">

              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">

                    <br />
                    <div className="row">
                      <div className="col-md-5"> <hr /> </div>
                      <div className="col-md-2"><center> <span className="modal-title">SENDER</span> </center></div>
                      <div className="col-md-5"><hr /></div>
                    </div>

                    <div className="row">


                      <div className="col-md-4 form-label">
                        <label>Sender Name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="senderName" className="form-control form-text-field" name="senderName" placeholder="Fullname" onChange={handleInputChange} validations={[required]} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Sender Id</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="senderId" className="form-control" name="senderId" placeholder="senderId" onChange={handleInputChange} validations={[required]} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label> Sender Phone</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="sender_Phone" className="form-control" name="sender_Phone" placeholder="sender Phone" onChange={handleInputChange} validations={[required]} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Sender Country</label>
                      </div>

                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="senderCountry" className="form-control" name="senderCountry" placeholder="Sender country" onChange={handleInputChange} value={memberdetails.account} />
                      </div>
                      <div className="row">
                        <div className="col-md-5"> <hr /> </div>
                        <div className="col-md-2"><center> <span className="modal-title">RECEIVER</span> </center></div>
                        <div className="col-md-5"><hr /></div>
                      </div>


                      <div className="col-md-4 form-label">
                        <label>Receiver name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="receiverName" className="form-control" name="receiverName" placeholder="Receiver Name" onChange={handleInputChange} validations={[required]} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Receiver phone</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="receiverPhone" className="form-control" name="receiverPhone" placeholder=" Receiver Phone" onChange={handleInputChange} validations={[required]} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Receiver Country</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <select className="form-control select-option" id="receiverCountry" name="receiverCountry" onChange={handleChangeoptionCategories} >
                          <option>Select Country</option>
                          {optionCategoryArrayDestinationCountry.map((optionCategory) => (
                            <option key={optionCategory.value}>{optionCategory.value}</option>
                          ))}
                        </select>
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8"> </label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <div className="row">
                        <div className="col-md-3"> <hr /> </div>
                        <div className="col-md-6"><center> <span className="modal-title">TRANSFER ACKNOWLEDGEMENT</span> </center></div>
                        <div className="col-md-3"><hr /></div>
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Receiver Instutution Response</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="receiver_instutution_response" className="form-control" name="receiver_instutution_response" placeholder=" Response" onChange={handleInputChange} validations={[required]} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Receiver Instutution Response ID</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="receiver_instutution_response_id" className="form-control" name="receiver_instutution_response_id" placeholder=" Response Id" onChange={handleInputChange} validations={[required]} />
                        <br />
                      </div>

                    </div>
                  </div>
                </form>
              </center>
            </div>
            {/* col 2 */}

            <div className="details-small">
              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">

                    <div className="row">
                      <div className="transfer-institution-empty">



                      </div>
                    </div>

                    <br />
                    <div className="row">
                      <div className="col-md-4"> <hr /> </div>
                      <div className="col-md-4"><center> <span className="modal-title">TRANSFER DETAILS</span> </center></div>
                      <div className="col-md-4"><hr /></div>
                    </div>

                    <div className="row">

                      <div className="col-md-4 form-label">
                        <label>Amount</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="amount" className="form-control" name="amount" placeholder="Amount" onChange={handleInputChange} validations={[required]} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Destinantion instutution</label>
                      </div>
                      <div className="col-md-8 form-group">

                        <select className="form-control select-option" id="destinationInstitution" name="destinationInstitution" onChange={handleInputChangeDestinationInstitution} >
                          <option>Select pertner</option>
                          {optionCategoryArrayDestinationInstitution.map((optionCategory) => (
                            <option key={optionCategory.value}>{optionCategory.value}</option>
                          ))}
                        </select>
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Local Transfer fees</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="local_transfer_fees" className="form-control" name="local_transfer_fees" placeholder=" Local Transfer fees" onChange={handleInputChange} value={destinationInstitionDetails.fees} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Destination Transfer fees</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="destination_transfer_fees" className="form-control" name="destination_transfer_fees" placeholder="Destination Transfer fees" onChange={handleInputChange} value={destinationInstitionDetails.partener_charges} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Total Transfer fees</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="total_transfer_feee" className="form-control" name="total_transfer_feee" placeholder="Total Transfer fees" onChange={handleInputChange} validations={[required]} value={destinationInstitionDetails.partener_charges + destinationInstitionDetails.fees} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Fees amount</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="total_transfer_feee" className="form-control" name="total_transfer_feee" placeholder="Total Transfer fees" onChange={handleInputChange} validations={[required]} value={(tutorial.amount * (destinationInstitionDetails.partener_charges + destinationInstitionDetails.fees) / 100)} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Total Amount</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="total_amount" className="form-control" name="total_amount" placeholder="Total Amount" onChange={handleInputChange} validations={[required]} value={tutorial.amount - (tutorial.amount * (destinationInstitionDetails.partener_charges + destinationInstitionDetails.fees) / 100)} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Transfer currency</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="transfer_currency" className="form-control" name="transfer_currency" placeholder="Transfer currency" onChange={handleInputChange} validations={[required]} value={destinationInstitionDetails.transfer_currency} />

                      </div>

                      <div className="col-md-4 form-label">
                        <label>Exchange rate</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="exchange_rate" className="form-control" name="exchange_rate" placeholder="Exchange rate" onChange={handleInputChange} value={destinationInstitionDetails.transfer_currency_rate} />

                      </div>
                      <div className="col-md-4 form-label">
                        <label>Total Transfer</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="total_transfer" className="form-control" name="total_transfer" placeholder="Total transfer" onChange={handleInputChange} value={(tutorial.amount - (tutorial.amount * (destinationInstitionDetails.partener_charges + destinationInstitionDetails.fees) / 100)) / destinationInstitionDetails.transfer_currency_rate} />
                        <br />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <div className="col-md-8 form-group">

                        {submitted ? ("") : (<button onClick={saveData}
                          type="button" className="button-tools-lg"><FaMoneyBillTransfer /> Transfer</button>)}

                        <button
                          type="button" onClick={() => setLgShow(false)} className="button-tools-lg"><ImCancelCircle /> Close</button>
                        <br /> <br />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8">{message}</label>


                    </div>
                  </div>
                </form>
              </center>
            </div>


          </div>
        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
          <center> <div>Ananda</div> </center>
        </Modal.Footer>
      </Modal>



      <Modal
        size="md"
        backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >


        <Modal.Body className="modal-bg">
          <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Transfer Edit  </div></center>
          <div className="dataRow-with-one-column-center">

            {/* col 1 */}
            <div className="details-small">

              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">


                    <div className="row">
                      <div className="col-md-5"> <hr /> </div>
                      <div className="col-md-2"><center> <span className="modal-title">SENDER</span> </center></div>
                      <div className="col-md-5"><hr /></div>
                    </div>
                    <div className="row">


                      <div className="col-md-4 form-label">
                        <label>Sender Name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="senderName" className="form-control form-text-field" name="senderName" placeholder="Sender Fullname" onChange={handleInputChangeEdit} validations={[required]} value={currentData.senderName} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Sender Id</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="senderId" className="form-control" name="senderId" placeholder="senderId" onChange={handleInputChangeEdit} validations={[required]} value={currentData.senderId} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label> Sender Phone</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="sender_Phone" className="form-control" name="sender_Phone" placeholder="sender Phone" onChange={handleInputChangeEdit} validations={[required]} value={currentData.sender_Phone} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Sender Country</label>
                      </div>

                      <div className="col-md-8 form-group">
                        <input type="text" id="senderCountry" className="form-control" name="senderCountry" placeholder="Sender country" onChange={handleInputChangeEdit} validations={[required]} value={currentData.senderCountry} />
                      </div>
                      <div className="row">
                        <div className="col-md-5"> <hr /> </div>
                        <div className="col-md-2"><center> <span className="modal-title">RECEIVER</span> </center></div>
                        <div className="col-md-5"><hr /></div>
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Receiver Country</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="receiverCountry" className="form-control" name="receiverCountry" placeholder="Receiver Country" onChange={handleInputChangeEdit} validations={[required]} value={currentData.receiverCountry} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Receiver name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="receiverName" className="form-control" name="receiverName" placeholder="Receiver Name" onChange={handleInputChangeEdit} validations={[required]} value={currentData.receiverName} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Receiver phone</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="receiverPhone" className="form-control" name="receiverPhone" placeholder=" Receiver Phone" onChange={handleInputChangeEdit} validations={[required]} value={currentData.receiverPhone} />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8">{message}</label>

                      <label className="col-md-8"> </label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>


                      <div className="col-md-8 form-group">

                        {submitted ? ("") : (<button onClick={updateData}
                          type="button" className="button-tools-lg"><FaMoneyBillTransfer /> Update Transfer</button>)}

                        <button
                          type="button" onClick={() => setLgShow2(false)} className="button-tools-lg"><ImCancelCircle /> Close</button>
                        <br /> <br />
                      </div>


                    </div>
                  </div>
                </form>
              </center>
            </div>
            {/* col 2 */}
          </div>
        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
          <center> <div>Ananda</div> </center>
        </Modal.Footer>
      </Modal>


      <Modal
        size="xl"
        backdrop="static"
        show={lgShow3}
        onHide={() => setLgShow3(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >


        <Modal.Body className="modal-bg">

          <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Transfer Disbursement  </div></center>

          <div className="dataRow-with-two-column-center">

            {/* col 1 */}
            <div className="details-small">

              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">

                    <div className="row">
                      <div className="col-md-5"> <hr /> </div>
                      <div className="col-md-2"><center> <span className="modal-title">SENDER</span> </center></div>
                      <div className="col-md-5"><hr /></div>
                    </div>
                    <div className="row">


                      <div className="col-md-4 form-label">
                        <label>Sender Name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="senderName" className="form-control form-text-field" name="senderName" placeholder="Sender Fullname" onChange={handleInputChangeEdit} validations={[required]} value={currentData.senderName} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Sender Id</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="senderId" className="form-control" name="senderId" placeholder="senderId" onChange={handleInputChangeEdit} validations={[required]} value={currentData.senderId} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label> Sender Phone</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="sender_Phone" className="form-control" name="sender_Phone" placeholder="sender Phone" onChange={handleInputChangeEdit} validations={[required]} value={currentData.sender_Phone} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Sender Country</label>
                      </div>

                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="senderCountry" className="form-control" name="senderCountry" placeholder="Sender country" onChange={handleInputChangeEdit} validations={[required]} value={currentData.senderCountry} />
                      </div>
                      <div className="row">
                        <div className="col-md-5"> <hr /> </div>
                        <div className="col-md-2"><center> <span className="modal-title">RECEIVER</span> </center></div>
                        <div className="col-md-5"><hr /></div>
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Receiver Country</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="receiverCountry" className="form-control" name="receiverCountry" placeholder="Receiver Country" onChange={handleInputChangeEdit} validations={[required]} value={currentData.receiverCountry} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Receiver name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="receiverName" className="form-control" name="receiverName" placeholder="Receiver Name" onChange={handleInputChangeEdit} validations={[required]} value={currentData.receiverName} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Receiver phone</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="receiverPhone" className="form-control" name="receiverPhone" placeholder=" Receiver Phone" onChange={handleInputChangeEdit} validations={[required]} value={currentData.receiverPhone} />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8"> </label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <div className="row">
                        <div className="col-md-3"> <hr /> </div>
                        <div className="col-md-6"><center> <span className="modal-title">TRANSFER ACKNOWLEDGEMENT</span> </center></div>
                        <div className="col-md-3"><hr /></div>
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Receiver Instutution Response</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="receiver_instutution_response" className="form-control" name="receiver_instutution_response" placeholder=" Response" onChange={handleInputChangeEdit} validations={[required]} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Receiver Instutution Response ID</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="receiver_instutution_response_id" className="form-control" name="receiver_instutution_response_id" placeholder=" Response Id" onChange={handleInputChangeEdit} validations={[required]} />
                        <br />
                      </div>

                      <div className="row">
                        <div className="col-md-3"> <hr /> </div>
                        <div className="col-md-6"><center> <span className="modal-title">DISBURSEMENT</span> </center></div>
                        <div className="col-md-3"><hr /></div>
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Disburser Name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="disburserName" className="form-control" name="disburserName" placeholder=" Disburser Name" onChange={handleInputChangeEdit} validations={[required]} value={currentData.disbursementRecieverName} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Disbursement date</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="disbursement_date" className="form-control" name="disbursement_date" placeholder=" Disbursement date" onChange={handleInputChangeEdit} validations={[required]} value={currentData.disbursement_date} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Disbursement currency</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="disbursement_currency" className="form-control" name="disbursement_currency" placeholder=" Disbursement Currency" onChange={handleInputChangeEdit} validations={[required]} value={currentData.disbursement_currency} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Disbursement Amount</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="disbursement_amount" className="form-control" name="disbursement_amount" placeholder=" Disbursement Amount" onChange={handleInputChangeEdit} validations={[required]} value={currentData.disbursement_amount} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Disbursement Reciever Name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="disbursementRecieverName" className="form-control" name="disbursementRecieverName" placeholder=" Disbursement Receiver Name" onChange={handleInputChangeEdit} validations={[required]} value={currentData.disbursementRecieverName} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Disbursement Reciever Id</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="disbursementRecieverId" className="form-control" name="disbursementRecieverId" placeholder=" Disbursement Receiver Id" onChange={handleInputChangeEdit} validations={[required]} value={currentData.disbursementRecieverId} />
                      </div>

                    </div>
                  </div>
                </form>
              </center>
            </div>
            {/* col 2 */}

            <div className="details-small">
              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">

                    <div className="row">
                      <div className="transfer-institution-empty">


                      </div>
                    </div>

                    <br />
                    <div className="row">
                      <div className="col-md-4"> <hr /> </div>
                      <div className="col-md-4"><center> <span className="modal-title">TRANSFER DETAILS</span> </center></div>
                      <div className="col-md-4"><hr /></div>
                    </div>

                    <div className="row">

                      <div className="col-md-4 form-label">
                        <label>Amount</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="amount" className="form-control" name="amount" placeholder="Amount" onChange={handleInputChangeEdit} validations={[required]} value={currentData.amount} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Destinantion instutution</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="destinationInstitution" className="form-control" name="destinationInstitution" placeholder=" Destination institution" onChange={handleInputChangeEdit} validations={[required]} value={currentData.destinationInstitution} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Local Transfer fees</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="local_transfer_fees" className="form-control" name="local_transfer_fees" placeholder=" Local Transfer fees" onChange={handleInputChangeEdit} validations={[required]} value={currentData.local_transfer_fees} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Destination Transfer fees</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="destination_transfer_fees" className="form-control" name="destination_transfer_fees" placeholder="Destination Transfer fees" onChange={handleInputChangeEdit} validations={[required]} value={currentData.destination_transfer_fees} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Total Transfer fees</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="total_transfer_feee" className="form-control" name="total_transfer_feee" placeholder="Total Transfer fees" onChange={handleInputChangeEdit} validations={[required]} value={currentData.total_transfer_feee} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Total Amount</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="total_amount" className="form-control" name="total_amount" placeholder="Total Amount" onChange={handleInputChangeEdit} validations={[required]} value={currentData.total_amount} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Transfer currency</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="transfer_currency" className="form-control" name="transfer_currency" placeholder="Transfer currency" onChange={handleInputChangeEdit} validations={[required]} value={currentData.transfer_currency} />

                      </div>

                      <div className="col-md-4 form-label">
                        <label>Exchange rate</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="exchange_rate" className="form-control" name="exchange_rate" placeholder="Exchange rate" onChange={handleInputChangeEdit} validations={[required]} value={currentData.exchange_rate} />

                      </div>
                      <div className="col-md-4 form-label">
                        <label>Total Transfer</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="total_transfer" className="form-control" name="total_transfer" placeholder="Total transfer" onChange={handleInputChangeEdit} validations={[required]} value={currentData.total_transfer} />
                        <br />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <div className="col-md-8 form-group">

                        {submitted ? ("") : (<button onClick={updateDataDisbursment}
                          type="button" className="button-tools-lg"><FaMoneyBillTransfer /> Update Disburser</button>)}

                        <button
                          type="button" onClick={() => setLgShow3(false)} className="button-tools-lg"><ImCancelCircle /> Close</button>
                        <br /> <br />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8">{message}</label>


                    </div>
                  </div>
                </form>
              </center>
            </div>


          </div>
        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
          <center> <div>Ananda</div> </center>
        </Modal.Footer>
      </Modal>


      <Modal
        size="xl"
        backdrop="static"
        show={lgShow4}
        onHide={() => setLgShow4(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >


        <Modal.Body className="modal-bg">

          <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Transfer View  </div></center>

          <div className="dataRow-with-two-column-center">

            {/* col 1 */}
            <div className="details-small">

              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">

                    <div className="row">
                      <div className="col-md-5"> <hr /> </div>
                      <div className="col-md-2"><center> <span className="modal-title">SENDER</span> </center></div>
                      <div className="col-md-5"><hr /></div>
                    </div>
                    <div className="row">


                      <div className="col-md-4 form-label">
                        <label>Sender Name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="senderName" className="form-control form-text-field" name="senderName" placeholder="Sender Fullname" onChange={handleInputChangeEdit} validations={[required]} value={currentData.senderName} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Sender Id</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="senderId" className="form-control" name="senderId" placeholder="senderId" onChange={handleInputChangeEdit} validations={[required]} value={currentData.senderId} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label> Sender Phone</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="sender_Phone" className="form-control" name="sender_Phone" placeholder="sender Phone" onChange={handleInputChangeEdit} validations={[required]} value={currentData.sender_Phone} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Sender Country</label>
                      </div>

                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="senderCountry" className="form-control" name="senderCountry" placeholder="Sender country" onChange={handleInputChangeEdit} validations={[required]} value={currentData.senderCountry} />
                      </div>
                      <div className="row">
                        <div className="col-md-5"> <hr /> </div>
                        <div className="col-md-2"><center> <span className="modal-title">RECEIVER</span> </center></div>
                        <div className="col-md-5"><hr /></div>
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Receiver Country</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="receiverCountry" className="form-control" name="receiverCountry" placeholder="Receiver Country" onChange={handleInputChangeEdit} validations={[required]} value={currentData.receiverCountry} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Receiver name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="receiverName" className="form-control" name="receiverName" placeholder="Receiver Name" onChange={handleInputChangeEdit} validations={[required]} value={currentData.receiverName} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Receiver phone</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="receiverPhone" className="form-control" name="receiverPhone" placeholder=" Receiver Phone" onChange={handleInputChangeEdit} validations={[required]} value={currentData.receiverPhone} />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8"> </label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <div className="row">
                        <div className="col-md-3"> <hr /> </div>
                        <div className="col-md-6"><center> <span className="modal-title">TRANSFER ACKNOWLEDGEMENT</span> </center></div>
                        <div className="col-md-3"><hr /></div>
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Receiver Instutution Response</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="receiver_instutution_response" className="form-control" name="receiver_instutution_response" placeholder=" Response" onChange={handleInputChangeEdit} validations={[required]} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Receiver Instutution Response ID</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="receiver_instutution_response_id" className="form-control" name="receiver_instutution_response_id" placeholder=" Response Id" onChange={handleInputChangeEdit} validations={[required]} />
                        <br />
                      </div>

                      <div className="row">
                        <div className="col-md-3"> <hr /> </div>
                        <div className="col-md-6"><center> <span className="modal-title">DISBURSEMENT</span> </center></div>
                        <div className="col-md-3"><hr /></div>
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Disburser Name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="disburserName" className="form-control" name="disburserName" placeholder=" Disburser Name" onChange={handleInputChangeEdit} validations={[required]} value={currentData.disbursementRecieverName} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Disbursement date</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="disbursement_date" className="form-control" name="disbursement_date" placeholder=" Disbursement date" onChange={handleInputChangeEdit} validations={[required]} value={currentData.disbursement_date} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Disbursement currency</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="disbursement_currency" className="form-control" name="disbursement_currency" placeholder=" Disbursement Currency" onChange={handleInputChangeEdit} validations={[required]} value={currentData.disbursement_currency} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Disbursement Amount</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="disbursement_amount" className="form-control" name="disbursement_amount" placeholder=" Disbursement Amount" onChange={handleInputChangeEdit} validations={[required]} value={currentData.disbursement_amount} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Disbursement Reciever Name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="disbursementRecieverName" className="form-control" name="disbursementRecieverName" placeholder=" Disbursement Receiver Name" onChange={handleInputChangeEdit} validations={[required]} value={currentData.disbursementRecieverName} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Disbursement Reciever Id</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="disbursementRecieverId" className="form-control" name="disbursementRecieverId" placeholder=" Disbursement Receiver Id" onChange={handleInputChangeEdit} validations={[required]} value={currentData.disbursementRecieverId} />
                      </div>

                    </div>
                  </div>
                </form>
              </center>
            </div>
            {/* col 2 */}

            <div className="details-small">
              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">

                    <div className="row">
                      <div className="transfer-institution-empty">


                      </div>
                    </div>

                    <br />
                    <div className="row">
                      <div className="col-md-4"> <hr /> </div>
                      <div className="col-md-4"><center> <span className="modal-title">TRANSFER DETAILS</span> </center></div>
                      <div className="col-md-4"><hr /></div>
                    </div>

                    <div className="row">

                      <div className="col-md-4 form-label">
                        <label>Amount</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="amount" className="form-control" name="amount" placeholder="Amount" onChange={handleInputChangeEdit} validations={[required]} value={currentData.amount} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Destinantion instutution</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="destinationInstitution" className="form-control" name="destinationInstitution" placeholder=" Destination institution" onChange={handleInputChangeEdit} validations={[required]} value={currentData.destinationInstitution} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Local Transfer fees</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="local_transfer_fees" className="form-control" name="local_transfer_fees" placeholder=" Local Transfer fees" onChange={handleInputChangeEdit} validations={[required]} value={currentData.local_transfer_fees} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Destination Transfer fees</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="destination_transfer_fees" className="form-control" name="destination_transfer_fees" placeholder="Destination Transfer fees" onChange={handleInputChangeEdit} validations={[required]} value={currentData.destination_transfer_fees} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Total Transfer fees</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="total_transfer_feee" className="form-control" name="total_transfer_feee" placeholder="Total Transfer fees" onChange={handleInputChangeEdit} validations={[required]} value={currentData.total_transfer_feee} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Total Amount</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="total_amount" className="form-control" name="total_amount" placeholder="Total Amount" onChange={handleInputChangeEdit} validations={[required]} value={currentData.total_amount} />
                      </div>

                      <div className="col-md-4 form-label">
                        <label>Transfer currency</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="transfer_currency" className="form-control" name="transfer_currency" placeholder="Transfer currency" onChange={handleInputChangeEdit} validations={[required]} value={currentData.transfer_currency} />

                      </div>

                      <div className="col-md-4 form-label">
                        <label>Exchange rate</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="exchange_rate" className="form-control" name="exchange_rate" placeholder="Exchange rate" onChange={handleInputChangeEdit} validations={[required]} value={currentData.exchange_rate} />

                      </div>
                      <div className="col-md-4 form-label">
                        <label>Total Transfer</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="total_transfer" className="form-control" name="total_transfer" placeholder="Total transfer" onChange={handleInputChangeEdit} validations={[required]} value={currentData.total_transfer} />
                        <br />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <div className="col-md-8 form-group">

                        {/* {submitted ? ("") : (<button onClick={updateData}
                          type="button" className="button-tools-lg"><FaMoneyBillTransfer /> Update Disburser</button>)} */}

                        <button
                          type="button" onClick={() => setLgShow4(false)} className="button-tools-lg"><ImCancelCircle /> Close</button>
                        <Link
                          type="button" className="button-tools-lg" to={`/transfer-receipt/`} state={currentData}>  Print
                        </Link>

                        {/* <button
                          type="button" className="col-md-1 table-buttons"
                          onClick={() => openData(tutorial.orderid)}
                        > <AiOutlinePrinter />
                        </button> */}


                        <br /> <br />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8">{message}</label>


                    </div>
                  </div>
                </form>

                {/* <Link onClick={()=>receipt(currentData.id)} className="button-form">Print</Link> */}

              </center>
            </div>


          </div>
        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
          <center> <div>Ananda</div> </center>
        </Modal.Footer>
      </Modal>





    </>
  );
};
