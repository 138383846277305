import React, { useState, useRef, useEffect } from "react";
import { FcServices } from 'react-icons/fc';
import { GiTimeBomb } from 'react-icons/gi';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";


import DatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
//import OrderService from "../../services/order.service";
import ExpensesService from "../../services/expense.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';
import MemberService from "../../services/members.service";
import { useNavigate, Link, Outlet } from 'react-router-dom';
import { FaMoneyBillTransfer } from "react-icons/fa6";
import logo from '../images/ananda.jpg';
import OrganizationDetailsService from "../../services/organization-details.service";
import TransferExchangeService from "../../services/transfer-exchange.service";


const currentUser = AuthService.getCurrentUser();

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default function TransferExchangeList() {

  const currentUser = AuthService.getCurrentUser();

  let navigate = useNavigate();

  useEffect(() => {

    if (JSON.parse(localStorage.getItem("user")) == null) {

      //redirectToLogin();

      navigate('/login', { replace: true });

    } else {

      let decodedToken = jwt_decode(currentUser.accessToken);
      let currentDate = new Date();
      // JWT exp is in seconds redirect to login
      if (decodedToken.exp * 1000 < currentDate.getTime()) {

        AuthService.logout();
        navigate('/login', { replace: true });
      }

    }

  }, []);

  const initialDataState = {
    id: null,
    pair: "",
    lastUpdateTime: "",
    buy: 0,
    sell: 0,
    priceSource: "",
    status: "UPDATED",
    username: "",


  };

  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentOrderData, setCurrentOrderData] = useState([]);
  const [currentOrderDataSummary, setCurrentOrderDataSummary] = useState([]);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [orderedData, setOrderedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [memberdetails, setMemberDetails] = useState([]);
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [organizationDetails, setorganizationDetails] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();
  const [isManager, setIsManager] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [role, setRole] = useState("Cashier");
  const [cashierName, setCashierName] = useState("");


  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);

  const [cash, setCash] = useState("");
  const [momo, setMomo] = useState("");
  const [visa, setVisa] = useState("");
  const [cheque, setCheque] = useState("");
  const [transfer, setTransfer] = useState("");

  const componentRef = useRef(null);

  const pageSizes = [100, 300, 500];

  tutorialsRef.current = tutorials;

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);
    setCashierName(event.target.value);

  }

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value);
  }

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveData = () => {

    setPage(1);
    const params = getRequestParams(searchTitle, page, pageSize);
    TransferExchangeService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);
      })
      .catch((e) => {
        console.log(e);
      });

    getSelectOption();

    getMemberData();

    getOrganizationDetails();
  };


  const updateData = () => {
    setPage(1);
    setMessage("");

    TransferExchangeService.update(currentData.id, currentData)
      .then(response => {
        setMessage(response.data);
        submitted(true);
        retrieveData();
      })
      .catch(e => {
        console.log(e);
      });
    refreshList();
  };


  const saveData = () => {
    setPage(1);

    var data = {
      pair: tutorial.pair,
      lastUpdateTime: tutorial.lastUpdateTime,
      buy: tutorial.buy,
      sell: tutorial.sell,
      status: tutorial.status,
      priceSource: tutorial.priceSource
    };

    if (tutorial.pair === "" | tutorial.pair === null) {
      setMessage("Pair name missing.");
    } else if (tutorial.buy === "" | tutorial.buy === null) {
      setMessage("Buy price missing.");
    }

    else if (tutorial.sell === "" | tutorial.sell === null) {
      setMessage("Sell price missing.");
    }

    else if (tutorial.priceSource === "" | tutorial.priceSource === null) {
      setMessage("Price source is missing.");
    } else {

      TransferExchangeService.create(data)
        .then(response => {

          setSubmitted(true);
          setMessage(response.data);
          retrieveData();
        })
        .catch(e => {
          console.log(e);
        });

    }


  };

  const newData = () => {
    setData(initialDataState);
    setSubmitted(false);
    setMessage("");


  };


  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setIsManager(true);

    }

  }


  const getOrganizationDetails = () => {
    setPage(1);

    OrganizationDetailsService.get(1)
      .then((response) => {

        setorganizationDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }

  const getSelectOption = () => {
    setPage(1);

    var category = "TRANSFER-EXCHANGE-PAIR";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });

  };



  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };

  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {
    setMessage("");
    setLgShow2(true);
    setPage(1);
    setcurrentData(initialDataState);

    TransferExchangeService.get(id)
      .then(response => {
        setcurrentData(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const add = () => {
    // props.history.push("/expense-new");
    navigate('/expense-new', { replace: true });
  }




  const logOut = () => {
    AuthService.logout();
  }


  const handleChangeEndDate = (date) => {

    setEndDate(date);

  }

  const handleChangeStartDate = (date) => {

    setStartDate(date);

  }

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);
  const [lgShow3, setLgShow3] = useState(false);


  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const searchOrder = () => {
    setPage(1);

  }

  const viewDetails = (id) => {
    setMessage("");
    setLgShow3(true);
    setPage(1);
    setcurrentData(initialDataState);

    TransferExchangeService.get(id)
      .then(response => {
        setcurrentData(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };



  return (
    <>
      <div className='pages'>

        <div className="row">
          {/* cards  */}

          <div className="col-md-3 tool-box">

            <table className=" table-tools">
              <div className="table-account">
                <tbody>

                  <tr>
                    <td><div className="table-name">{memberdetails.firstname}</div></td>
                    <td><div className="table-name">{memberdetails.lastname}</div></td>
                  </tr>
                  <tr>
                    <td>Branch:</td>
                    <td> <div className="table-name">{memberdetails.account}</div></td>
                  </tr>
                </tbody>
              </div>
            </table>
          </div>

          {/* <div className="col-md-3 tool-box">
             
          </div> */}

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  {/* <td className="tg-0lax"><button onClick={add} className="button-tools">New</button></td> */}
                  <td className="tg-0lax"><button onClick={() => setLgShow(true)} className="button-tools">New</button></td>
                </tr>

              </tbody>

            </table>
          </div>


          {/* end card box */}


          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>

              <div className="col-md-3 table-title">  CURRENCY EXCHANGE </div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>

            </div>
            <div className="details">

              <div className="table-wrap">
                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>

                      <th>ID</th>
                      <th>PAIR</th>
                      <th>LAST UPDATE TIME</th>
                      <th>BUY</th>
                      <th>SELL</th>
                      <th>PRICE SOURCE</th>
                      <th>STATUS</th>
                      <th>USERNAME</th>
                      <th></th>


                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.pair}</td>
                          <td>{tutorial.lastUpdateTime}</td>
                          <td>{tutorial.buy}</td>
                          <td>{tutorial.sell}</td>
                          <td>{tutorial.priceSource}</td>

                          <td><span className={"status " + tutorial.status}>{tutorial.status}</span></td>
                          <td>{tutorial.username}</td>
                          <td><div className="table-icons-wrapper">

                            <button
                              type="button" className="table-buttons-l"
                              onClick={() => viewDetails(tutorial.id)}
                            > Details
                            </button>

                            <button
                              type="button" className="table-buttons-l"
                              onClick={() => openObject(tutorial.id)}
                            > Edit
                            </button>

                          </div></td>
                        </tr>

                      ))}
                  </tbody>

                </table>

              </div>

            </div>

          </div>
        </div>

      </div>

      <Modal
        size="md"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >


        <Modal.Body className="modal-bg">

          <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <br />

          <center> <div className="col-md-4 ananda-modal-header-title"> EXCHANGE  </div></center>

          <div className="dataRow-with-one-column-center">

            {/* col 1 */}
            <div className="details-small">

              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">

                    <div className="row">


                      <div className="col-md-4 form-label">
                        <label>Pair</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <select id="pair" name="pair" className="form-control select-option" onChange={handleInputChange} >
                          <option>Select</option>
                          {optionCategoryArray.map((optionCategory) => (
                            <option key={optionCategory.value}>{optionCategory.value}</option>
                          ))}

                        </select>
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Buy  price</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="buy" className="form-control" name="buy" placeholder="Buy price" onChange={handleInputChange} value={tutorial.buy} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Sell price</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="sell" className="form-control" name="sell" placeholder="Sell price" onChange={handleInputChange} value={tutorial.sell} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Price source</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="priceSource" className="form-control" name="priceSource" placeholder="Price source" onChange={handleInputChange} value={tutorial.priceSource} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Comment</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="comment" className="form-control" name="comment" placeholder="Remark" onChange={handleInputChange} value={tutorial.comment} />
                      </div>


                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <div className="col-md-8 form-group">

                        {submitted ? ("") : (<button onClick={saveData}
                          type="button" className="button-tools"><FaMoneyBillTransfer /> Save</button>)}

                        <button
                          type="button" onClick={() => setLgShow(false)} className="button-tools"><ImCancelCircle /> Close</button>
                        <br /> <br />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8">{message}</label>


                    </div>
                  </div>
                </form>
              </center>
            </div>


          </div>
        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
          <center> <div>Ananda</div> </center>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >


        <Modal.Body className="modal-bg">

          <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Expenses  </div></center>

          <div className="dataRow-with-one-column-center">

            {/* col 1 */}
            <div className="details-small">

              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">

                    <div className="row">


                      <div className="col-md-4 form-label">
                        <label>Pair</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <select disabled id="pair" name="pair" className="form-control select-option" >
                          <option>{currentData.pair}</option>
                          {optionCategoryArray.map((optionCategory) => (
                            <option key={optionCategory.value}>{optionCategory.value}</option>
                          ))}

                        </select>
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Buy  price</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="buy" className="form-control" name="buy" placeholder="Buy price" onChange={handleInputChangeEdit} value={currentData.buy} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Sell price</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="sell" className="form-control" name="sell" placeholder="Sell price" onChange={handleInputChangeEdit} value={currentData.sell} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Price source</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="priceSource" className="form-control" name="priceSource" placeholder="Price source" onChange={handleInputChangeEdit} value={currentData.priceSource} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Comment</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="comment" className="form-control" name="comment" placeholder="Remark" onChange={handleInputChangeEdit} value={currentData.comment} />
                      </div>


                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <div className="col-md-8 form-group">

                        {submitted ? ("") : (<button onClick={updateData}
                          type="button" className="button-tools"><FaMoneyBillTransfer /> Update</button>)}

                        <button
                          type="button" onClick={() => setLgShow2(false)} className="button-tools"><ImCancelCircle /> Close</button>
                        <br /> <br />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8">{message}</label>


                    </div>
                  </div>
                </form>
              </center>
            </div>


          </div>
        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
          <center> <div>Ananda</div> </center>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        backdrop="static"
        show={lgShow3}
        onHide={() => setLgShow3(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >


        <Modal.Body className="modal-bg">

          <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Expenses  </div></center>

          <div className="dataRow-with-one-column-center">

            {/* col 1 */}
            <div className="details-small">

              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">

                    <div className="row">


                      <div className="col-md-4 form-label">
                        <label>Pair</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <select disabled id="pair" name="pair" className="form-control select-option" >
                          <option>{currentData.pair}</option>
                          {optionCategoryArray.map((optionCategory) => (
                            <option key={optionCategory.value}>{optionCategory.value}</option>
                          ))}

                        </select>
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Buy  price</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="buy" className="form-control" name="buy" placeholder="Buy price" onChange={handleInputChangeEdit} value={currentData.buy} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Sell price</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="sell" className="form-control" name="sell" placeholder="Sell price" onChange={handleInputChangeEdit} value={currentData.sell} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Price source</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="priceSource" className="form-control" name="priceSource" placeholder="Price source" onChange={handleInputChangeEdit} value={currentData.priceSource} />
                      </div>
                      <div className="col-md-4 form-label">
                        <label>Comment</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="comment" className="form-control" name="comment" placeholder="Remark" onChange={handleInputChangeEdit} value={currentData.comment} />
                      </div>


                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <div className="col-md-8 form-group">

                        {submitted ? ("") : (<button onClick={updateData}
                          type="button" className="button-tools"><FaMoneyBillTransfer /> Update</button>)}

                        <button
                          type="button" onClick={() => setLgShow3(false)} className="button-tools"><ImCancelCircle /> Close</button>
                        <br /> <br />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8">{message}</label>


                    </div>
                  </div>
                </form>
              </center>
            </div>


          </div>
        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
          <center> <div>Ananda</div> </center>
        </Modal.Footer>
      </Modal>




    </>
  );
};
