import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import './App.css';
import './style/forms.css';
import './style/AppLogin.css';
import './style/main.css';
import './style/table.css';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datepicker/dist/react-datepicker.css";
import Navbar from './components/Navbar';
import AuthService from './services/auth.service';
import Home from './components/home/Home';
import Login from './components/authentication/Login';
import ProductList from './components/product/Products-list';
import ProductView from './components/product/product-view';
import ProductNew from './components/product/product-new';
// import Saleslist from './components/sales/Sales-summary';
// //import Saleslist2 from './components/sales/Sales-summary2';
// import SalesPendingOrders from './components/sales/sales-pending-orders';
// import SalesPendingOrderSaller from './components/sales/sales-pending-order-saller';
// import SalesPendingOrderDeposit from './components/sales/sales-pending-order-deposit';
// import SalesOrderNew from './components/sales/Sales-orders-new';
// import SalesOrderSuccess from './components/sales/sales-order-new-success';
// import SalesReceipt from './components/sales/Sales-receipt';
// import SalesOrderNewScanner from './components/sales/Sales-orders-new-scanner';


//import StockListIn from './components/stock/stock-list-in';
// import StockListInPages from './components/stock/stock-list-in-pages';
// import StockInNew from './components/stock/stock-in-new';
// import StockInView from './components/stock/stock-in-view';
// import StockOutView from './components/stock/stock-out-view';
// import StockListOut from './components/stock/stock-list-out';
// import StockOutNew from './components/stock/stock-out-new';

// import StockListLevel from './components/stock/stock-level-list';
// import StockLevelViewTransfer from './components/stock/stock-level-view-transfer';
// import StockLevelViewDelete from './components/stock/stock-level-view-delete';
// import StockLevelView from './components/stock/stock-level-view';
// import StockListReturn from './components/stock/stock-returned-list';
// import StockReturnNew from './components/stock/stock-returned-new';
// import StockReturnedView from './components/stock/stock-returned-view';
// import StockListInInspect from './components/stock/stock-list-in-inspect';
// import StockListOutInspect from './components/stock/stock-list-out-inspect';
// import StockListLevelBySize from './components/stock/stock-level-list-by-size';
// import StockListLevelByCode from './components/stock/stock-level-list-by-code';
// import StockInNewScanner from './components/stock/stock-in-new-scanner';


// import StockkeeperListSummary from './components/stockkeeper/stockkeper-list-summary';
// import StockkeeperStockRequestForm from './components/stockkeeper/stockkeeper-stock-request-form';
// import StockkeeperPendingRequest from './components/stockkeeper/stockkeeper-pending-request';


// import PaymentsAll from './components/payment/paymentAll';
// import PaymentForm from './components/payment/payment-form';
// import PaymentsReport from './components/payment/payment-report';

import ExpenseList from './components/expenses/expense-list';
// import ExpenseNew from './components/expenses/expense-new';
import ExpenseView from './components/expenses/expense-view';

import UsersLIst from './components/users/users-list';
import MemberView from './components/members/members-view';

import UserRegistration from './components/users/registration';

import CustomersList from './components/customers/customers-list';
import CustomerNew from './components/customers/customer-new';
import CustomerView from './components/customers/customer-view';
import CustomerSearch from './components/customers/customers-search';

import CashingList from './components/cashing/cashing-list';

import DeliveryListAll from './components/delivery/delivery-list-all';
import DeliveryView from './components/delivery/delivery-view';
import DeliveryReport from './components/delivery/delivery-report';
import DeliveryReportReste from './components/delivery/delivery-report -reste';
import DeliveryReportPending from './components/delivery/delivery-report -pending';
import DeliveryReportCompleted from './components/delivery/delivery-report -completed';
import DeliveryNoteAll from './components/delivery/delivery-note-all';
import DeliveryNoteReste from './components/delivery/delivery-note-reste';
import DeliveryListDelivered from './components/delivery/delivery-list-delivered';

import VideList from './components/vide/vide-list';
import VideNew from './components/vide/vide-new';
import VideView from './components/vide/vide-view';
import VideListLevel from './components/vide/vide-level-list';
import VideLevelView from './components/vide/vide-level-view';

import UnauthorizedAccess from './components/home/Unauthorized-access';
import UnauthorizedAccessDisabledAccount from './components/home/Unauthorized-access-disabled';

//import ShippingList from './components/shipping/Shipping-list';
//import ShippingNew from './components/shipping/Shipping-new';

import Reports from './components/report/reports-cashier';
import ReportsSales from './components/report/reports-sales';
import PrintReportCashier from './components/report/print-report-cashier';
import ReportSallers from './components/report/reports-sallers';
import ReportSallersPerformance from './components/report/reports-sallers-perfromance';
import ReportsJournal from './components/report/reports-journal';
import ReportsCashing from './components/report/reports-cashing';
import ReportStand from './components/report/reports-stand';
import ReportStandProfit from './components/report/reports-stand-profit';
import SettingList from './components/settings/settings-list';

import AccessList from './components/access-control/access-list';
import AccessOtpList from './components/access-control/access-otp-list';
import StockInitialList from './components/stock/stock-initial-list';

import DashboardList from './components/dashboard/dashboard-list'
import StockKeeperReceipt from './components/stockkeeper/stockkeeper-receipt';

import PrevisionList from './components/prevision/prevision-list';

import TransferList from './components/transfer/transfer-list';
import TransferNew from './components/transfer/transfer-new';

import TransferDetailsList from './components/transfer/transfer-details-list';
import TransferReceipt from './components/transfer/transfer-receipt';
import TransferCommissionList from './components/transfer/transfer-commission';
import TransferExchangeList from './components/transfer-exchange/transfer-exchange-list';





const currentUser = AuthService.getCurrentUser();

var isAuth=false;
var isAdmin=false;

const check =()=>{

  if (JSON.parse(localStorage.getItem("user")) == null) {

    isAuth = false;
    
  } else {
    isAuth = true;
    
    let user = currentUser;

    let decodedToken = jwt_decode(user.accessToken);
    let currentDate = new Date();
    // JWT exp is in seconds redirect to login
    if (decodedToken.exp * 1000 < currentDate.getTime()) {

      isAuth = false;
      
    } else {
      isAuth = true;
      

    }
  }
 

}


function App() {

  check();

  return (
    <>
    

      {/* {isAuthenticated ? ( */}

      <BrowserRouter>

      {isAuth?(<Navbar />):("")}
      
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<DashboardList />} />
          <Route path="/login" element={<Login />} />
          <Route path="/unauthorized" element={UnauthorizedAccess} />
          <Route path="/unauthorized-disabled" element={UnauthorizedAccessDisabledAccount} />

          
          <Route path="/customers" element={<CustomersList />} />
          <Route path='/customers-search' element={<CustomerSearch/>} />
          <Route path='/customers-new' element={<CustomerNew/>} />
          <Route path='/customer/:id' element={<CustomerView/>} />

          <Route path='/products' element={<ProductList/>} />
          <Route exact path="/product/:id" element={<ProductView/>} />
          <Route path='/products-new' element={<ProductNew/>} />
          <Route exact path="/expense-list" element={<ExpenseList/>} />
          <Route exact path="/expense/:id" element={<ExpenseView/>} />
          <Route exact path="/users" element={<UsersLIst/>} />
          <Route exact path="/registration" element={<UserRegistration/>} />

          <Route exact path="/deliveries" element={<DeliveryListAll/>} />
          <Route exact path="/deliveries/:id" element={<DeliveryView/>} />
          <Route exact path="/delivery-report" element={<DeliveryReport/>} />
          <Route exact path="/delivery-reste" element={<DeliveryReportReste/>} />
          <Route exact path="/delivery-pending" element={<DeliveryReportPending/>} />
          <Route exact path="/delivery-completed" element={<DeliveryListDelivered/>} />
          <Route exact path="/delivery-note" element={<DeliveryNoteAll/>} />
          <Route exact path="/delivery-note-reste" element={<DeliveryNoteReste/>} />

          <Route exact path="/settings" element={<SettingList/>} />
          <Route exact path="/cashing" element={<CashingList/>} />

          <Route exact path="/reports" element={<Reports/>} /> 
          <Route exact path="/reports-cashier" element={<PrintReportCashier/>} />
          <Route exact path="/reports-sallers" element={<ReportSallers/>} />
          <Route exact path="/reports-stand" element={<ReportStand/>} />
          <Route exact path="/reports-stand-profit" element={<ReportStandProfit/>} />
          <Route exact path="/reports-sales" element={<ReportsSales/>} />
          <Route exact path="/reports-journal" element={<ReportsJournal/>} />
          <Route exact path="/reports-cashing" element={<ReportsCashing/>} />
          <Route exact path="/reports-performance" element={<ReportSallersPerformance/>} />         
          <Route exact path="/dashboard" element={<DashboardList/>} />

          <Route exact path="/access" element={<AccessList/>} />
          <Route exact path="/access-otp" element={<AccessOtpList/>} />

          <Route exact path="/prevision" element={<PrevisionList/>} />

          <Route exact path="/transfer" element={<TransferList/>} />
          <Route exact path="/transfer-new" element={<TransferNew/>} />
          <Route exact path="/transfer-details" element={<TransferDetailsList/>} />         
          <Route exact path='transfer-receipt' element={<TransferReceipt/>} />
          <Route exact path='transfer-commission' element={<TransferCommissionList/>} />
          <Route exact path='transfer-exchange' element={<TransferExchangeList/>} />
        </Routes>

        {/* ):( */}



      </BrowserRouter>

      {/* )} */}

    </>
  );
}

export default App;