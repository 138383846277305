import http from "./http-common";
import authHeader from "./auth-header";


const getAll = (params) => {
    return http.get("/transfer-exchange", { params,headers: authHeader() });
  };
  

const get = (id) => {
  return http.get(`/transfer-exchange/${id}`,{ headers: authHeader() });
};

const create = (data) => {
   
  return http.post("/transfer-exchange", data,{ headers: authHeader() });
};

const update = (id, data) => {
  return http.put(`/transfer-exchange/${id}`, data,{ headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/transfer-exchange/${id}`,{ headers: authHeader()});
};



const TransferExchangeService = {
  getAll,
  get,
  create,
  update,
  remove,

  
};

export default TransferExchangeService;
