import React, { useState, useRef, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';
import { FcServices } from 'react-icons/fc';
import { GiTimeBomb } from 'react-icons/gi';
import { AiFillFolderOpen } from 'react-icons/ai';
import DatePicker from 'react-datepicker';
import Chart from "react-apexcharts";
import { withRouter } from 'react-router';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import CashingService from "../../services/cashing.service";
import MemberService from "../../services/members.service";
import SelectOptionService from "../../services/select-options.service";
import DashboardService from "../../services/dashboard.service";
import { format } from 'date-fns';
import { useNavigate, Link, Outlet } from 'react-router-dom';
import CurrencyFormat from "react-currency-format";
import PrevisionService from "../../services/prevision.service";
import TransferService from "../../services/transfer.service";
import TransferExchangeService from "../../services/transfer-exchange.service";


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const currentUser = AuthService.getCurrentUser();


export default function DashboardList() {


  let navigate = useNavigate();
  const nav = useNavigate();

  if (JSON.parse(localStorage.getItem("user")) == null) {

    //return <Redirect to="/login" />;
    navigate('/login', { replace: true });

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    navigate('/login', { replace: true });

  }

  if (!currentUser.roles.includes("ROLE_CASHIER")) {
    //return <Redirect to="/unauthorized" />;
    navigate('/unauthorized', { replace: true });

  }


  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);


  const initialDataState = {
    id: null,
    date: "",
    cashier: "",
    amount: "",
    headCashier: "",
    headCashierAmount: "",
    comment: "",
    fiveThousands: "",
    twoThousands: "",
    oneThousands: "",
    fiveHundred: "",
    onehundred: "",
    fifty: "",
    twenty: "",
    ten: "",
    five: "",
    two: "",
    one: "",

    euro: "",
    euroRate: "",
    euro2: "",
    euroRate2: "",
    euro3: "",
    euroRate3: "",
    euro4: "",
    euroRate4: "",

    dollar: "",
    dollarRate: "",
    dollar2: "",
    dollarRate2: "",
    dollar3: "",
    dollarRate3: "",
    dollar4: "",
    dollarRate4: "",
    caisseInit: "",
    pointerName: "",
    pointerAmount: "",
    receivedBy: "",



    username: "",

  };

  const [tutorial, setData] = useState(initialDataState);
  const [searchTitle, setSearchTitle] = useState("");
  const [count, setCount] = useState(0);
  // const [submitted, setSubmitted] = useState(false);
  const [memberdetails, setMemberDetails] = useState([]);
  const [isManager, setIsManager] = useState(false);
  const [currentData, setcurrentData] = useState([]);
  const [message, setMessage] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [amountValidated, setAmountValidated] = useState(0);
  const [isValidated, setIsValidated] = useState(false);
  const [isHeadCashier, setIsHeadCashier] = useState(false);

  const [tutorials, setDatas] = useState([]);
  const [transfer_by_country, settransfer_by_country] = useState([]);
  const [transfer_exchange, settransfer_exchange] = useState([]);
  const tutorialsRef = useRef();

  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [cashierName, setCashierName] = useState("");

  const [page, setPage] = useState(1);
  
  const [pageSize, setPageSize] = useState(100);

  const pageSizes = [200, 500, 1000];

  tutorialsRef.current = tutorials;


  const [SALES_JAN, setSALES_JAN] = useState(0);
  const [SALES_FEB, setSALES_FEB] = useState(0);
  const [SALES_MARC, setSALES_MARC] = useState(0);
  const [SALES_APR, setSALES_APR] = useState(0);
  const [SALES_MAY, setSALES_MAY] = useState(0);
  const [SALES_JUN, setSALES_JUN] = useState(0);
  const [SALES_JULY, setSALES_JULY] = useState(0);
  const [SALES_AUG, setSALES_AUG] = useState(0);
  const [SALES_SEPT, setSALES_SEPT] = useState(0);
  const [SALES_OCT, setSALES_OCT] = useState(0);
  const [SALES_NOV, setSALES_NOV] = useState(0);
  const [SALES_DEC, setSALES_DEC] = useState(0);

  const [TARGET_SALES_JAN, setTARGET_SALES_JAN] = useState(0);
  const [TARGET_SALES_FEB, setTARGET_SALES_FEB] = useState(0);
  const [TARGET_SALES_MARC, setTARGET_SALES_MARC] = useState(0);
  const [TARGET_SALES_APR, setTARGET_SALES_APR] = useState(0);
  const [TARGET_SALES_MAY, setTARGET_SALES_MAY] = useState(0);
  const [TARGET_SALES_JUN, setTARGET_SALES_JUN] = useState(0);
  const [TARGET_SALES_JULY, setTARGET_SALES_JULY] = useState(0);
  const [TARGET_SALES_AUG, setTARGET_SALES_AUG] = useState(0);
  const [TARGET_SALES_SEPT, setTARGET_SALES_SEPT] = useState(0);
  const [TARGET_SALES_OCT, setTARGET_SALES_OCT] = useState(0);
  const [TARGET_SALES_NOV, setTARGET_SALES_NOV] = useState(0);
  const [TARGET_SALES_DEC, setTARGET_SALES_DEC] = useState(0);

  const [PROFIT_JAN, setPROFIT_JAN] = useState(0);
  const [PROFIT_FEB, setPROFIT_FEB] = useState(0);
  const [PROFIT_MARC, setPROFIT_MARC] = useState(0);
  const [PROFIT_APR, setPROFIT_APR] = useState(0);
  const [PROFIT_MAY, setPROFIT_MAY] = useState(0);
  const [PROFIT_JUN, setPROFIT_JUN] = useState(0);
  const [PROFIT_JULY, setPROFIT_JULY] = useState(0);
  const [PROFIT_AUG, setPROFIT_AUG] = useState(0);
  const [PROFIT_SEPT, setPROFIT_SEPT] = useState(0);
  const [PROFIT_OCT, setPROFIT_OCT] = useState(0);
  const [PROFIT_NOV, setPROFIT_NOV] = useState(0);
  const [PROFIT_DEC, setPROFIT_DEC] = useState(0);


  const handleChangeEndDate = (date) => {

    setEndDate(date);

  }

  const handleChangeStartDate = (date) => {

    setStartDate(date);

  }

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });

  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);
    setCashierName(event.target.value);

  }

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };


  const retrieveData = () => {
    setPage(1);


    var data = {
      text1: format(startDate, 'yyyy-MM-dd') + " 00:00:00",
      text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
      text3: cashierName
    }


    DashboardService.getReport(data)
      .then((response) => {

        const { SALES_JAN, SALES_FEB, SALES_MARC, SALES_APR, SALES_MAY, SALES_JUN, SALES_JULY, SALES_AUG, SALES_SEPT, SALES_OCT, SALES_NOV, SALES_DEC,
          TARGET_SALES_JAN, TARGET_SALES_FEB, TARGET_SALES_MARC, TARGET_SALES_APR, TARGET_SALES_MAY, TARGET_SALES_JUN, TARGET_SALES_JULY, TARGET_SALES_AUG,
          TARGET_SALES_SEPT, TARGET_SALES_OCT, TARGET_SALES_NOV, TARGET_SALES_DEC,

          PROFIT_JAN, PROFIT_FEB, PROFIT_MARC, PROFIT_APR, PROFIT_MAY, PROFIT_JUN, PROFIT_JULY, PROFIT_AUG, PROFIT_SEPT, PROFIT_OCT, PROFIT_NOV, PROFIT_DEC } = response.data;

        setSALES_JAN(SALES_JAN);
        setSALES_FEB(SALES_FEB);
        setSALES_MARC(SALES_MARC);
        setSALES_APR(SALES_APR);
        setSALES_MAY(SALES_MAY);
        setSALES_JUN(SALES_JUN);
        setSALES_JULY(SALES_JULY);
        setSALES_AUG(SALES_AUG);
        setSALES_SEPT(SALES_SEPT);
        setSALES_OCT(SALES_OCT);
        setSALES_NOV(SALES_NOV);
        setSALES_DEC(SALES_DEC);

        setPROFIT_JAN(PROFIT_JAN);
        setPROFIT_FEB(PROFIT_FEB);
        setPROFIT_MARC(PROFIT_MARC);
        setPROFIT_APR(PROFIT_APR);
        setPROFIT_MAY(PROFIT_MAY);
        setPROFIT_JUN(PROFIT_JUN);
        setPROFIT_JULY(PROFIT_JULY);
        setPROFIT_AUG(PROFIT_AUG);
        setPROFIT_SEPT(PROFIT_SEPT);
        setPROFIT_OCT(PROFIT_OCT);
        setPROFIT_NOV(PROFIT_NOV);
        setPROFIT_DEC(PROFIT_DEC);

      })
      .catch((e) => {
        console.log(e);
      });

    var data1 = {
      text1: "2024",
      text2: "Income"
    }

    PrevisionService.getPrevisionPerYearAndType(data1)
      .then((response) => {


        setTARGET_SALES_JAN(response.data.january);
        setTARGET_SALES_FEB(response.data.february);
        setTARGET_SALES_MARC(response.data.march);
        setTARGET_SALES_APR(response.data.april);
        setTARGET_SALES_MAY(response.data.may);
        setTARGET_SALES_JUN(response.data.june);
        setTARGET_SALES_JULY(response.data.july);
        setTARGET_SALES_AUG(response.data.augostin);
        setTARGET_SALES_SEPT(response.data.september);
        setTARGET_SALES_OCT(response.data.octomber);
        setTARGET_SALES_NOV(response.data.november);
        setTARGET_SALES_DEC(response.data.december);



      })
      .catch((e) => {
        console.log(e);
      });

    var tranferByStatus = {
      text1: "2024-01-01 00:00:00",
      text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
      text3: "Pending"
    }


    TransferService.getTranferByStatus(tranferByStatus)
      .then((response) => {

        setDatas(response.data);


      })
      .catch((e) => {
        console.log(e);
      });


    var tranferByCountry = {
      text1: "2024-01-01 00:00:00",
      text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",

    }

    TransferService.getTranferByCountry(tranferByCountry)
      .then((response) => {

        settransfer_by_country(response.data);



      })
      .catch((e) => {
        console.log(e);
      });

      setPage(1);
      const params = getRequestParams(searchTitle, page, pageSize);
      TransferExchangeService.getAll(params)
        .then((response) => {
          const { tutorials, totalPages } = response.data;
  
          settransfer_exchange(tutorials);
          setCount(totalPages);
        })
        .catch((e) => {
          console.log(e);
        });



    getMemberData();
    getSelectOption();
  };

  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setIsManager(true);

    }

    if (currentUser.roles.includes("ROLE_HEADCASHIER")) {
      setIsHeadCashier(true);

    }

  }

  const getSelectOption = () => {
    setPage(1);

    var category = "CASHIER";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        // setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });

  };


  useEffect(retrieveData, [page, pageSize]);



  const refreshList = () => {
    setPage(1);
    retrieveData();
  };


  const openObject = (id) => {
    setPage(1);

    setMessage("");

    setcurrentData(initialDataState);
    // const id = tutorialsRef.current[rowIndex].id;


    CashingService.get(id)
      .then(response => {
        setcurrentData(response.data);
        // console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });

  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };


  const confirm = () => {

    setMessage("");

    var data = {
      text1: currentData.id,
      text2: currentData.headCashierAmount,
      text3: currentData.comment,
      text4: currentData.receivedBy,
      text5: currentUser.username
    }

    if (currentData.headCashierAmount === null || currentData.headCashierAmount === "") {
      setMessage("Amount is empty!");
    } else if (currentData.headCashierAmount < 1) {
      setMessage("Amount is 0");
    } else if (currentData.comment === null || currentData.comment === "") {
      setMessage("Comment is empty");
    } else {

      CashingService.confirm(data)
        .then(response => {

          const { message } = response.data;
          setMessage(message);
          retrieveData();
        })
        .catch(e => {
          console.log(e);
        });


    }


  }

  const options = {
    xaxis: {
      categories: ["JAN", "FEB", "MARC", "APR", "MAY", "JUN", "JULY", "AUG", "SEP", "OCT", "NOV", "DEC"]
    }
  };

  const series_sales_target = [

    {
      name: " Sales",
      data: [SALES_JAN, SALES_FEB, SALES_MARC, SALES_APR, SALES_MAY, SALES_JUN, SALES_JULY, SALES_AUG, SALES_SEPT, SALES_OCT, SALES_NOV, SALES_DEC]
    },

    {
      name: " Target",
      data: [TARGET_SALES_JAN, TARGET_SALES_FEB, TARGET_SALES_MARC, TARGET_SALES_APR, TARGET_SALES_MAY, TARGET_SALES_JUN, TARGET_SALES_JULY, TARGET_SALES_AUG, TARGET_SALES_SEPT, TARGET_SALES_OCT, TARGET_SALES_NOV, TARGET_SALES_DEC]
    },
  ];

  const series = [
    {
      name: "Profit",
      data: [PROFIT_JAN, PROFIT_FEB, PROFIT_MARC, PROFIT_APR, PROFIT_MAY, PROFIT_JUN, PROFIT_JULY, PROFIT_AUG, PROFIT_SEPT, PROFIT_OCT, PROFIT_NOV, PROFIT_DEC]
    },

    // {
    //   name: " Expenses",
    //   data: [10, 50, 90, 20, 100, 90, 70, 50, 30, 95, 35, 15]
    // },

  ];

  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}

          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <div className="table-account">
                <tbody>
                  <tr>
                    <td><div className="table-name">{memberdetails.firstname}</div></td>
                    <td><div className="table-name">{memberdetails.lastname}</div></td>
                  </tr>
                  <tr>
                    <td>Branch:</td>
                    <td>{memberdetails.account}</td>
                  </tr>
                </tbody>
              </div>
            </table>

          </div>
          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <tbody>

                <tr>
                  <td rowSpan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
                  <td><DatePicker className="form-control datePicker-style"
                    selected={startDate}
                    onChange={handleChangeStartDate}
                    name="startDate"
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                  /></td>
                  <td><DatePicker className="form-control datePicker-style"
                    selected={endDate}
                    onChange={handleChangeEndDate}
                    name="endDate"
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                  /></td>
                </tr>
                {isManager ? (<tr>
                  <td>Cashier</td>
                  <td>
                    <div className="col-md-8 form-group">
                      <select className="form-control" onChange={handleChangeoptionCategories} >
                        <option>Select</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div></td>
                </tr>) : (<tr>
                  <td>Cashier</td>
                  <td>
                    <div className="col-md-8 form-group">
                      <select disabled className="form-control" onChange={handleChangeoptionCategories} >
                        <option key={currentUser.username}>{currentUser.username}</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div></td>
                </tr>)}

              </tbody>
            </table>

          </div>

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  <td className="tg-0lax"><Button onClick={() => setLgShow(true)} className="button-tools-red">Add</Button></td>
                </tr>
              </tbody>

            </table>
          </div>


          {/* end card box */}
        </div>

      </div>


      <div className="row">

        <div className="col-lg-5 dashboard dashboard-div-title">

          <div className="col-md-4 dashboard"> <center>Pending Transfer</center> <br /></div>
          <table className="table-home">
            <thead className="scroll-thead">
              <tr>
                <th className="table-home-header-sm">  SOURCE </th>
                <th className="table-home-header-sm">DESTINATION</th>
                <th className="table-home-header-sm">INSTITUTION</th>
                <th className="table-home-header-sm">AMOUNT</th>
                <th className="table-home-header-md">DATE</th>
                <th className="table-home-header-sm">STATUS</th>

              </tr>
            </thead>
            <tbody>
              {tutorials &&
                tutorials.map((tutorial, index) => (
                  <tr key={tutorial.id}>
                    <td>{tutorial.senderCountry}</td>
                    <td>{tutorial.receiverCountry}</td>
                    <td>{tutorial.destinationInstitution}</td>
                    <td>{<CurrencyFormat value={tutorial.total_amount} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />}</td>
                    <td>{tutorial.transferDate}</td>
                    <td>{<span className={"status " + tutorial.transferStatus}>{tutorial.transferStatus}</span>}</td>

                  </tr>
                ))}
            </tbody>
          </table>

        </div>
        <div className="col-md-4 dashboard"> <center>Annual Transfer</center> <br />
          <Chart options={options} height={350} width={700} series={series_sales_target} type="area" />
        </div>
      </div>


      {/* //table2 */}

      <div className="row">

        <div className="col-lg-5 dashboard dashboard-div-title">

          <div className="row">
            <div className="col-md-4">
              <div className="col-md-8 dashboard"> <center> Transfer Localization</center> <br /></div>
              <table className="table-home">
                <thead className="scroll-thead">
                  <tr>
                    <th className="table-home-header-sm">  SOURCE </th>
                    <th className="table-home-header-sm">DESTINATION</th>
                    <th className="table-home-header-sm">AMOUNT</th>


                  </tr>
                </thead>
                <tbody>
                  {transfer_by_country &&
                    transfer_by_country.map((tutorial, index) => (
                      <tr key={tutorial.id}>
                        <td>{tutorial.senderCountry}</td>
                        <td>{tutorial.receiverCountry}</td>
                        <td>{<CurrencyFormat value={tutorial.amount} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />}</td>


                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {/* table2 */}
            <div className="col-md-4 table-home-separator">
              <div className="col-md-8 dashboard"> <center> Agents on duty</center> <br /></div>
              <table className="table-home">
                <thead className="scroll-thead">
                  <tr>
                    <th className="table-home-header-sm">PAIR </th>
                    <th className="table-home-header-sm">PRICE TIME</th>
                    <th className="table-home-header-sm">BUY</th>
                    <th className="table-home-header-sm">SELL</th>


                  </tr>
                </thead>
                <tbody>
                  {transfer_by_country &&
                    transfer_by_country.map((tutorial, index) => (
                      <tr key={tutorial.id}>
                        <td>{tutorial.senderCountry}</td>
                        <td>{tutorial.receiverCountry}</td>
                        <td>{<CurrencyFormat value={tutorial.amount} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />}</td>
                        <td>{<CurrencyFormat value={tutorial.amount} displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => <div>{value}</div>} />}</td>

                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

          </div>

        </div>

        <div className="col-lg-5 dashboard dashboard-div-title">


          <div className="col-md-4 dashboard"> <center> Transfer Exchange </center> <br /></div>
          <table className="table-home">
                <thead className="scroll-thead">
                  <tr>
                    
                    <th className="table-home-header-sm">PAIR</th>
                      <th className="table-home-header-sm"> UPDATE TIME</th>
                      <th className="table-home-header-sm">BUY</th>
                      <th className="table-home-header-sm">SELL</th>
                      <th className="table-home-header-sm"> SOURCE</th>
                      <th className="table-home-header-sm">STATUS</th>


                  </tr>
                </thead>
                <tbody>
                  {transfer_exchange &&
                    transfer_exchange.map((tutorial, index) => (
                      <tr key={tutorial.id}>
                        <td  className="table-home-header-sm">{tutorial.pair}</td>
                          <td className="table-home-header-sm">{tutorial.lastUpdateTime}</td>
                          <td className="table-home-header-sm">{tutorial.buy}</td>
                          <td className="table-home-header-sm">{tutorial.sell}</td>
                          <td className="table-home-header-sm">{tutorial.priceSource}</td>
                          <td className="table-home-header-sm"><span className={"status " + tutorial.status}>{tutorial.status}</span></td>

                      </tr>
                    ))}
                </tbody>
              </table>

        </div>
      </div>
    </>
  );
};