import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { useNavigate, Link, Outlet } from 'react-router-dom';
import AuthService from "../../services/auth.service";

import authService from '../../services/auth.service';
import logo from '../images/logo.png'


const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };



  export default function Login() {

 
    authService.logout();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);

    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    let navigate = useNavigate();

    function handleClickLogin(username, id) {

        if (username === username & id != null) {
            
            navigate('/home', { replace: true });

        } else {
           // console.log("invalid credentials");
        }
        //navigate('/dashboard', { replace: true });
    };


    const handleLogin = (e) => {
        e.preventDefault();

      

        setMessage("");

        if (username == null || username == "") {
            setMessage("Username required.");

        } else if (password == null || password == "") {
            setMessage("Password required.");
        } else {

            AuthService.login(username, password).then((response) => {

                handleClickLogin(response.username, response.id);
    
    
            }).catch((e) => {
    
                    if (e.message == "Request failed with status code 401") {
                        setMessage("Invalid credantials!");
                    } else {
    
                        setMessage("Network Error!");
    
                    }
                });

        }


    };

  



  return (
    <div className="body">

      <div className="login">



        <div className="wrapper fadeInDown">
          <div id="formContent">
            {/* <!-- Tabs Titles --> */}
            <h2 className="active"> Money Transfer </h2>
            

            {/* <!-- Icon --> */}
            <div className="fadeIn first">
               
              <img src={logo} id="icon" alt="User Icon" className="logo-img"/>
              
              
            </div>

            {/* <!-- Login Form --> */}
            <Form className="login-form" onSubmit={handleLogin}>
              <Input 
               type="text"
               id="username" 
               className="fadeIn second" 
               name="username" 
               autoComplete="off"
               placeholder="username" 
               value={username}
               onChange={onChangeUsername}
              validations={[required]} />

              <Input 
               type="password"
               id="password"
               className="fadeIn third"
               name="password" 
               placeholder="password"
               value={password}
              onChange={onChangePassword}
              validations={[required]}
                />
              
              <button type="submit" className="login-button"> <div > <span className="fadeIn fourth"></span>Login</div></button>
             
              {/* <!-- Remind Passowrd --> */}
            <div id="formFooter">
            {message && (
            <div className="form-group">
              <div className="danger-message" role="alert">
                {message}
               
              </div>
            </div>
          )}
           
              
            </div>
            </Form>

           


          </div>

          last update 05-17-2024 18:00
        </div>

      </div>
    </div>
  );
};

