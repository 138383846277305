import React, { useState, useEffect, useRef } from "react";

import * as FaIcons from 'react-icons/fa';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";
import { FcServices, FcCustomerSupport } from 'react-icons/fc';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
import CustomersService from "../../services/customers.service";
import MemberService from "../../services/members.service";

import { useNavigate, Link, Outlet } from 'react-router-dom';

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};




const currentUser = AuthService.getCurrentUser();


  

export default function CustomerNew () {

    let navigate = useNavigate();

   useEffect(() => {



      if (JSON.parse(localStorage.getItem("user")) == null) {

         navigate('/login', { replace: true });
         AuthService.reloadWindow();

      } else {

         let decodedToken = jwt_decode(currentUser.accessToken);
         let currentDate = new Date();
         // JWT exp is in seconds redirect to login
         if (decodedToken.exp * 1000 < currentDate.getTime()) {

            AuthService.logout();
            navigate('/login', { replace: true });
            AuthService.reloadWindow();

         }

      }

   }, []);

    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/

    const initialDataState = {
        id: null,
        fullname: "",
        phone: "",
        contact: "",
        phone: "",
        email: "",
        other: "",
        remark: "",
        username: "",

        published: false
    };

    const [tutorial, setData] = useState(initialDataState);
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");
    const [memberdetails, setMemberDetails] = useState([]);


    const handleinputChange = event => {
        const { name, value } = event.target;
        setData({ ...tutorial, [name]: value });
    };


    const saveData = () => {

        var data = {
            fullname: tutorial.fullname,
            contact: tutorial.contact,
            phone: tutorial.phone,
            email: tutorial.email,
            other: tutorial.other,
            remark: tutorial.remark,
            celebrated_day: tutorial.celebrated_day,
            celebrated_day_description: tutorial.celebrated_day_description,
            username: currentUser.username
        };



        CustomersService.create(data)
            .then(response => {
                setData({
                    id: response.data.id,
                    fullname: response.data.fullname,
                    contact: response.data.contact,
                    phone: response.data.phone,
                    email: response.data.email,
                    other: response.data.other,
                    remark: response.data.remark,
                    celebrated_day:response.data.celebrated_day,
                    celebrated_day_description:response.data.celebrated_day_description,
                    username: response.data.username

                });
                setSubmitted(true);
                setMessage("Data saved successfully.");
                //console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

    };

    const newData = () => {
        setData(initialDataState);
        setSubmitted(false);
        setMessage("");
        //props.history.push("/customers-new/");
        navigate('/customers-new', { replace: true });

    };




    const [tutorials, setDatas] = useState([]);
    const tutorialsRef = useRef();



    const [page, setPage] = useState(1);

    const [pageSize, setPageSize] = useState(100);
    const pageSizes = [200, 300, 500];



    tutorialsRef.current = tutorials;

    const getMemberData = () => {
        setPage(1);

        MemberService.getMemberdetails(currentUser.username)
            .then((response) => {

                setMemberDetails(response.data);


            })
            .catch((e) => {
                console.log(e);
            });

    }



    const retrieveData = () => {
        setPage(1);
        getMemberData();


    };


    useEffect(retrieveData, [page, pageSize]);

    const list = () => {
        //props.history.push("/customers/");
        navigate('/customers', { replace: true });
        
    };

    const add = () => {
        //props.history.push("/customers-new/");
        navigate('/customers-new', { replace: true });
    };


    return (
        <div className='pages'>

            <div className="row">



                {/* cards  */}

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <div className="table-account">
                            <tbody>
                                <tr>
                                    <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><div className="table-name">{memberdetails.firstname}</div></td>
                                    <td><div className="table-name">{memberdetails.lastname}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="table-name">Branch:</div></td>
                                    <td><div className="table-name">{memberdetails.account}</div></td>
                                </tr>

                            </tbody>
                        </div>
                    </table>


                </div>
                <div className="col-md-3 tool-box">

                    <table>
                        <tbody>
                            <tr>
                                <td><span className="page-title-img"><FcCustomerSupport /> </span></td>
                                <td><span className="page-title"> Customer</span></td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>
                            <tr>
                                <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                                <td className="tg-0lax"></td>
                                <td className="tg-0lax"></td>
                            </tr>
                            <tr>
                                <td className="tg-0lax"><button onClick={list} className="button-tools">Refresh</button></td>
                                <td className="tg-0lax"><button onClick={add} className="button-tools">New</button></td>
                            </tr>
                            <tr>
                                <td className="tg-0lax"></td>
                                <td className="tg-0lax"> </td>
                            </tr>
                        </tbody>

                    </table>
                </div>


                {/* end card box */}



                <div className="dataRow">

                    <center>
                        <div className="details-small">

                            <center>
                                <form className="form form-horizontal ">
                                    <div className="form-body">
                                        <div className="row">

                                            <div className="col-md-4">
                                                <label>Full name</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="fullname" className="form-control" name="fullname" placeholder="customer name" onChange={handleinputChange} validations={[required]} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Contact</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="contact" className="form-control" name="contact" placeholder="Contact" onChange={handleinputChange} validations={[required]} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Phone</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="phone" className="form-control" name="phone" placeholder="Phone" onChange={handleinputChange} validations={[required]} />
                                            </div>
                                            <div className="col-md-4">
                                                <label>Email</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="email" id="email" className="form-control" name="email" placeholder="email" onChange={handleinputChange} validations={[required]} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>Celebrated day</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="celebrated_day" className="form-control" name="celebrated_day" placeholder="Celebrated day" onChange={handleinputChange} validations={[required]} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>Celebrated Descrption</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="celebrated_day_description" className="form-control" name="celebrated_day_description" placeholder="Celebrated descrption" onChange={handleinputChange} validations={[required]} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>Other</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="other" className="form-control" name="other" placeholder="Other" onChange={handleinputChange} validations={[required]} />
                                            </div>

                                            <div className="col-md-4">
                                                <label>Remark</label>
                                            </div>
                                            <div className="col-md-8 form-group">
                                                <input type="text" id="remark" className="form-control" name="remark" placeholder="Remark" onChange={handleinputChange} validations={[required]} />
                                            </div>

                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>

                                            <label className="col-md-8">{message}</label>

                                            <div className="col-md-4">
                                                <label> </label>
                                            </div>




                                            <div className="col-md-8 form-group">


                                                {submitted ? (<button onClick={newData}
                                                    className="button-form"><ImPlus /> Add</button>) : (<button onClick={saveData}
                                                        type="button" className="button-tools"><ImFloppyDisk /> Save</button>)}


                                                <button
                                                    type="button" onClick={list} className="button-tools"><ImCancelCircle /> Close</button>


                                            </div>





                                        </div>
                                    </div>
                                </form>

                            </center>



                        </div>
                    </center>


                </div>
            </div>

        </div>
    );
};
