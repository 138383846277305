import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { Modal } from 'react-bootstrap';
import { ImPencil } from "react-icons/im";
import { AiTwotoneDelete } from "react-icons/ai";

import { withRouter } from 'react-router';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import AccessControlOtpService from "../../services/access-control-otp.service"
import SelectOptionService from "../../services/select-options.service";

import { useNavigate, Link, Outlet } from 'react-router-dom';

import PrevisionService from "../../services/prevision.service";


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


const currentUser = AuthService.getCurrentUser();


export default function  PrevisionList() {

  let navigate = useNavigate();
  const nav = useNavigate();


  if (JSON.parse(localStorage.getItem("user")) == null) {

    //return <Redirect to="/login" />;

    navigate('/login', { replace: true });

  }

  let decodedToken = jwt_decode(currentUser.accessToken);
  let currentDate = new Date();
  // JWT exp is in seconds redirect to login
  if (decodedToken.exp * 1000 < currentDate.getTime()) {

    //return <Redirect to="/login" />;

    navigate('/login', { replace: true });

  }

  if (!currentUser.roles.includes("ROLE_ADMIN")) {
    //return <Redirect to="/unauthorized" />;

  }

  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/
  /***************************************************************************************/

  const initialDataState = {
    id: null,
    username: "",
    year: "",
    type: "",
    description: "",
    january: 0,
    february: 0,
    march: 0,
    april: 0,
    may: 0,
    june: 0,
    july: 0,
    augostin: 0,
    september: 0,
    octomber: 0,
    november: 0,
    december: 0,


  };

  const [tutorial, setData] = useState(initialDataState);

  const [currentData, setcurrentData] = useState(initialDataState);
  const [memberdetails, setMemberDetails] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategorySelectedValueType, setOptionCategorySelectedValueType] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [optionCategoryArrayType, setOptionCategoryArrayType] = useState([]);

  const [message, setMessage] = useState("");
  const [username, setUsername] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [messageNewPassword, setMessageNewPassword] = useState("");

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [300, 500, 1000];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });

  };

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);
    setUsername(event.target.value);
  }

  const handleChangeoptionCategoriesType = (event) => {
    setOptionCategorySelectedValueType(event.target.value);

  }

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveData = () => {
    setPage(1);


    const params = getRequestParams(searchTitle, page, pageSize);

    PrevisionService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

      })
      .catch((e) => {
        console.log(e);
      });

    getSelectOption();

  };



  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };



  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {

    setLgShow2(true);
    setMessage("");

    setPage(1);

    setcurrentData(initialDataState);


    PrevisionService.get(id)
      .then(response => {

        setcurrentData(response.data);

      })
      .catch(e => {
        console.log(e);
      });

  };

  const getSelectOption = () => {

    setPage(1);


    SelectOptionService.getAllUsernames()
      .then((response) => {

        setOptionCategoryArray(response.data);

      })
      .catch((e) => {
        console.log(e);
      });

    var category = "PREVISION-TYPE";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArrayType(response.data);

      })
      .catch((e) => {
        console.log(e);
      });


  };



  const remove = (id) => {

    setMessage("");
    setPage(1);

    setcurrentData(initialDataState);


    PrevisionService.remove(id)
      .then(response => {

        refreshList();

        setMessage(response.data);


      })
      .catch(e => {
        console.log(e);
      });

  };


  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);

  const registration = () => {
    //props.history.push("/registration/");
  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const updateData = () => {


    setPage(1);

    setMessage("");

    console.log("=====270====" + currentData.id);

    PrevisionService.update(currentData.id, currentData)
      .then(response => {

        setMessage(response.data);
        refreshList();
      })
      .catch(e => {
        console.log(e);
      });


  };

  const updateDataReset = () => {


    setPage(1);

    setMessage("");

    console.log("=====270====" + currentData.id);

    PrevisionService.updateReset(currentData.id)
      .then(response => {

        setMessage(response.data);
        refreshList();
      })
      .catch(e => {
        console.log(e);
      });



  };

  const save = () => {

    setMessage("");

     if (tutorial.type === null || tutorial.type === "") {
      setMessage("Type is missing!");
    }else if (tutorial.year === null || tutorial.year === "") {
      setMessage("Year is missing!");
    }

    else {

      var data = {
        id: tutorial.id,
        year: tutorial.year,
        description: tutorial.description,
        type: tutorial.type,
        january: tutorial.january,
        february: tutorial.february,
        march: tutorial.march,
        april: tutorial.april,
        may: tutorial.may,
        june: tutorial.june,
        july: tutorial.july,
        augostin: tutorial.augostin,
        september: tutorial.september,
        octomber: tutorial.octomber,
        november: tutorial.november,
        december: tutorial.december,
        username: currentUser.username
      }

     // console.log(data);

      PrevisionService.create(data)
        .then(response => {

          
            setMessage(response.data);
            refreshList();
            newData();
         


        })
        .catch(e => {
          console.log(e);
        });

    }



  }

  const newData = () => {
    setSubmitted(true);
   
    setData(initialDataState);

  }

  return (
    <>
      <div className='pages'>

        <div className="row">



          {/* cards  */}

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
                <tr>
                  <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>{memberdetails.firstname}</td>
                  <td>{memberdetails.lastname}</td>
                </tr>
                <tr>
                  <td>Branch:</td>
                  <td>{memberdetails.account}</td>
                </tr>
                <tr>
                  <td colSpan="2"> <input type="text" value={searchTitle}
                    onChange={onChangeSearchTitle} placeholder="Search" className="search-input"></input> </td>
                  <td><button onClick={findByTitle} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td>
                </tr>
              </tbody>
            </table>



          </div>

          <div className="col-md-3 tool-box">

            <table className=" table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  <td className="tg-0lax"><button onClick={() => setLgShow(true)} className="button-tools">New</button></td>
                </tr>
                <tr>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
              </tbody>
            </table>
          </div>


          {/* end card box */}



          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>

              <div className="col-md-3 table-title">  PREVISION </div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>



            </div>
            <div className="details">

              <div className="table-wrap">

                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>
                      <th>ID</th>
                      <th>USER</th>
                      <th>YEAR</th>
                      <th>TYPE</th>
                      <th>DESCRIPTION</th>
                      <th>JAN</th>
                      <th>FEB</th>
                      <th>MARC</th>
                      <th>APR</th>
                      <th>MAY</th>
                      <th>JUN</th>
                      <th>JULY</th>
                      <th>AUG</th>
                      <th>SEPT</th>
                      <th>OCT</th>
                      <th>NOV</th>
                      <th>DEC</th>
                      <th>Action</th>
                      

                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.username}</td>
                          <td>{tutorial.year}</td>
                          <td>{tutorial.type}</td>
                          <td>{tutorial.description}</td>
                          <td>{tutorial.january}</td>
                          <td>{tutorial.february}</td>
                          <td>{tutorial.march}</td>
                          <td>{tutorial.april}</td>
                          <td>{tutorial.may}</td>
                          <td>{tutorial.june}</td>
                          <td>{tutorial.july}</td>
                          <td>{tutorial.augostin}</td>
                          <td>{tutorial.september}</td>
                          <td>{tutorial.octomber}</td>
                          <td>{tutorial.november}</td>
                          <td>{tutorial.december}</td>
                          <td><div className="row">

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => openObject(tutorial.id)}
                            > <ImPencil />
                            </button>

                            <button
                              type="button" className="col-md-1 table-buttons"
                              onClick={() => remove(tutorial.id)}
                            > <AiTwotoneDelete />
                            </button>



                          </div></td>
                        </tr>

                      ))}
                  </tbody>

                </table>

              </div>

            </div>

          </div>
        </div>

      </div>

      <Modal
        size="lg"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Prevision

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>

              <div className="details-sm">

                <form className="form form-horizontal ">
                  <div className="form-body">
                    <div className="row">

                      
                      <div className="col-md-4">
                        <label>Year</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="year" className="form-control" name="year" placeholder="Year" onChange={handleInputChange} validations={[required]} value={tutorial.year} />
                      </div>

                      <div className="col-md-4">
                        <label>TYPE</label>
                      </div>
                      <div className="col-md-8 form-group">                        
                        <select className="form-control select-option" id="type" name="type" onChange={handleInputChange} >
                          <option>Select</option>
                          {optionCategoryArrayType.map((optionCategory) => (
                            <option key={optionCategory.value}>{optionCategory.value}</option>
                          ))}

                        </select>
                      </div>
                      <div className="col-md-4">
                        <label>Description</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="description" className="form-control" name="description" placeholder="description" onChange={handleInputChange} validations={[required]} value={tutorial.description} />
                      </div>

                      <div className="col-md-4">
                        <label>Jan</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="january" className="form-control" name="january" placeholder="january" onChange={handleInputChange} validations={[required]} value={tutorial.january} />
                      </div>

                      <div className="col-md-4">
                        <label>Feb</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="february" className="form-control" name="february" placeholder="february" onChange={handleInputChange} validations={[required]} value={tutorial.february} />
                      </div>
                      <div className="col-md-4">
                        <label>Marc</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="march" className="form-control" name="march" placeholder="march" onChange={handleInputChange} validations={[required]} value={tutorial.march} />
                      </div>

                      <div className="col-md-4">
                        <label>April</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="april" className="form-control" name="april" placeholder="april" onChange={handleInputChange} validations={[required]} value={tutorial.april} />
                      </div>
                      
                      <div className="col-md-4">
                        <label>May</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="may" className="form-control" name="may" placeholder="may" onChange={handleInputChange} validations={[required]} value={tutorial.may} />
                      </div>

                      <div className="col-md-4">
                        <label>JUne</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="june" className="form-control" name="june" placeholder="june" onChange={handleInputChange} validations={[required]} value={tutorial.june} />
                      </div>

                      <div className="col-md-4">
                        <label>july</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="july" className="form-control" name="july" placeholder="july" onChange={handleInputChange} validations={[required]} value={tutorial.july} />
                      </div>

                      <div className="col-md-4">
                        <label>Aug</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="augostin" className="form-control" name="augostin" placeholder="Aug" onChange={handleInputChange} validations={[required]} value={tutorial.augostin} />
                      </div>

                      <div className="col-md-4">
                        <label>Sept</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="september" className="form-control" name="september" placeholder="September" onChange={handleInputChange} validations={[required]} value={tutorial.september} />
                      </div>

                      <div className="col-md-4">
                        <label>Oct</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="octomber" className="form-control" name="octomber" placeholder="octomber" onChange={handleInputChange} validations={[required]} value={tutorial.octomber} />
                      </div>

                      <div className="col-md-4">
                        <label>Nov</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="november" className="form-control" name="november" placeholder="november" onChange={handleInputChange} validations={[required]} value={tutorial.november} />
                      </div>

                      <div className="col-md-4">
                        <label>Dec</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="december" className="form-control" name="december" placeholder="december" onChange={handleInputChange} validations={[required]} value={tutorial.december} />
                      </div>


                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <label className="col-md-8">{message}</label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>


                      <div className="col-md-8 form-group">

                        {submitted ? (<button onClick={newData}
                          type="button" className="button-form"> Add</button>) : (<button onClick={save}
                            type="button" className="button-form"> Save</button>)}



                        <button onClick={() => setLgShow(false)}
                          type="button" className="button-form"> Close</button>
                      </div>

                    </div>
                  </div>
                </form>


              </div>

            </center>





          </div>


        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit Prevision

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">

          <div className="dataRow">

            <center>

              <div className="details-sm">

                <form className="form form-horizontal ">
                  <div className="form-body">
                    <div className="row">

                      
                      <div className="col-md-4">
                        <label>Year</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="year" className="form-control" name="year" placeholder="Year" onChange={handleInputChangeEdit} validations={[required]} value={currentData.year} />
                      </div>
                     
                      <div className="col-md-4">
                        <label>TYPE</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="type" className="form-control" name="type" placeholder="Type" onChange={handleInputChangeEdit} validations={[required]} value={currentData.type} />
                      </div>

                      <div className="col-md-4">
                        <label>Description</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input  type="text" id="description" className="form-control" name="description" placeholder="Description" onChange={handleInputChangeEdit} validations={[required]} value={currentData.description} />
                      </div>

                      <div className="col-md-4">
                        <label>Jan</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input  type="text" id="january" className="form-control" name="january" placeholder="January" onChange={handleInputChangeEdit} validations={[required]} value={currentData.january} />
                      </div>

                      <div className="col-md-4">
                        <label>Feb</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input  type="text" id="february" className="form-control" name="february" placeholder="February" onChange={handleInputChangeEdit} validations={[required]} value={currentData.february} />
                      </div>

                      <div className="col-md-4">
                        <label>Marc</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input  type="text" id="march" className="form-control" name="march" placeholder="March" onChange={handleInputChangeEdit} validations={[required]} value={currentData.march} />
                      </div>

                      <div className="col-md-4">
                        <label>april</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input  type="text" id="april" className="form-control" name="april" placeholder="April" onChange={handleInputChangeEdit} validations={[required]} value={currentData.april} />
                      </div>

                      <div className="col-md-4">
                        <label>May</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input  type="text" id="may" className="form-control" name="may" placeholder="May" onChange={handleInputChangeEdit} validations={[required]} value={currentData.may} />
                      </div>

                      <div className="col-md-4">
                        <label>JUne</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input  type="text" id="june" className="form-control" name="june" placeholder="June" onChange={handleInputChangeEdit} validations={[required]} value={currentData.june} />
                      </div>

                      <div className="col-md-4">
                        <label>July</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input  type="text" id="july" className="form-control" name="july" placeholder="july" onChange={handleInputChangeEdit} validations={[required]} value={currentData.july} />
                      </div>

                      <div className="col-md-4">
                        <label>Aug</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input  type="text" id="augostin" className="form-control" name="augostin" placeholder="Aug" onChange={handleInputChangeEdit} validations={[required]} value={currentData.augostin} />
                      </div>

                      <div className="col-md-4">
                        <label>Sept</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input  type="text" id="september" className="form-control" name="september" placeholder="Sept" onChange={handleInputChangeEdit} validations={[required]} value={currentData.september} />
                      </div>

                      <div className="col-md-4">
                        <label>Oct</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input  type="text" id="octomber" className="form-control" name="octomber" placeholder="Oct" onChange={handleInputChangeEdit} validations={[required]} value={currentData.octomber} />
                      </div>

                      <div className="col-md-4">
                        <label>Nov</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input  type="text" id="november" className="form-control" name="november" placeholder="Nov" onChange={handleInputChangeEdit} validations={[required]} value={currentData.november} />
                      </div>

                      <div className="col-md-4">
                        <label>Dec</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input  type="text" id="december" className="form-control" name="december" placeholder="Dec" onChange={handleInputChangeEdit} validations={[required]} value={currentData.december} />
                      </div>

                    
                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <label className="col-md-8">{message}</label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <div className="col-md-8 form-group">
                        {/* <button onClick={updateData}
                          type="button" disabled className="button-form"> Update</button> */}


                        <button onClick={updateData}
                          type="button" className="button-form"> Update</button>

                        <button onClick={() => setLgShow2(false)}
                          type="button" className="button-form"> Close</button>
                      </div>

                    </div>
                  </div>
                </form>


              </div>

            </center>





          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};
