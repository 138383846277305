import React, { useState, useRef,useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { GiTimeBomb } from 'react-icons/gi';
import { AiTwotoneEdit } from 'react-icons/ai';

import { withRouter } from 'react-router';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
import VideService from "../../services/vide.service";
import SelectOptionService from "../../services/select-options.service";
 import MemberService from "../../services/members.service";
 import { useNavigate } from "react-router-dom";




const currentUser = AuthService.getCurrentUser();


export default function VideListLevel () {

  let navigate = useNavigate();

  useEffect(() => {



      if (JSON.parse(localStorage.getItem("user")) == null) {

          //redirectToLogin();

          navigate('/login', { replace: true });

      } else {

          let decodedToken = jwt_decode(currentUser.accessToken);
          let currentDate = new Date();
          // JWT exp is in seconds redirect to login
          if (decodedToken.exp * 1000 < currentDate.getTime()) {

              AuthService.logout();
              navigate('/login', { replace: true });

          }

      }

  }, []);

const initialDataState = {
    id: null,
    stand: "",
    date1: "",
    pid: "",
    product: "",
    qty_in: "",
    stockkeeper: "",
    code: "",

    size: "",
    color: "",
    qty_out: "",
    qty_transfer_in: "",
    qty_transfer_out: "",
    qty_damage: "",
    stand_destination: "",
    stand_source: "",
    description: "",
    comment: "",
    transfer_type: "",




    username: "",
    
    published: false,
     
  };



  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");

  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray,setOptionCategoryArray]= useState([]);

  const [optionCategorySelectedValueTransfer, setOptionCategorySelectedValueTransfer] = useState("");
  const [optionCategoryArrayTransfer,setOptionCategoryArrayTransfer]= useState([]);
  const [memberdetails, setMemberDetails] = useState([]);
    
  const handleChangeoptionCategories = (event) =>{
    setOptionCategorySelectedValue(event.target.value);

    retrieveDataBystock();
    
  }

  const handleChangeoptionCategoriesTransfer = (event) =>{
    setOptionCategorySelectedValueTransfer(event.target.value);
    
  }
  

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  

  const newData = () => {
    setData(initialDataState);
    setSearchedProductDetails(initialDataState);
    setSubmitted(false);
    setMessage("");
  };

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [searchedProductDetails, setSearchedProductDetails] = useState("");
  const tutorialsRef = useRef();

  

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);

  

  const pageSizes = [100, 500, 1000];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const onChangeSearchProduct = (e) => {
    const searchProduct = e.target.value;
    setSearchProduct(searchProduct);
     
  };

   

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };

  const retrieveData = () => {

    

    const params = getRequestParams(searchTitle, page, pageSize);

    VideService.getAllStockLevel(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
      getSelectOption();
      getSelectOptionTransfer();
      getMemberData();
  };

  const getMemberData=()=>{

        MemberService.getMemberdetails(currentUser.username)
        .then((response) => {
        
            setMemberDetails(response.data);
             
            
        })
        .catch((e) => {
            console.log(e);
        });

    }

  const retrieveDataBystock = () => {

    

    const params = getRequestParams(optionCategorySelectedValue, page, pageSize);

    VideService.getAllStockLevelBystock(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
      getSelectOption();
      getSelectOptionTransfer();
  };

  const getSelectOption =()=>{
    var category="stock-name";
    SelectOptionService.getAllSelectedOptions(category)
    .then((response) => {
      
      setOptionCategoryArray(response.data);
      setOptionCategoryArrayTransfer(response.data);
       
      
      
    })
    .catch((e) => {
      console.log(e);
    });
  };

  const getSelectOptionTransfer =()=>{
    var category="Stock-transfer-type";
    SelectOptionService.getAllSelectedOptionsTransferType(category)
    .then((response) => {
      
      
      setOptionCategoryArrayTransfer(response.data);
      
      
      
    })
    .catch((e) => {
      console.log(e);
    });
  };

  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    retrieveData();
  };

  

  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {
    setcurrentData(initialDataState);
    // props.history.push("/vide-level/" + id);
  };

  

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const add=()=>{
    // props.history.push("/vide-new");
  }

  const list=()=>{
    // props.history.push("/vide");
  }

  const stockLevel=()=>{
    // props.history.push("/vide-level");
  }


  return (
    <div className='pages'>

      <div className="row">



        {/* cards  */}

        <div className="col-md-3 tool-box">

          <table className="table table-tools">
            <tr>
              <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>{memberdetails.firstname}</td>
              <td>{memberdetails.lastname}</td>
            </tr>
            <tr>
              <td>{memberdetails.account}</td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2"> <input onChange={onChangeSearchTitle} id="customer" type="text" name="customer" placeholder="Stock name" className="search-input" />  </td>
              <td><button onClick={findByTitle} className="button-tools"> <span className="table-tools-img-search"><FaIcons.FaSearch /></span>Search</button></td>
            </tr>
          </table>


        </div>
        <div className="col-md-3 tool-box">
          <table className="table table-tools">

            <tr>
              <td rowspan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
              <td><div className="col-md-8 form-group">
                    <select className="form-control select-option" onChange={handleChangeoptionCategories} >
                      <option>Select</option>
                      {optionCategoryArray.map((optionCategory) => (
                        <option value={optionCategory.value}>{optionCategory.value}</option>
                      ))}

                    </select>
                  </div></td>
              <td> <button onClick={retrieveDataBystock}  className="button-tools">OK</button> </td>
            </tr>
            <tr>
              <td></td>
              <td ></td>
            </tr>

          </table>




        </div>

        <div className="col-md-3 tool-box">

          <table className="table table-tools">
          <tr>
              <td class="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
              <td class="tg-0lax"><button onClick={add} className="button-tools-red">New</button></td>
              <td class="tg-0lax"><button onClick={stockLevel} className="button-tools">Actual</button></td>
            </tr>
            <tr>
            <td class="tg-0lax"></td>
            </tr>
          </table>
        </div>


        {/* end card box */}



        <div className="dataRow">
          <div className="row pagination-box">

            <div className="col-md-3 pagination-box">{"Items per Page : "}
              <select onChange={handlePageSizeChange} value={pageSize}>
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select></div>

            <div className="col-md-3 table-title">  ACTUAL VIDE STOCK </div>

            <div className="col-md-4"><Pagination

              count={count}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />

            </div>



          </div>
          <div className="details">

         
            <div className="table-wrap">
              <table className="table ">
                <thead className="scroll-thead">
                  <tr>

                    <th>ID</th>
                    <th>STAND</th>
                    <th>PID</th>
                    <th>CODE</th>
                    <th>PRODUCT</th>
                    <th>SIZE</th>
                    <th>COLOR</th>
                    <th>CURRENT QTY</th>
                    <th>STATUS</th>
                    <th>Update</th>

                  </tr>
                </thead>
                <tbody>
                  {tutorials &&
                    tutorials.map((tutorial, index) => (

                      <tr>
                        <td>{tutorial.id}</td>
                        <td>{tutorial.stockname}</td>
                        <td>{tutorial.pid}</td>
                        <td>{tutorial.code}</td>
                        <td>{tutorial.name}</td>
                        <td>{tutorial.size}</td>
                        <td>{tutorial.color}</td>
                        <td>{tutorial.current_stock_qty}</td>
                        <td><span className={"status " + tutorial.status}>{tutorial.status}</span></td>
                        <td><div className="table-action-button">

                          <button
                            type="button" className="btn"
                            onClick={() => openObject(tutorial.id)}
                          > <AiTwotoneEdit />
                          </button>

                        </div></td>
                      </tr>

                    ))}
                </tbody>
              </table>
            </div>

          </div>

        </div>
      </div>

    </div>
  );
};
