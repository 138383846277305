import http from "./http-common";
import authHeader from "./auth-header";

const getAll = (params) => {
  return http.get("/selectoptions", { params,headers: authHeader() });
};

const getAllSelectedOptions = (category) => {
    return http.get(`/selectedoptions/${category}`, {headers: authHeader() });
};

const getAllSelectedOptionsWithData = (data) => {
  return http.post("/selectoptionsdata", data,{ headers: authHeader() });
};

  const getAllSelectedOptionsTransferType = (category) => {
    return http.get(`/selecttransfertype/${category}`, {headers: authHeader() });
  };

const getAllCategory = () => {
    return http.get("/selectcategory", { headers: authHeader() });
  };

const get = (id) => {
  return http.get(`/selectoptions/${id}`,{ headers: authHeader() });
};

const getSallerDetails = (saller) => {
  return http.get(`/selectoptionsaller/${saller}`,{ headers: authHeader() });
};

const create = (data) => {
   
  return http.post("/selectoptions", data,{ headers: authHeader() });
};

const update = (id, data) => {
  return http.put(`/selectoptions/${id}`, data,{ headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/selectoptions/${id}`,{ headers: authHeader()});
};


const findByTitle = (title) => {
  return http.get(`/selectoptions?code=${title}`);
};

const getSelectionSallersByBranch = (branch) => {

  console.log(branch);
 
  return http.get(`/selectedoptionsallers/${branch}`, { headers: authHeader() });
};

const getAllUsernames = () => {
  return http.get("/selectallusernames", { headers: authHeader() });
};

const SelectOptionService = {
  getAll,
  get,
  create,
  update,
  remove,
  findByTitle,
  getAllCategory,
  getAllSelectedOptions,
  getAllSelectedOptionsTransferType,
  getSelectionSallersByBranch,
  getSallerDetails,
  getAllUsernames,
  getAllSelectedOptionsWithData
};

export default SelectOptionService;
