import React, { useState, useRef, useEffect } from "react";

import * as FaIcons from 'react-icons/fa';
import { FcServices } from 'react-icons/fc';
import { AiFillFolderOpen } from 'react-icons/ai';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";

import { format } from 'date-fns';
import { List } from "@material-ui/core";



const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default function  NavbarLeft () {

  let navigate = useNavigate();

  const initialDataState = {
    id: null,
    stand: "",
    date1: "",
    pid: "",
    product: "",
    qty_in: "",
    stockkeeper: "",
    code: "",
    size: "",
    color: "",
    qty_out: "",
    qty_transfer_in: "",
    qty_transfer_out: "",
    qty_damage: "",
    stand_destination: "",
    stand_source: "",
    description: "",
    comment: "",
    transfer_type: "",
    stock_type: "",
    username: "",
    stockEntryId: "",
    location: "",

    published: false,

  };



  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [stockEntryId, setStockEntryId] = useState("");

  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);

  const [optionCategorySelectedValueTransfer, setOptionCategorySelectedValueTransfer] = useState("");
  const [optionCategorySelectedValueTransferDestination, setOptionCategorySelectedValueTransferDestination] = useState("");
  const [optionCategorySelectedValueTransferSource, setOptionCategorySelectedValueTransferSource] = useState("");
  const [optionCategoryArrayTransfer, setOptionCategoryArrayTransfer] = useState([]);

  const [memberdetails, setMemberDetails] = useState([]);

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);

  }



  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };


  const newData = () => {
    setPage(1);
    setData(initialDataState);

    setSubmitted(false);
    setMessage("");
  };

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();



  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);



  const pageSizes = [100, 500, 1000];

  tutorialsRef.current = tutorials;

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };





  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };





  // useEffect(retrieveData, [page, pageSize]);



  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const home = () => {
    
    navigate('/home', { replace: true });
  }

  const reports = () => {
    
    navigate('/reports', { replace: true });
  }
  const customers = () => {
    
    navigate('/customers', { replace: true });
  }
  const products = () => {
    
    navigate('/products', { replace: true });
  }

  const orders = () => {
   
    navigate('/sales-pending-order', { replace: true });
  }
  const salesList = () => {
     
    navigate('/sales-list', { replace: true });
  }
  const exit = () => {
    
    navigate('/delivery-pending', { replace: true });
  }
  const expenses = () => {
     
    navigate('/expense-list', { replace: true });
  }

  const stock = () => {
     
    navigate('/stock-in', { replace: true });
  }
  const payments = () => {
   
    navigate('/payments', { replace: true });
  }
  const cashing = () => {
    
    navigate('/cashing', { replace: true });
  }
  const credit = () => {
     
    navigate('/sales-pending-order-deposit', { replace: true });
  }
  const users = () => {
    
    navigate('/users', { replace: true });
  }
  const settings = () => {
     
    navigate('/settings', { replace: true });
  }
  const stockkeeperPendingRequest = () => {
    
    navigate('/stockkeeper-pending-request', { replace: true });
  }
  const access = () => {
    
    navigate('/access', { replace: true });
  }
  const otpAccessOtp = () => {
     
    navigate('/access-otp', { replace: true });
  }

  const scanner = () => {
    
    navigate('/scanner', { replace: true });
  }


  const dashboard = () => {
    
    navigate('/dashboard', { replace: true });
  }

  const prevision = () => {
    
    navigate('/prevision', { replace: true });
  }

  const transfer = () => {
    
    navigate('/transfer', { replace: true });
  }

  const transferCommission = () => {
    
    navigate('/transfer-commission', { replace: true });
  }

  const transferDetails = () => {
    
    navigate('/transfer-details', { replace: true });
  }

  const transferExchange = () => {
    
    navigate('/transfer-exchange', { replace: true });
  }

  return (
    <div className='navbar-left'>

      <div className="sidebar-header1">

      </div>

      <div onClick={home} className="row-menu">Home</div>
      {/* <div onClick={reports} className="row-menu">Reports</div>
      <div onClick={cashing} className="row-menu"> Cashing</div> */}
      <div onClick={customers} className="row-menu">Customers</div>
      <div onClick={products} className="row-menu">Products</div>
      <div onClick={expenses} className="row-menu"> Expenses</div>
      
     
      <div onClick={users} className="row-menu"> users</div>
      <div onClick={settings} className="row-menu"> Settings</div>
      <div onClick={otpAccessOtp} className="row-menu">Otp</div>
     
      <div onClick={dashboard} className="row-menu">Dashboard</div>
      <div onClick={prevision} className="row-menu">Prevision</div>
      <div onClick={transfer} className="row-menu">Transfer</div>      
      <div onClick={transferDetails} className="row-menu">TransferDetails</div>
      <div onClick={transferCommission} className="row-menu">Commissions</div>
      <div onClick={transferExchange} className="row-menu">Exchange</div>
      



    </div>
  );
};
