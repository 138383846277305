import axios from "axios";

export default axios.create({

  baseURL: "https://qpay.anandaapp.com:9117/api",
//  baseURL: "http://localhost:9117/api",


  headers: {
    "Content-type": "application/json"
  }
  
});
