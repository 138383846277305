import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import { ImExit, ImProfile } from 'react-icons/im';
import { Link } from 'react-router-dom';
import NavbarLeft from './NavbarLeft';
import './Navbar.css';
import { Modal } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import AuthService from '../services/auth.service';
import MemberService from '../services/members.service';
import {RiAccountCircleFill} from 'react-icons/ri'

//import logo from './images/logo.png';
import logo from './images/logo.jpeg'

const currentUser = AuthService.getCurrentUser();

export default function Navbar() {
  let navigate = useNavigate();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user")) == null) {
      navigate('/login', { replace: true });
    } else {

      let decodedToken = jwt_decode(currentUser.accessToken);
      let currentDate = new Date();
      // JWT exp is in seconds redirect to login
      if (decodedToken.exp * 1000 < currentDate.getTime()) {

        AuthService.logout();
        navigate('/login', { replace: true });
      }
    }

  }, []);

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const [conn, setConn] = useState(true);

  const initialDataState = {
    id: null,
    email: "",
    firstname: "",
    lastname: "",
    account: "",
    phone: "",
    joiningdate: "",
    roleName: "",
    pssw1: "",
    pssw2: "",
    pssw3: "",
  };

  const [tutorial, setData] = useState(initialDataState);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");

  const logOut = () => {
    setLgShow2(false);
    AuthService.logout();
    setConn(false);
    navigate('/login', { replace: true });

  }

  const [lgShow2, setLgShow2] = useState(false);
  const [lgShow3, setLgShow3] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const pageSizes = [300, 500, 1000]

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const getMemberData = () => {
    setPage(1);
  }

  const openObject = (id) => {
    setLgShow(true);
    setLgShow2(false);
    setPage(1);
    setcurrentData(initialDataState);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {
        MemberService.get(response.data.other)
          .then(response => {
            setcurrentData(response.data);
          })
          .catch(e => {
            console.log(e);
          });

      })
      .catch((e) => {
        console.log(e);
      });
  };

  const resetPassword = () => {
    setLgShow(false);
    setLgShow3(true);
  }

  const changePassword = () => {
    setMessage("");

    var data = {
      text1: tutorial.pssw1,
      text2: tutorial.pssw2,
      text3: tutorial.pssw3,
      text4: currentUser.username,
    }

    if (data.text2 === null || data.text2 === "") {
      setMessage("New password is empty!");
    }
    else if (data.text2.length < 6 || data.text2.length > 40) {
      setMessage(" The password must be between 6 and 40 characters.");

    } else if (data.text2 === null || data.text2 === "") {
      setMessage("New password is empty!");

    }
    else if (data.text3 === null || data.text3 === "") {
      setMessage(" Confirm password.");

    }

    else if (data.text2 !== data.text3) {
      setMessage(" The passwords not matching!");
    } else {

      MemberService.changePassword(data)
        .then((response) => {

          console.log(response.data);

        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  const home = () => {
    navigate('/home', { replace: true });
  }

  const salesPendingOrder = () => {
    navigate('/sales-pending-order', { replace: true });
  }

  const retrieveData = () => {
    setPage(1);
    window.location.reload();

  };

  // useEffect(retrieveData,[page, pageSize]);

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar'>
          {conn ? (<><Link to='#' className='menu-bars'>
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>

            <Link to='#' className='menu-bars'>
              <FaIcons.FaHome onClick={home} />
            </Link>

            <Link to='#' className='menu-bars'>
              <FaIcons.FaShoppingCart onClick={salesPendingOrder} />
            </Link>

            <div className="title">ANANDA </div>

            <div className="profile"><span onClick={() => setLgShow2(true)} className="img"> <RiAccountCircleFill /> </span></div>
          </>
          ) : ("")}

        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
                <span className="icon icon-img"><img src={logo} id="icon" className="page-icon" alt="" /> </span>
              </Link>
            </li>
            {/* <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
              <span className="icon icon-img"><img src={logo} id="icon" className="page-icon" alt=""/> </span>
              </Link>
            </li> */}
            {/* {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    <span className="nav-icon nav-icon-img nav-span">{item.icon} </span>
                    <span className="nav-span">{item.title}</span>
                  </Link>
                </li>
              );
            })} */}
            <NavbarLeft />
          </ul>
        </nav>
      </IconContext.Provider>

      <Modal
        size="sm"
        // backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">
          <div className="dataRow">
            <center>
              <div className="details-sm-2">
                <center>
                  <div className="row"><button onClick={logOut} className="button-profile">Logout <span className="img"> <ImExit /> </span> </button></div>
                  <div className="row"><button onClick={() => openObject(1)} className="button-profile">Profile <span className="img"> <ImProfile /> </span></button></div>
                </center>
              </div>
            </center>
          </div>
        </Modal.Body>
      </Modal>

      {/* profile */}

      <Modal
        size="md"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Profile

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">
          <div className="dataRow">
            <center>
              <div className="details-sm">
                <form className="form form-horizontal ">
                  <div className="form-body">
                    <div className="row">
                      <div className="col-md-4">
                        <label>FIrst Name</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="text" id="firstname" className="form-control" name="firstname" placeholder="Firstname" onChange={handleInputChangeEdit} validations={[required]} value={currentData.firstname} />
                      </div>
                      <div className="col-md-4">
                        <label>Lastname</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="text" id="lastname" className="form-control" name="lastname" placeholder="Lastname" onChange={handleInputChangeEdit} validations={[required]} value={currentData.lastname} />
                      </div>
                      <div className="col-md-4">
                        <label>Phone</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="text" id="phone" className="form-control" name="phone" placeholder="Phone" onChange={handleInputChangeEdit} validations={[required]} value={currentData.phone} />
                      </div>
                      <div className="col-md-4">
                        <label>Account</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input readOnly type="text" id="account" className="form-control" name="account" placeholder="Account" onChange={handleInputChangeEdit} validations={[required]} value={currentData.account} />
                      </div>
                      <div className="col-md-4">
                        <label>Email</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="email" id="email" className="form-control" readOnly name="email" placeholder="Email" onChange={handleInputChangeEdit} validations={[required]} value={currentData.email} />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <label className="col-md-8"></label>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <div className="col-md-8 form-group">


                        <button onClick={() => setLgShow(false)}
                          type="button" className="button-form"> Close</button>

                        <button onClick={resetPassword}
                          type="button" className="button-form"> Reset</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </center>
          </div>
        </Modal.Body>
      </Modal>

      {/* password */}

      <Modal
        size="md"
        backdrop="static"
        show={lgShow3}
        onHide={() => setLgShow3(false)}
        aria-labelledby="example-modal-sizes-title-lg"

      >

        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Reset password

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">
          <div className="dataRow">
            <center>
              <div className="details-sm">
                <form className="form form-horizontal ">
                  <div className="form-body">
                    <div className="row">
                      <div className="col-md-4">
                        <label>New Password</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="password" id="pssw2" className="form-control" name="pssw2" placeholder="New password" onChange={handleInputChange} validations={[required]} />
                      </div>

                      <div className="col-md-4">
                        <label>Confirm Password</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="password" id="pssw3" className="form-control" name="pssw3" placeholder="Confirm" onChange={handleInputChange} validations={[required]} />
                      </div>


                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <label className="col-md-8 danger-message"> {message} </label>

                      <div className="col-md-4">
                        <label>   </label>
                      </div>
                      <div className="col-md-8 form-group">
                        <button onClick={() => setLgShow3(false)}
                          type="button" className="button-form"> Close</button>

                        <button onClick={changePassword}
                          type="button" className="button-form"> Reset</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </center>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
