import http from "./http-common";
import authHeader from "./auth-header";

const getAll = (params) => {
  return http.get("/accessotplist", {params, headers: authHeader() });
};

const get = (id) => {
  return http.get(`/accessotplist/${id}`,{ headers: authHeader() });
};

const checkIfAuthorizationCodeExist = (code) => {
  return http.get(`/accessotpauthorization/${code}`,{ headers: authHeader() });
};

const checkIfAuthorizationCodeExistWithType = (data) => {
  return http.post(`/accessotpauthorizationwithtype`,data,{ headers: authHeader() });
};

const checkIfDeleteCodeExist = (code) => {
  return http.get(`/accessotpdelete/${code}`,{ headers: authHeader() });
};


const create = (data) => {
   
  return http.post("/newaccessotp", data,{ headers: authHeader() });
};

const update = (id, data) => {
  return http.put(`/accessotplist/${id}`, data,{ headers: authHeader() });
};

const updateReset = (id) => {
  return http.get(`/resetotp/${id}`, { headers: authHeader() });
};

const remove = (id) => {
  return http.delete(`/accessotplist/${id}`,{ headers: authHeader()});
};


const findByTitle = (title) => {
  return http.get(`/accessotp?code=${title}`);
};

const AccessControlOtpService = {
  getAll,
  get,
  create,
  update,
  updateReset,
  remove,
  findByTitle,
  checkIfAuthorizationCodeExist,
  checkIfAuthorizationCodeExistWithType,
  checkIfDeleteCodeExist,
  
};

export default AccessControlOtpService;
