import http from "./http-common";
import authHeader from "./auth-header";



const get = (id) => {
  return http.get(`/organizationdetails/${id}`,{ headers: authHeader() });
};

 

const OrganizationDetailsService = {
 
  get
   
};

export default OrganizationDetailsService;
