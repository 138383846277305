import React, { useState, useEffect, useRef } from "react";

import * as FaIcons from 'react-icons/fa';
import { ImFloppyDisk, ImCancelCircle, ImPlus, Imsend } from "react-icons/im";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { FcServices, FcCustomerSupport } from 'react-icons/fc';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
import CustomersService from "../../services/customers.service";
import MemberService from "../../services/members.service";

import { useNavigate, Link, Outlet } from 'react-router-dom';
import TransferService from "../../services/transfer.service";

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};




const currentUser = AuthService.getCurrentUser();




export default function TransferNew() {

    let navigate = useNavigate();

    useEffect(() => {



        if (JSON.parse(localStorage.getItem("user")) == null) {

            navigate('/login', { replace: true });
            AuthService.reloadWindow();

        } else {

            let decodedToken = jwt_decode(currentUser.accessToken);
            let currentDate = new Date();
            // JWT exp is in seconds redirect to login
            if (decodedToken.exp * 1000 < currentDate.getTime()) {

                AuthService.logout();
                navigate('/login', { replace: true });
                AuthService.reloadWindow();

            }

        }

    }, []);

    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/
    /***************************************************************************************/

    const initialDataState = {
        id: null,
        senderName: "",
        senderId: "",
        sender_Phone: "",
        senderCountry: "",

        receiverCountry: "",
        receiverName: "",
        receiverPhone: "",
        amount: "",

        destinationInstitution: "",
        local_transfer_fees: "",
        destination_transfer_fees: "",
        total_transfer_feee: "",

        total_amount: "",
        transfer_currency: "",
        exchange_rate: "",
        total_transfer: "",

        receiver_instutution_response: "",
        receiver_instutution_response_id: "",
        
        disburserName: "",
        disbursement_date: "",
        disbursement_currency: "",
        disbursement_amount: "",

        disbursementRecieverName: "",
        disbursementRecieverId: "",

        published: false
    };

    const [tutorial, setData] = useState(initialDataState);
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");
    const [memberdetails, setMemberDetails] = useState([]);


    const handleinputChange = event => {
        const { name, value } = event.target;
        setData({ ...tutorial, [name]: value });
    };


    const saveData = () => {

        var data = {
            senderName: tutorial.senderName,
            senderId: tutorial.senderId,
            sender_Phone: tutorial.sender_Phone,
            senderCountry: tutorial.senderCountry,

            receiverCountry: tutorial.receiverCountry,
            receiverName: tutorial.receiverName,
            receiverPhone: tutorial.receiverPhone,
            amount: tutorial.amount,

            destinationInstitution: tutorial.destinationInstitution,
            local_transfer_fees: tutorial.local_transfer_fees,
            destination_transfer_fees: tutorial.destination_transfer_fees,
            total_transfer_feee: tutorial.total_transfer_feee,

            total_amount: tutorial.total_amount,
            transfer_currency: tutorial.transfer_currency,
            exchange_rate: tutorial.exchange_rate,
            total_transfer: tutorial.total_transfer,

            receiver_instutution_response: tutorial.receiver_instutution_response,
            receiver_instutution_response_id: tutorial.receiver_instutution_response_id,
            disburserName: tutorial.disburserName,
            disbursement_date: tutorial.disbursement_date,
            disbursement_currency: tutorial.disbursement_currency,
            disbursement_amount: tutorial.disbursement_amount,

            disbursementRecieverName: tutorial.disbursementRecieverName,
            disbursementRecieverId: tutorial.disbursementRecieverId

        };



        TransferService.create(data)
            .then(response => {
                setData({
                    id: response.data.id,
                    senderName: response.data.senderName,
                    senderId: response.data.senderId,
                    sender_Phone: response.data.sender_Phone,
                    senderCountry: response.data.senderCountry,

                    receiverCountry: response.data.receiverCountry,
                    receiverName: response.data.receiverName,
                    receiverPhone: response.data.receiverPhone,
                    amount: response.data.amount,

                    destinationInstitution: response.data.destinationInstitution,
                    local_transfer_fees: response.data.local_transfer_fees,
                    destination_transfer_fees: response.data.destination_transfer_fees,
                    total_transfer_feee: response.data.total_transfer_feee,

                    total_amount: response.data.total_amount,
                    transfer_currency: response.data.transfer_currency,
                    exchange_rate: response.data.exchange_rate,
                    total_transfer: response.data.total_transfer,

                    receiver_instutution_response: response.data.receiver_instutution_response,
                    receiver_instutution_response_id: response.data.receiver_instutution_response_id,
                    disburserName: response.data.disburserName,
                    disbursement_date: response.data.disbursement_date,
                    disbursement_currency: response.data.disbursement_currency,
                    disbursement_amount: response.data.disbursement_amount,

                    disbursementRecieverName: response.data.disbursementRecieverName,
                    disbursementRecieverId: response.data.disbursementRecieverId

                });
                setSubmitted(true);
                setMessage("Data saved successfully.");
                //console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

    };

    const newData = () => {
        setData(initialDataState);
        setSubmitted(false);
        setMessage("");
        //props.history.push("/customers-new/");
        navigate('/transfer-new', { replace: true });

    };




    const [tutorials, setDatas] = useState([]);
    const tutorialsRef = useRef();



    const [page, setPage] = useState(1);

    const [pageSize, setPageSize] = useState(100);
    const pageSizes = [200, 300, 500];



    tutorialsRef.current = tutorials;

    const getMemberData = () => {
        setPage(1);

        MemberService.getMemberdetails(currentUser.username)
            .then((response) => {

                setMemberDetails(response.data);


            })
            .catch((e) => {
                console.log(e);
            });

    }



    const retrieveData = () => {
        setPage(1);
        getMemberData();


    };


    useEffect(retrieveData, [page, pageSize]);

    const list = () => {
        //props.history.push("/customers/");
        navigate('/transfer', { replace: true });

    };

    const add = () => {
        //props.history.push("/customers-new/");
        navigate('/transfer-new', { replace: true });
    };


    return (
        <div className='pages'>

            <div className="row">



                {/* cards  */}

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <div className="table-account">
                            <tbody>
                                <tr>
                                    <td rowSpan="3"><h2> <span className="table-tools-img"><FaIcons.FaIdCardAlt /></span> </h2></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><div className="table-name">{memberdetails.firstname}</div></td>
                                    <td><div className="table-name">{memberdetails.lastname}</div></td>
                                </tr>
                                <tr>
                                    <td><div className="table-name">Branch:</div></td>
                                    <td><div className="table-name">{memberdetails.account}</div></td>
                                </tr>

                            </tbody>
                        </div>
                    </table>


                </div>
                <div className="col-md-3 tool-box">

                    <table>
                        <tbody>
                            <tr>
                                <td><span className="page-title-img"><FcCustomerSupport /> </span></td>
                                <td><span className="page-title"> Customer</span></td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <div className="col-md-3 tool-box">

                    <table className="table-tools">
                        <tbody>
                            <tr>
                                <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                                <td className="tg-0lax"></td>
                                <td className="tg-0lax"></td>
                            </tr>
                            <tr>
                                <td className="tg-0lax"><button onClick={list} className="button-tools">Refresh</button></td>
                                <td className="tg-0lax"><button onClick={add} className="button-tools">New</button></td>
                            </tr>
                            <tr>
                                <td className="tg-0lax"></td>
                                <td className="tg-0lax"> </td>
                            </tr>
                        </tbody>

                    </table>
                </div>


                {/* end card box */}



                <div className="dataRow-with-two-column-center">

                    {/* col 1 */}
                    <div className="details-small">

                        <center>
                            <form className="form form-horizontal ">
                                <div className="form-body">

                                    {/* <div className="row"> <center> <span className="modal-title">SENDING INSTITUTION</span> </center> </div> */}
                                    <div className="row">
                                        <div className="transfer-institution">
                                            <center>
                                                ANANDA MONEY TRANSFER <br />
                                                RWANDA | KIGALI<br />
                                                TIN :1234567890 | PHONE :0785390822 <br />

                                            </center>
                                        </div>
                                    </div>

                                    <br />
                                    <div className="row">
                                        <div className="col-md-5"> <hr /> </div>
                                        <div className="col-md-2"><center> <span className="modal-title">SENDER</span> </center></div>
                                        <div className="col-md-5"><hr /></div>
                                    </div>
                                    <div className="row">


                                        <div className="col-md-4 form-label">
                                            <label>Sender Name</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="text" id="senderName" className="form-control form-text-field" name="senderName" placeholder="Fullname" onChange={handleinputChange} validations={[required]} />
                                        </div>
                                        <div className="col-md-4 form-label">
                                            <label>Sender Id</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="text" id="senderId" className="form-control" name="senderId" placeholder="senderId" onChange={handleinputChange} validations={[required]} />
                                        </div>
                                        <div className="col-md-4 form-label">
                                            <label> Sender Phone</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="number" id="sender_Phone" className="form-control" name="sender_Phone" placeholder="sender Phone" onChange={handleinputChange} validations={[required]} />
                                        </div>
                                        <div className="col-md-4 form-label">
                                            <label>Sender Country</label>
                                        </div>

                                        <div className="col-md-8 form-group">
                                            <input type="text" id="senderCountry" className="form-control" name="senderCountry" placeholder="Sender country" onChange={handleinputChange} validations={[required]} />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5"> <hr /> </div>
                                            <div className="col-md-2"><center> <span className="modal-title">RECEIVER</span> </center></div>
                                            <div className="col-md-5"><hr /></div>
                                        </div>
                                        <div className="col-md-4 form-label">
                                            <label>Receiver Country</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="text" id="receiverCountry" className="form-control" name="receiverCountry" placeholder="Receiver Country" onChange={handleinputChange} validations={[required]} />
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Receiver name</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="text" id="receiverName" className="form-control" name="receiverName" placeholder="Receiver Name" onChange={handleinputChange} validations={[required]} />
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Receiver phone</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="number" id="receiverPhone" className="form-control" name="receiverPhone" placeholder=" Receiver Phone" onChange={handleinputChange} validations={[required]} />
                                        </div>
                                       
                                        <div className="col-md-4">
                                            <label> </label>
                                        </div>

                                        <label className="col-md-8">{message}</label>

                                        <div className="col-md-4">
                                            <label> </label>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-3"> <hr /> </div>
                                            <div className="col-md-6"><center> <span className="modal-title">TRANSFER ACKNOWLEDGEMENT</span> </center></div>
                                            <div className="col-md-3"><hr /></div>
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Receiver Instutution Response</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="text" id="receiver_instutution_response" className="form-control" name="receiver_instutution_response" placeholder=" Response" onChange={handleinputChange} validations={[required]} />
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Receiver Instutution Response ID</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="text" id="receiver_instutution_response_id" className="form-control" name="receiver_instutution_response_id" placeholder=" Response Id" onChange={handleinputChange} validations={[required]} />
                                            <br />
                                        </div>
                                       
                                        <div className="row">
                                            <div className="col-md-3"> <hr /> </div>
                                            <div className="col-md-6"><center> <span className="modal-title">DISBURSEMENT</span> </center></div>
                                            <div className="col-md-3"><hr /></div>
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Disburser Name</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="text" id="disburserName" className="form-control" name="disburserName" placeholder=" Disburser Name" onChange={handleinputChange} validations={[required]} />
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Disbursement date</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="text" id="disbursement_date" className="form-control" name="disbursement_date" placeholder=" Disbursement date" onChange={handleinputChange} validations={[required]} />
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Disbursement currency</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="text" id="disbursement_currency" className="form-control" name="disbursement_currency" placeholder=" Disbursement Currency" onChange={handleinputChange} validations={[required]} />
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Disbursement Amount</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="text" id="disbursement_amount" className="form-control" name="disbursement_amount" placeholder=" Disbursement Amount" onChange={handleinputChange} validations={[required]} />
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Disbursement Reciever Name</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="text" id="disbursementRecieverName" className="form-control" name="disbursementRecieverName" placeholder=" Disbursement Receiver Nme" onChange={handleinputChange} validations={[required]} />
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Disbursement Reciever Id</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="text" id="disbursementRecieverId" className="form-control" name="disbursementRecieverId" placeholder=" Disbursement Receiver Id" onChange={handleinputChange} validations={[required]} />
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </center>
                    </div>
                    {/* col 2 */}

                    <div className="details-small">
                        <center>
                            <form className="form form-horizontal ">
                                <div className="form-body">

                                    <div className="row">
                                        <div className="transfer-institution-empty">


                                        </div>
                                    </div>

                                    <br />
                                    <div className="row">
                                        <div className="col-md-4"> <hr /> </div>
                                        <div className="col-md-4"><center> <span className="modal-title">TRANSFER DETAILS</span> </center></div>
                                        <div className="col-md-4"><hr /></div>
                                    </div>

                                    <div className="row">

                                        <div className="col-md-4 form-label">
                                            <label>Amount</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="number" id="amount" className="form-control" name="amount" placeholder="Amount" onChange={handleinputChange} validations={[required]} />
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Destinantion instutution</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="text" id="destinationInstitution" className="form-control" name="destinationInstitution" placeholder=" Destination institution" onChange={handleinputChange} validations={[required]} />
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Local Transfer fees</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="number" id="local_transfer_fees" className="form-control" name="local_transfer_fees" placeholder=" Local Transfer fees" onChange={handleinputChange} validations={[required]} />
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Destination Transfer fees</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="number" id="destination_transfer_fees" className="form-control" name="destination_transfer_fees" placeholder="Destination Transfer fees" onChange={handleinputChange} validations={[required]} />
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Total Transfer fees</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="number" id="total_transfer_feee" className="form-control" name="total_transfer_feee" placeholder="Total Transfer fees" onChange={handleinputChange} validations={[required]} va />
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Total Amount</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="number" id="total_amount" className="form-control" name="total_amount" placeholder="Total Amount" onChange={handleinputChange} validations={[required]} va />
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Transfer currency</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="text" id="transfer_currency" className="form-control" name="transfer_currency" placeholder="Transfer currency" onChange={handleinputChange} validations={[required]} />

                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Exchange rate</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="text" id="exchange_rate" className="form-control" name="exchange_rate" placeholder="Exchange rate" onChange={handleinputChange} validations={[required]} />

                                        </div>
                                        <div className="col-md-4 form-label">
                                            <label>Total Transfer</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input  type="text" id="total_transfer" className="form-control" name="total_transfer" placeholder="Total transfer" onChange={handleinputChange} validations={[required]} />
                                            <br />
                                        </div>

                                        <div className="col-md-4">
                                            <label> </label>
                                        </div>
                                        <div className="col-md-8 form-group">

                                            {submitted ? (<button onClick={newData}
                                                className="button-form"><ImPlus /> Add</button>) : (<button onClick={saveData}
                                                    type="button" className="button-tools-lg"><FaMoneyBillTransfer /> Transfer</button>)}

                                            <button
                                                type="button" onClick={list} className="button-tools-lg"><ImCancelCircle /> Close</button>
                                            <br /> <br />
                                        </div>



                                        {/* <div className="row">
                                            <div className="col-md-3"> <hr /> </div>
                                            <div className="col-md-6"><center> <span className="modal-title">TRANSFER ACKNOWLEDGEMENT</span> </center></div>
                                            <div className="col-md-3"><hr /></div>
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Instutution Response</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input disabled type="text" id="instutution_response" className="form-control" name="instutution_response" placeholder=" response" onChange={handleinputChange} validations={[required]} />
                                        </div>

                                        <div className="col-md-4 form-label">
                                            <label>Instutution Response ID</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input disabled type="text" id="instutution_response_id" className="form-control" name="instutution_response_id" placeholder=" Response Id" onChange={handleinputChange} validations={[required]} />
                                        </div> */}

                                        <div className="col-md-4">
                                            <label> </label>
                                        </div>

                                        <label className="col-md-8">{message}</label>


                                    </div>
                                </div>
                            </form>
                        </center>
                    </div>


                </div>
            </div>

        </div>
    );
};
