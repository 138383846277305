import http from "./http-common";
import authHeader from "./auth-header";

const getAll = (params) => {
  return http.get("/transfer_commission", { params,headers: authHeader() });
};

const get = (id) => {
  return http.get(`/transfer_commission/${id}`,{ headers: authHeader() });
};


const TransferCommissionService = {

  get,
  getAll
   
};

export default TransferCommissionService;
