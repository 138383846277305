import React, { useState, useRef, useEffect } from "react";
import { GiTimeBomb } from 'react-icons/gi';
import DatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import AuthService from "../../services/auth.service";
//import OrderService from "../../services/order.service";
import ReportService from "../../services/report.service";
import SelectOptionService from "../../services/select-options.service";
import { addDays, format } from 'date-fns';
import subDays from "date-fns/subDays";
import MemberService from "../../services/members.service";
import { useNavigate } from 'react-router-dom';

const currentUser = AuthService.getCurrentUser();

const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

export default function Reports() {
    let navigate = useNavigate();

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("user")) == null) {

            navigate('/login', { replace: true });
            AuthService.reloadWindow();

        } else {

            let decodedToken = jwt_decode(currentUser.accessToken);
            let currentDate = new Date();
            // JWT exp is in seconds redirect to login
            if (decodedToken.exp * 1000 < currentDate.getTime()) {

                AuthService.logout();
                navigate('/login', { replace: true });
                AuthService.reloadWindow();

            }
        }

    }, []);

    const initialDataState = {
        id: null,
        date: "",
        headCashier: "",
        cashier: "",
        cash: "",
        momo: "",
        cheque: "",
        transfer: "",
        visa: "",
        expenses: "",
        comment: "",
    };

    const [tutorial, setData] = useState(initialDataState);
    const [submitted, setSubmitted] = useState(false);
    const [reportSalesData, setReportSalesData] = useState([]);
    const [reportPaymentData, setReportPaymentData] = useState([]);
    const [expenseData, setExpenseData] = useState("");
    const [caisseInt, setCaisseInt] = useState("");
    const [cashingData, setCashingData] = useState([]);
    const [cashingCount, setCashingCount] = useState([]);
    const [currentData, setcurrentData] = useState(initialDataState);
    const [grandTotal, setGrandTotal] = useState("");
    const [message, setMessage] = useState("");
    const [orderedData, setOrderedData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [memberdetails, setMemberDetails] = useState([]);
    const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
    const [optionCategoryArray, setOptionCategoryArray] = useState([]);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [tutorials, setDatas] = useState([]);
    const [searchTitle, setSearchTitle] = useState("");
    const tutorialsRef = useRef();
    const [isManager, setIsManager] = useState(false);
    const [isReport, setIsReport] = useState(false);
    const [role, setRole] = useState("Cashier");
    const [cashierName, setCashierName] = useState("");

    const [dollar, setDollar] = useState("");
    const [dollarRate, setDollarRate] = useState("");
    const [dollarTotal, setDollarTotal] = useState("");
    const [dollar2, setDollar2] = useState("");
    const [dollar2Rate, setDollar2Rate] = useState("");
    const [dollar2Total, setDollar2Total] = useState("");
    const [dollar3, setDollar3] = useState("");
    const [dollar3Rate, setDollar3Rate] = useState("");
    const [dollar3Total, setDollar3Total] = useState("");
    const [dollar4, setDollar4] = useState("");
    const [dollar4Rate, setDollar4Rate] = useState("");
    const [dollar4Total, setDollar4Total] = useState("");
    const [totalDollar, setTotalDollar] = useState("");
    ////euro
    const [euro, setEuro] = useState("");
    const [euroRate, setEuroRate] = useState("");
    const [euroTotal, setEuroTotal] = useState("");
    const [euro2, setEuro2] = useState("");
    const [euro2Rate, setEuro2Rate] = useState("");
    const [euro2Total, setEuro2Total] = useState("");

    const [euro3, setEuro3] = useState("");
    const [euro3Rate, setEuro3Rate] = useState("");
    const [euro3Total, setEuro3Total] = useState("");
    const [euro4, setEuro4] = useState("");
    const [euro4Rate, setEuro4Rate] = useState("");
    const [euro4Total, setEuro4Total] = useState("");

    const [totalEuro, setTotalEuro] = useState("");
    const [biettageGrandTotal, setBiettageGrandTotal] = useState("");
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(3);

    const [cash, setCash] = useState("");
    const [momo, setMomo] = useState("");
    const [visa, setVisa] = useState("");
    const [cheque, setCheque] = useState("");
    const [transfer, setTransfer] = useState("");
    const [period, setPeriod] = useState("");
    const [isHeadCashier, setIsHeadCashier] = useState(false);
    const [isCashierSelected, setIsCashierSelected] = useState(false);

    const [headCashierCash, setHeadCashierCash] = useState("");
    const [headCashieMomo, setHeadCashieMomo] = useState("");
    const [headCashierCheque, setHeadCashierCheque] = useState("");
    const [headCashierTransfer, setHeadCashierTransfer] = useState("");
    const [headCashierVisa, setHeadCashierVisa] = useState("");
    const [headCashierExpenses, setHeadCashierExpenses] = useState("");
    const [headCashierComment, setHeadCashierComment] = useState("");
    const [headCashierReport, setHeadCashierReport] = useState([]);
    const [headCashierReportTotal, setHeadCashierReportTotal] = useState([]);
    const componentRef = useRef(null);
    const pageSizes = [100, 300, 100];
    tutorialsRef.current = tutorials;
    const [smShow, setSmShow] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [lgShow2, setLgShow2] = useState(false);

    const handleInputChangeEdit = event => {
        const { name, value } = event.target;
        setcurrentData({ ...currentData, [name]: value });
    };

    const handleInputChange = event => {
        const { name, value } = event.target;
        setData({ ...tutorial, [name]: value });
    };

    const handleChangeoptionCategories = (event) => {
        setOptionCategorySelectedValue(event.target.value);
        setCashierName(event.target.value);
    }

    const onChangeSearchTitle = (e) => {
        const searchTitle = e.target.value;
        setSearchTitle(searchTitle);
    };

    const handleChangeSearchText = (event) => {
        setSearchText(event.target.value);
    }

    const handleChangeHeadCashierComments = (event) => {
        setHeadCashierComment(event.target.value);


    }

    const getRequestParams = (searchTitle, page, pageSize) => {
        let params = {};

        if (isManager == true) {
            setRole("Manager");
        }

        if (searchTitle) {
            params["code"] = searchTitle;
        }

        if (role) {
            params["role"] = role;
        }

        if (memberdetails.account) {
            params["location"] = memberdetails.account;
        }

        if (page) {
            params["page"] = page - 1;
        }

        if (pageSize) {
            params["size"] = pageSize;
        }

        if (startDate) {
            params["date"] = format(startDate, 'yyyy-MM-dd');
        }

        if (currentUser.username) {
            params["username"] = currentUser.username;
        }

        if (optionCategorySelectedValue) {
            params["cashier"] = optionCategorySelectedValue;
        }

        if (isReport) {
            params["report"] = isReport;
        }
        return params;
    };


    const retrieveData = () => {
        setPage(1);

        if (currentUser.roles.includes("ROLE_MANAGER")) {
            setCashierName(optionCategorySelectedValue);

        } else {
            setCashierName(currentUser.username);
        }
        var data = {
            text1: format(startDate, 'yyyy-MM-dd') + " 00:00:00",
            text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
            text3: memberdetails.account,
            text4: cashierName,
        }

        ReportService.salesReport(data)
            .then((response) => {
                const { reportSalesData, reportPaymentData, cashing, cashingCount, expenses, caisseInt, gtotal, totalPages, dollar, dollarRate, dollarTotal, dollar2, dollar2Rate, dollar2Total, dollar3, dollar3Rate, dollar3Total, dollar4, dollar4Rate, dollar4Total, totalDollar, euro, euroRate, euroTotal, euro2, euro2Rate, euro2Total, euro3, euro3Rate, euro3Total, euro4, euro4Rate, euro4Total, totalEuro, biettageGrandTotal } = response.data;
                setReportSalesData(reportSalesData);
                setReportPaymentData(reportPaymentData);
                setExpenseData(expenses);
                //setCaisseInt(caisseInt);
                setCashingData(cashing);
                setCashingCount(cashingCount);
                setGrandTotal(gtotal);
                setCount(totalPages);

                setDollar(dollar);
                setDollarRate(dollarRate);
                setDollarTotal(dollarTotal);

                setDollar2(dollar2);
                setDollar2Rate(dollar2Rate);
                setDollar2Total(dollar2Total);

                setDollar3(dollar3);
                setDollar3Rate(dollar3Rate);
                setDollar3Total(dollar3Total);

                setDollar4(dollar4);
                setDollar4Rate(dollar4Rate);
                setDollar4Total(dollar4Total);
                setTotalDollar(totalDollar);

                //euro
                setEuro(euro);
                setEuroRate(euroRate);
                setEuroTotal(euroTotal);

                setEuro2(euro2);
                setEuro2Rate(euro2Rate);
                setEuro2Total(euro2Total);

                setEuro3(euro3);
                setEuro3Rate(euro3Rate);
                setEuro3Total(euro3Total);

                setEuro4(euro4);
                setEuro4Rate(euro4Rate);
                setEuro4Total(euro4Total);
                setTotalEuro(totalEuro);
                setBiettageGrandTotal(biettageGrandTotal);
                //setCount(totalPages);
                //console.log(response.data);
                setIsCashierSelected(true);
            })
            .catch((e) => {
                console.log(e);
            });
        getSelectOption();
        getMemberData();
    };

    const getMemberData = () => {
        setPage(1);
        MemberService.getMemberdetails(currentUser.username)
            .then((response) => {

                setMemberDetails(response.data);
                setPeriod(response.data.report_duration);
            })
            .catch((e) => {
                console.log(e);
            });

        if (currentUser.roles.includes("ROLE_MANAGER")) {
            setIsManager(true);

        }

        if (currentUser.roles.includes("ROLE_HEADCASHIER")) {
            setIsHeadCashier(true);
        }
    }

    const getSelectOption = () => {
        setPage(1);
        var category = "CASHIER";
        SelectOptionService.getAllSelectedOptions(category)
            .then((response) => {
                setOptionCategoryArray(response.data);
                // setOptionCategoryArrayTransfer(response.data);
            })
            .catch((e) => {
                console.log(e);
            });

    };

    const refreshList = () => {
        setPage(1);
        retrieveData();
        setHeadCashierReport([]);
    };

    const findByTitle = () => {
        setPage(1);
        retrieveData();
    };

    const handleChangeEndDate = (date) => {
        setEndDate(date);
        setPage(1);
    }

    const handleChangeStartDate = (date) => {
        setStartDate(date);
        setPage(1);
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    const cashierReports = () => {
        // props.history.push("/reports-cashier/?x=$" + currentUser.username);
        navigate('/report-cashier' + currentUser.username, { replace: true })
    }

    const reportSallers = () => {
        // props.history.push("/reports-sallers");
        navigate('/reports-sallers', { replace: true });
    }

    const reportSales = () => {
        // props.history.push("/reports-sales");
        navigate('/reports-sales', { replace: true });
    }

    const reportJournal = () => {
        // props.history.push("/reports-journal");
        navigate('/reports-journal', { replace: true });
    }

    const reportCashing = () => {
        // props.history.push("/reports-cashing");
        navigate('/reports-cashing', { replace: true });
    }

    const reportStand = () => {
        // props.history.push("/reports-stand");
        navigate('/reports-stand', { replace: true });
    }

    const retriveCashing = (id) => {
        setPage(1);
        ReportService.cashingReport(id)
            .then((response) => {

                const { cashing, cashingCount, caisseInt, gtotal, totalPages, dollar, dollarRate, dollarTotal, dollar2, dollar2Rate, dollar2Total, dollar3, dollar3Rate, dollar3Total, dollar4, dollar4Rate, dollar4Total, totalDollar, euro, euroRate, euroTotal, euro2, euro2Rate, euro2Total, euro3, euro3Rate, euro3Total, euro4, euro4Rate, euro4Total, totalEuro, biettageGrandTotal } = response.data;


                setCaisseInt(caisseInt);
                setCashingData(cashing);
                setCashingCount(cashingCount);
                //setGrandTotal(gtotal);
                setCount(totalPages);

                setDollar(dollar);
                setDollarRate(dollarRate);
                setDollarTotal(dollarTotal);

                setDollar2(dollar2);
                setDollar2Rate(dollar2Rate);
                setDollar2Total(dollar2Total);

                setDollar3(dollar3);
                setDollar3Rate(dollar3Rate);
                setDollar3Total(dollar3Total);

                setDollar4(dollar4);
                setDollar4Rate(dollar4Rate);
                setDollar4Total(dollar4Total);
                setTotalDollar(totalDollar);

                //euro
                setEuro(euro);
                setEuroRate(euroRate);
                setEuroTotal(euroTotal);

                setEuro2(euro2);
                setEuro2Rate(euro2Rate);
                setEuro2Total(euro2Total);

                setEuro3(euro3);
                setEuro3Rate(euro3Rate);
                setEuro3Total(euro3Total);

                setEuro4(euro4);
                setEuro4Rate(euro4Rate);
                setEuro4Total(euro4Total);
                setTotalEuro(totalEuro);
                setBiettageGrandTotal(biettageGrandTotal);
                //setCount(totalPages);
                //console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const showReport = () => {
        setLgShow(true);
    }

    const showReportPeriodic = () => {
        var data = {
            text1: format(startDate, 'yyyy-MM-dd') + " 00:00:00",
            text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
            text3: memberdetails.account,
            text4: optionCategorySelectedValue,
        }

        ReportService.getHeadCashierReportTotal(data)
            .then((response) => {
                setHeadCashierReportTotal(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
        setLgShow2(true);
    }

    const saveHeadCashierReport = () => {
        var data = {
            id: null,
            date: "",
            headCashier: currentUser.username,
            cashier: optionCategorySelectedValue,
            cash: tutorial.cash,
            momo: tutorial.momo,
            cheque: tutorial.cheque,
            transfer: tutorial.transfer,
            visa: tutorial.visa,
            expenses: tutorial.expenses,
            comment: tutorial.comment,
        };

        if (data.cashier === null || data.cashier === "") {
            setMessage("Select cashier report first.");
        }
        else if (data.cash === null || data.cash === "") {
            setMessage("Cash is empty.");
        }
        else if (data.momo === null || data.momo === "") {
            setMessage("Momo is empty.");
        }
        else if (data.cheque === null || data.cheque === "") {
            setMessage("Cheque is empty.");
        }
        else if (data.transfer === null || data.transfer === "") {
            setMessage("Transfer is empty.");
        }
        else if (data.visa === null || data.visa === "") {
            setMessage("VISA is empty.");
        }
        else if (data.comment === null || data.comment === "") {
            setMessage("Comment is empty.");
        } else {
            ReportService.createHeadCashierReport(data)
                .then(response => {
                    setMessage(response.data);
                    // setMessage("Saved successfully.");
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    const closeModal = () => {
        setLgShow(false);
    }

    const getHeadCashierReport = () => {
        var data = {
            text1: format(endDate, 'yyyy-MM-dd'),
            text2: optionCategorySelectedValue,

        };
        ReportService.getHeadCashierReport(data)
            .then(response => {
                setHeadCashierReport(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    const performance = () => {
        // props.history.push("/reports-performance");
        navigate('/reports-performance', { replace: true });
    }

    const profit = () => {
        // props.history.push("/reports-stand-profit");
        navigate('/reports-stand-profit', { replace: true });
    }

    useEffect(retrieveData, [page, pageSize]);

    return (
        <>
            <div className='pages'>
                <div className="row">
                    {/* cards */}
                    <div className="col-md-3 tool-box">
                        <table className="table-tools">
                            <tbody>
                                <tr>
                                    <td rowSpan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
                                    <td>
                                        {isManager ? (<DatePicker className="form-control datePicker-style"
                                            selected={startDate}
                                            onChange={handleChangeStartDate}
                                            name="startDate"
                                            minDate={subDays(new Date(), period)}
                                            maxDate={addDays(new Date(), 0)}
                                            dateFormat="yyyy-MM-dd"
                                        />) : (<DatePicker disabled className="form-control datePicker-style"
                                            selected={startDate}
                                            onChange={handleChangeStartDate}
                                            minDate={subDays(new Date(), period)}
                                            maxDate={addDays(new Date(), 0)}
                                            dateFormat="yyyy-MM-dd"
                                        />)}
                                    </td>
                                    <td>
                                        {isManager ? (<DatePicker className="form-control datePicker-style"
                                            selected={endDate}
                                            onChange={handleChangeEndDate}
                                            name="endDate"
                                            minDate={subDays(new Date(), period)}
                                            maxDate={addDays(new Date(), 0)}
                                            dateFormat="yyyy-MM-dd"
                                        />) : (<DatePicker disabled className="form-control datePicker-style"
                                            selected={endDate}
                                            onChange={handleChangeEndDate}
                                            name="endDate"
                                            minDate={subDays(new Date(), period)}
                                            maxDate={addDays(new Date(), 0)}
                                            dateFormat="yyyy-MM-dd"
                                        />)}
                                    </td>
                                </tr>
                                {isManager ? (<tr>
                                    <td>Cashier</td>
                                    <td>
                                        <div className="col-md-8 form-group">
                                            <select className="form-control" onChange={handleChangeoptionCategories} >
                                                <option>Select</option>
                                                {optionCategoryArray.map((optionCategory) => (
                                                    <option key={optionCategory.value}>{optionCategory.value}</option>
                                                ))}

                                            </select>
                                        </div></td>
                                </tr>) : (<tr>
                                    <td>Cashier</td>
                                    <td>
                                        <div className="col-md-8 form-group">
                                            <select disabled className="form-control" onChange={handleChangeoptionCategories} >
                                                <option key={currentUser.username}>{currentUser.username}</option>
                                                {optionCategoryArray.map((optionCategory) => (
                                                    <option key={optionCategory.value}>{optionCategory.value}</option>
                                                ))}

                                            </select>
                                        </div></td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>

                    <div className="col-md-3 tool-box">
                        <table className="table-tools">
                            <tbody>
                                <tr>
                                    <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                                    <td className="tg-0lax"><button onClick={reportSallers} className="button-tools">Sallers</button></td>
                                    <td className="tg-0lax"><button onClick={reportSales} className="button-tools">Sales</button></td>
                                </tr>
                                <tr>
                                    <td className="tg-0lax"><button onClick={reportJournal} className="button-tools">Journal</button></td>
                                    <td className="tg-0lax"><button onClick={reportCashing} className="button-tools">Cashing</button></td>
                                    <td className="tg-0lax"><button onClick={reportStand} className="button-tools">Stand</button></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-3 tool-box">
                        <table className="table-tools">
                            <div className="table-account">
                                <tbody>
                                    <tr>
                                        <td className="tg-0lax"><button onClick={performance} className="button-tools">Performance</button></td>
                                        <td><button onClick={profit} className="button-tools">Profit</button></td>
                                        <td><button className="button-tools">Assets</button></td>
                                    </tr>
                                </tbody>
                            </div>
                        </table>
                    </div>

                    <div className="dataRow">
                        <div className="details">
                            {/* row 1 start */}
                            <div className="row">
                                {/* col1 start */}
                                <div className="col-md-4">

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th colSpan="2">Cashier</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Cashier: {optionCategorySelectedValue}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Cashier's comment: {cashingData.cashierComment}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Head Cashier: {cashingData.headCashier}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Head.C Amount: {cashingData.headCashierAmount}</td>
                                                <td></td>
                                            </tr>
                                            {/* <tr>
 <td>Comment: {cashingData.comment}</td>
 <td></td>
 </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                                {/* col1 end */}

                                {/* col2 start */}
                                <div className="col-md-2">

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th colSpan="2">Biettage</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cashingCount &&
                                                cashingCount.map((tutorial, index) => (
                                                    <tr key={tutorial}>
                                                        <td>{tutorial}</td>
                                                        <td><button onClick={() => retriveCashing(tutorial)} className="button button-tools-cashing">OK</button></td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                                {/* col1 end */}

                                {/* col3 start */}
                                <div className="col-md-6">

                                    <div className="row">Head-cashier Report | {format(endDate, 'yyyy-MM-dd')}</div>
                                    <div className="row"><div className="col-md-4"> {isHeadCashier ? (<button onClick={() => showReport()} className="button-head-cashier-report">Head-Cashier report</button>) : ("")} </div> <div className="col-md-4"> {isHeadCashier ? (<button onClick={getHeadCashierReport} className="button-head-cashier-report">Refresh</button>) : ("")} </div> </div>
                                    <div className="row"><div className="col-md-4"> {isHeadCashier ? (<button onClick={() => showReportPeriodic()} className="button-head-cashier-report">Periodic report</button>) : ("")} </div>  </div>
                                    <div className="row">
                                        <div className="cashier-report-text-area">
                                            Head Cashier:{headCashierReport.headCashier}<br />
                                            Cashier: {headCashierReport.cashier}<br />
                                            Cash: {headCashierReport.cash}<br />
                                            Momo: {headCashierReport.momo}<br />
                                            Visa:{headCashierReport.visa}<br />
                                            Cheque: {headCashierReport.cheque}<br />
                                            Transfer: {headCashierReport.transfer}<br />
                                            Expenses: {headCashierReport.expenses}<br />
                                            Comment: {headCashierReport.comment}
                                        </div>
                                    </div>
                                </div>
                                {/* col3 end */}
                            </div>{/* row 1 end */}
                            <hr />
                            {/* row 2 start */}
                            <div className="row">
                                {/* col1 start */}
                                <div className="col-md-4">
                                    <table className="table">
                                        <thead className="scroll-thead">
                                            <tr>
                                                <th>BIETAGE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>AMOUNT</td>
                                                <td></td>
                                                <td>{cashingData.amount}</td>
                                            </tr>
                                            <tr>
                                                <td>5000</td>
                                                <td>{cashingData.fiveThousands}</td>
                                                <td>{cashingData.fiveThousandsTotal}</td>
                                            </tr>
                                            <tr>
                                                <td>2000</td>
                                                <td>{cashingData.twoThousands}</td>
                                                <td>{cashingData.twoThousandsTotal}</td>
                                            </tr>
                                            <tr>
                                                <td>1000</td>
                                                <td>{cashingData.oneThousands}</td>
                                                <td>{cashingData.oneThousandsTotal}</td>
                                            </tr>
                                            <tr>
                                                <td>500</td>
                                                <td>{cashingData.fiveHundred}</td>
                                                <td>{cashingData.fiveHundredTotal}</td>
                                            </tr>
                                            <tr>
                                                <td>100</td>
                                                <td>{cashingData.onehundred}</td>
                                                <td>{cashingData.onehundredTotal}</td>
                                            </tr>
                                            <tr>
                                                <td>50</td>
                                                <td>{cashingData.fifty}</td>
                                                <td>{cashingData.fiftyTotal}</td>
                                            </tr>
                                            <tr>
                                                <td>20</td>
                                                <td>{cashingData.twenty}</td>
                                                <td>{cashingData.twentyTotal}</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>{cashingData.ten}</td>
                                                <td>{cashingData.tenTotal}</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>{cashingData.five}</td>
                                                <td>{cashingData.fiveTotal}</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>{cashingData.two}</td>
                                                <td>{cashingData.twoTotal}</td>
                                            </tr>
                                            <tr>
                                                <td>1</td>
                                                <td>{cashingData.one}</td>
                                                <td>{cashingData.oneTotal}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* col1 end */}

                                {/* col2 start */}
                                <div className="col-md-4">
                                    <table className="table">
                                        <thead className="scroll-thead">
                                            <tr>
                                                <th>ANALYTICS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>BEST SALLER</td>
                                                <td>{reportSalesData.reportSales_totalSales}</td>
                                            </tr>

                                            <tr>
                                                <td>BEST COSTOMERS</td>
                                                <td>{reportSalesData.reportSales_newSales}</td>
                                            </tr>
                                            <tr>
                                                <td>BEST CASHIER</td>
                                                <td>{reportSalesData.reportSales_salesPayment}</td>
                                            </tr>
                                            <tr>
                                                <td>CUSTOMER WITH DEPOSIT</td>
                                                <td>{reportSalesData.reportSales_salesDeposit}</td>
                                            </tr>
                                            <tr>
                                                <td>TOTAL CREDIT</td>
                                                <td>{reportSalesData.reportSales_salesCredit}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* col1 end */}

                                {/* col3 start */}
                                <div className="col-md-2">
                                    <table className="table">
                                        <thead className="scroll-thead">
                                            <tr>
                                                <th>SALES</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>TOTAL SALES</td>
                                                <td>{reportSalesData.reportSales_totalSales}</td>
                                            </tr>

                                            <tr>
                                                <td>NEW SALES</td>
                                                <td>{reportSalesData.reportSales_newSales}</td>
                                            </tr>
                                            <tr>
                                                <td>PAYMENT</td>
                                                <td>{reportSalesData.reportSales_salesPayment}</td>
                                            </tr>
                                            <tr>
                                                <td>DEPOSIT</td>
                                                <td>{reportSalesData.reportSales_salesDeposit}</td>
                                            </tr>
                                            <tr>
                                                <td>CREDIT</td>
                                                <td>{reportSalesData.reportSales_salesCredit}</td>
                                            </tr>
                                        </tbody>
                                    </table></div>
                                {/* col3 end */}

                                {/* col4 start */}
                                <div className="col-md-2">

                                    <table className="table">
                                        <thead className="scroll-thead">
                                            <tr>
                                                <th>DESCRIPTION</th>
                                                <th>AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Cash</td>
                                                <td>{reportPaymentData.cash}</td>
                                            </tr>
                                            <tr>
                                                <td>MoMo</td>
                                                <td>{reportPaymentData.momo}</td>
                                            </tr>
                                            <tr>
                                                <td>Visa</td>
                                                <td>{reportPaymentData.visa}</td>
                                            </tr>
                                            <tr>
                                                <td>Cheque</td>
                                                <td>{reportPaymentData.cheque}</td>
                                            </tr>
                                            <tr>
                                                <td>Tranfer</td>
                                                <td>{reportPaymentData.transfer}</td>
                                            </tr>
                                            <tr>
                                                <td><span className="total-text">G TOTAL::</span></td>
                                                <td><span className="total-text">{reportPaymentData.total} </span></td>
                                            </tr>
                                            <tr>
                                                <td>EXPENSES:</td>
                                                <td>{expenseData}</td>
                                            </tr>
                                            <tr>
                                                <td><span className="total-text">TOTAL:</span></td>
                                                <td><span className="total-text">{grandTotal}</span></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* row 4 end */}
                            </div>{/* row 2 end */}
                            <hr />
                        </div>
                    </div>
                </div>
            </div>

            <Modal ref={componentRef}
                size="lg"
                backdrop="static"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"

            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <div className="row">
                            {/* <div className="col-md-6"> <label> {customerName} {customerPhone} </label></div> */}
                        </div>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-bg">
                    <div className="dataRow">
                        <div className="details">
                            <form className="form form-horizontal ">
                                <div className="form-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>HEAD CASHIER</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input disabled type="text" id="headCashier" className="form-control" name="headCashier" placeholder="model number" onChange={handleInputChange} value={currentUser.username} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Cashier</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input disabled type="text" id="cashier" className="form-control" name="cashier" placeholder="Cashier" onChange={handleInputChange} value={optionCategorySelectedValue} />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Cash</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="number" id="cash" className="form-control" name="cash" placeholder="Cash" onChange={handleInputChange} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Momo</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="number" id="momo" className="form-control" name="momo" placeholder="MoMo" onChange={handleInputChange} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Cheque</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="number" id="cheque" className="form-control" name="cheque" placeholder="Cheque" onChange={handleInputChange} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Transfer</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="number" id="transfer" className="form-control" name="transfer" placeholder="Transfer" onChange={handleInputChange} validations={[required]} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Visa</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="number" id="visa" className="form-control" name="visa" placeholder="Visa" onChange={handleInputChange} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Expenses</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="text" id="expenses" className="form-control" name="expenses" placeholder="Expenses" onChange={handleInputChange} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Comment</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input type="text" id="comment" className="form-control" name="comment" placeholder="Comment" onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-4">
                                            <label> </label>
                                        </div>
                                        <label className="col-md-8 danger-message">{message}</label>
                                        <div className="col-md-4">
                                            <label> </label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <button onClick={saveHeadCashierReport}
                                                type="button" className="button-tools"> Save</button>
                                            <button onClick={closeModal} className="button-tools">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal ref={componentRef}
                size="lg"
                backdrop="static"
                show={lgShow2}
                onHide={() => setLgShow2(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <div className="row">
                            Head cashier periodic report
                            {/* <div className="col-md-6"> <label> {customerName} {customerPhone} </label></div> */}
                        </div>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-bg">
                    <div className="dataRow">
                        <div className="details">
                            <form className="form form-horizontal ">
                                <div className="form-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>HEAD CASHIER</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input disabled type="text" id="headCashier" className="form-control" name="headCashier" placeholder="model number" onChange={handleInputChange} value={currentUser.username} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Cashier</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input disabled type="text" id="cashier" className="form-control" name="cashier" placeholder="Cashier" onChange={handleInputChange} value={optionCategorySelectedValue} />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Cash</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input disabled type="number" id="cash" className="form-control" name="cash" placeholder="Cash" onChange={handleInputChange} value={headCashierReportTotal.cash} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Momo</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input disabled type="number" id="momo" className="form-control" name="momo" placeholder="MoMo" onChange={handleInputChange} value={headCashierReportTotal.momo} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Cheque</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input disabled type="number" id="cheque" className="form-control" name="cheque" placeholder="Cheque" onChange={handleInputChange} value={headCashierReportTotal.cheque} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Transfer</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input disabled type="number" id="transfer" className="form-control" name="transfer" placeholder="Transfer" onChange={handleInputChange} validations={[required]} value={headCashierReportTotal.transfer} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Visa</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input disabled type="number" id="visa" className="form-control" name="visa" placeholder="Visa" onChange={handleInputChange} value={headCashierReportTotal.visa} />
                                        </div>

                                        <div className="col-md-4">
                                            <label>Expenses</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input disabled type="text" id="expenses" className="form-control" name="expenses" placeholder="Expenses" onChange={handleInputChange} value={headCashierReportTotal.expenses} />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Comment</label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <input disabled type="text" id="comment" className="form-control" name="comment" placeholder="Comment" onChange={handleInputChange} value={headCashierReportTotal.cash + headCashierReportTotal.momo + headCashierReportTotal.cheque + headCashierReportTotal.transfer + headCashierReportTotal.visa - headCashierReportTotal.expenses} />
                                        </div>
                                        <div className="col-md-4">
                                            <label> </label>
                                        </div>
                                        <label className="col-md-8 danger-message">{message}</label>
                                        <div className="col-md-4">
                                            <label> </label>
                                        </div>
                                        <div className="col-md-8 form-group">
                                            <button onClick={closeModal} className="button-tools">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};