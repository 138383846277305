import React, { useState, useRef, useEffect } from "react";
import { FcServices } from 'react-icons/fc';
import { GiTimeBomb } from 'react-icons/gi';
import { ImFloppyDisk, ImCancelCircle, ImPlus } from "react-icons/im";


import DatePicker from 'react-datepicker';
import { Modal } from 'react-bootstrap';
//import { Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Pagination from "@material-ui/lab/Pagination";
import AuthService from "../../services/auth.service";
//import OrderService from "../../services/order.service";
import ExpensesService from "../../services/expense.service";
import SelectOptionService from "../../services/select-options.service";
import { format } from 'date-fns';
import MemberService from "../../services/members.service";
import { useNavigate, Link, Outlet } from 'react-router-dom';
import { FaMoneyBillTransfer } from "react-icons/fa6";
import logo from '../images/ananda.jpg';
import OrganizationDetailsService from "../../services/organization-details.service";


const currentUser = AuthService.getCurrentUser();

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

export default function ExpenseList() {

  const currentUser = AuthService.getCurrentUser();

  let navigate = useNavigate();

  useEffect(() => {

    if (JSON.parse(localStorage.getItem("user")) == null) {

      //redirectToLogin();

      navigate('/login', { replace: true });

    } else {

      let decodedToken = jwt_decode(currentUser.accessToken);
      let currentDate = new Date();
      // JWT exp is in seconds redirect to login
      if (decodedToken.exp * 1000 < currentDate.getTime()) {

        AuthService.logout();
        navigate('/login', { replace: true });
      }

    }

  }, []);

  const initialDataState = {
    id: null,
    expenseType: "",
    amount: "",
    other: "",

    username: "",

  };

  const [tutorial, setData] = useState(initialDataState);
  const [submitted, setSubmitted] = useState(false);
  const [currentOrderData, setCurrentOrderData] = useState([]);
  const [currentOrderDataSummary, setCurrentOrderDataSummary] = useState([]);
  const [currentData, setcurrentData] = useState(initialDataState);
  const [message, setMessage] = useState("");
  const [orderedData, setOrderedData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [memberdetails, setMemberDetails] = useState([]);
  const [optionCategorySelectedValue, setOptionCategorySelectedValue] = useState("");
  const [optionCategoryArray, setOptionCategoryArray] = useState([]);
  const [organizationDetails, setorganizationDetails] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [tutorials, setDatas] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const tutorialsRef = useRef();
  const [isManager, setIsManager] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [role, setRole] = useState("Cashier");
  const [cashierName, setCashierName] = useState("");


  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(3);

  const [cash, setCash] = useState("");
  const [momo, setMomo] = useState("");
  const [visa, setVisa] = useState("");
  const [cheque, setCheque] = useState("");
  const [transfer, setTransfer] = useState("");

  const componentRef = useRef(null);

  const pageSizes = [100, 300, 500];

  tutorialsRef.current = tutorials;

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...tutorial, [name]: value });
  };

  const handleInputChangeEdit = event => {
    const { name, value } = event.target;
    setcurrentData({ ...currentData, [name]: value });
  };

  const handleChangeoptionCategories = (event) => {
    setOptionCategorySelectedValue(event.target.value);
    setCashierName(event.target.value);

  }

  const onChangeSearchTitle = (e) => {
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
  };

  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value);
  }

  const getRequestParams = (searchTitle, page, pageSize) => {
    let params = {};

    if (isManager == true) {
      setRole("Manager");
    }

    if (searchTitle) {
      params["code"] = searchTitle;
    }

    if (role) {
      params["role"] = role;
    }

    if (memberdetails.account) {
      params["location"] = memberdetails.account;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    if (startDate) {
      params["date"] = format(startDate, 'yyyy-MM-dd');
    }

    if (currentUser.username) {
      params["username"] = currentUser.username;
    }

    if (optionCategorySelectedValue) {
      params["cashier"] = optionCategorySelectedValue;
    }

    if (isReport) {
      params["report"] = isReport;
    }

    return params;
  };

  const retrieveData = () => {

    setPage(1);

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setCashierName(optionCategorySelectedValue);

    } else {
      setCashierName(currentUser.username);
    }

    var data = {
      text1: format(startDate, 'yyyy-MM-dd') + " 00:00:00",
      text2: format(endDate, 'yyyy-MM-dd') + " 23:59:59",
      text3: memberdetails.account,
      text4: cashierName,
      text5: searchTitle
    }

    ExpensesService.getAll(data)
      .then((response) => {

        const { tutorials, totalPages } = response.data;

        setDatas(tutorials);
        setCount(totalPages);

        //setCount(totalPages);

        //console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
    getSelectOption();

    getMemberData();

    getOrganizationDetails();
  };


  const updateData = () => {
    setPage(1);
    setMessage("");

    ExpensesService.update(currentData.id, currentData)
      .then(response => {
        setMessage("Transfer updated successfully!");
        submitted(true);
      })
      .catch(e => {
        console.log(e);
      });
    refreshList();
  };


  const saveData = () => {
    setPage(1);

    var data = {
      expenseType: tutorial.expenseType,
      amount: tutorial.amount,
      other: tutorial.other,
      username: currentUser.username
    };

    ExpensesService.create(data)
      .then(response => {
        setData({
          id: response.data.id,
          expenseType: response.data.expenseType,
          amount: response.data.amount,
          other: response.data.other,
          username: response.data.username

        });
        setSubmitted(true);
        setMessage("Data saved successfully.");
        //console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const newData = () => {
    setData(initialDataState);
    setSubmitted(false);
    setMessage("");

    navigate('/expense', { replace: true });
  };


  const getMemberData = () => {
    setPage(1);

    MemberService.getMemberdetails(currentUser.username)
      .then((response) => {

        setMemberDetails(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

    if (currentUser.roles.includes("ROLE_MANAGER")) {
      setIsManager(true);

    }

  }


  const getOrganizationDetails = () => {
    setPage(1);

    OrganizationDetailsService.get(1)
      .then((response) => {

        setorganizationDetails(response.data);
        console.log(response.data);


      })
      .catch((e) => {
        console.log(e);
      });

  }

  const getSelectOption = () => {
    setPage(1);

    var category = "CASHIER";
    SelectOptionService.getAllSelectedOptions(category)
      .then((response) => {

        setOptionCategoryArray(response.data);
        // setOptionCategoryArrayTransfer(response.data);



      })
      .catch((e) => {
        console.log(e);
      });

  };



  useEffect(retrieveData, [page, pageSize]);

  const refreshList = () => {
    setPage(1);
    retrieveData();
  };

  const findByTitle = () => {
    setPage(1);
    retrieveData();
  };

  const openObject = (id) => {
    setMessage("");
    setLgShow2(true);
    setPage(1);
    setcurrentData(initialDataState);

    ExpensesService.get(id)
      .then(response => {
        setcurrentData(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const add = () => {
    // props.history.push("/expense-new");
    navigate('/expense-new', { replace: true });
  }




  const logOut = () => {
    AuthService.logout();
  }


  const handleChangeEndDate = (date) => {

    setEndDate(date);

  }

  const handleChangeStartDate = (date) => {

    setStartDate(date);

  }

  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);


  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const searchOrder = () => {
    setPage(1);

  }

  const list = () => {
    // props.history.push("/expense-list/");
    navigate('/expense-list', { replace: true });
  };



  return (
    <>
      <div className='pages'>

        <div className="row">
          {/* cards  */}

          <div className="col-md-3 tool-box">

            <table className=" table-tools">
              <div className="table-account">
                <tbody>

                  <tr>
                    <td><div className="table-name">{memberdetails.firstname}</div></td>
                    <td><div className="table-name">{memberdetails.lastname}</div></td>
                  </tr>
                  <tr>
                    <td>Branch:</td>
                    <td> <div className="table-name">{memberdetails.account}</div></td>
                  </tr>
                </tbody>
              </div>
            </table>
          </div>

          <div className="col-md-3 tool-box">
            <table className="table-tools">
              <tbody>

                <tr>
                  <td rowSpan="2"><h2><span className="table-tools-img"><GiTimeBomb /></span></h2></td>
                  <td><DatePicker className="form-control datePicker-style"
                    selected={startDate}
                    onChange={handleChangeStartDate}
                    name="startDate"
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                  /></td>
                  <td><DatePicker className="form-control datePicker-style"
                    selected={endDate}
                    onChange={handleChangeEndDate}
                    name="endDate"
                    maxDate={new Date()}
                    dateFormat="yyyy-MM-dd"
                  /></td>
                </tr>
                {isManager ? (<tr>
                  <td>Cashier</td>
                  <td>
                    <div className="col-md-8 form-group">
                      <select className="form-control" onChange={handleChangeoptionCategories} >
                        <option>Select</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div></td>
                </tr>) : (<tr>
                  <td>Cashier</td>
                  <td>
                    <div className="col-md-8 form-group">
                      <select disabled className="form-control" onChange={handleChangeoptionCategories} >
                        <option>{currentUser.username}</option>
                        {optionCategoryArray.map((optionCategory) => (
                          <option key={optionCategory.value}>{optionCategory.value}</option>
                        ))}

                      </select>
                    </div></td>
                </tr>)}
              </tbody>
            </table>
          </div>

          <div className="col-md-3 tool-box">

            <table className="table-tools">
              <tbody>
                <tr>
                  <td className="tg-0lax" rowSpan="3"><h2> <span className="table-tools-img"><FcServices /></span> </h2></td>
                  <td className="tg-0lax"></td>
                  <td className="tg-0lax"></td>
                </tr>
                <tr>
                  <td className="tg-0lax"><button onClick={refreshList} className="button-tools">Refresh</button></td>
                  {/* <td className="tg-0lax"><button onClick={add} className="button-tools">New</button></td> */}
                  <td className="tg-0lax"><button onClick={() => setLgShow(true)} className="button-tools">New</button></td>
                </tr>

              </tbody>

            </table>
          </div>


          {/* end card box */}


          <div className="dataRow">
            <div className="row pagination-box">

              <div className="col-md-3 pagination-box">{"Items per Page : "}
                <select onChange={handlePageSizeChange} value={pageSize}>
                  {pageSizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select></div>

              <div className="col-md-3 table-title">  EXPENSES * </div>

              <div className="col-md-4"><Pagination

                count={count}
                page={page}
                siblingCount={1}
                boundaryCount={1}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />

              </div>

            </div>
            <div className="details">

              <div className="table-wrap">
                <table className="table ">
                  <thead className="scroll-thead">
                    <tr>

                      <th>ID</th>
                      <th>DATE</th>
                      <th>EXPENSE TYPE</th>
                      <th>AMOUNT</th>
                      <th>OTHER</th>
                      <th>USERNAME</th>
                      <th>STATUS</th>
                      <th>Action</th>


                    </tr>
                  </thead>
                  <tbody>
                    {tutorials &&
                      tutorials.map((tutorial, index) => (

                        <tr key={tutorial.id}>
                          <td>{tutorial.id}</td>
                          <td>{tutorial.date}</td>
                          <td>{tutorial.expenseType}</td>
                          <td>{tutorial.amount}</td>
                          <td>{tutorial.other}</td>
                          <td>{tutorial.username}</td>
                          <td><span className={"status " + tutorial.other}>{tutorial.other}</span></td>
                          <td><div className="table-icons-wrapper">

                            <button
                              type="button" className="table-buttons-l"
                              onClick={() => openObject(tutorial.id)}
                            > Details
                            </button>

                          </div></td>
                        </tr>

                      ))}
                  </tbody>

                </table>

              </div>

            </div>

          </div>
        </div>

      </div>

      <Modal
        size="md"
        backdrop="static"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >


        <Modal.Body className="modal-bg">

          <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Expenses  </div></center>

          <div className="dataRow-with-one-column-center">

            {/* col 1 */}
            <div className="details-small">

              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">


                    <div className="row">
                      <div className="col-md-5"> <hr /> </div>
                      <div className="col-md-2"><center> <span className="modal-title">SENDER</span> </center></div>
                      <div className="col-md-5"><hr /></div>
                    </div>
                    <div className="row">


                      <div className="col-md-4">
                        <label>Expense type</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="expenseType" className="form-control" name="expenseType" placeholder="Expense type" onChange={handleInputChange} validations={[required]} />
                      </div>
                      <div className="col-md-4">
                        <label>Purpose</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="number" id="amount" className="form-control" name="amount" placeholder="Amount" onChange={handleInputChange} validations={[required]} />
                      </div>
                      <div className="col-md-4">
                        <label>Remark</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input type="text" id="other" className="form-control" name="other" placeholder="Remark" onChange={handleInputChange} validations={[required]} />
                      </div>


                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <div className="col-md-8 form-group">

                        {submitted ? ("") : (<button onClick={saveData}
                          type="button" className="button-tools"><FaMoneyBillTransfer /> Save</button>)}

                        <button
                          type="button" onClick={() => setLgShow(false)} className="button-tools"><ImCancelCircle /> Close</button>
                        <br /> <br />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8">{message}</label>


                    </div>
                  </div>
                </form>
              </center>
            </div>


          </div>
        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
          <center> <div>Ananda</div> </center>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        backdrop="static"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >


        <Modal.Body className="modal-bg">

          <div className="row">
            <div className="ananda-modal-header">
              <table className=" table-tools">
                <tbody>
                  <tr>
                    <td className="tg-0lax" rowSpan="3">  <img src={logo} id="icon" alt="User Icon" className="ananda-modal-header-image" /></td>
                    <td className="tg-0lax">{organizationDetails.name}</td>
                    <td className="tg-0lax"></td>
                  </tr>

                  <tr>
                    <td className="tg-0lax">{organizationDetails.location} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>
                  <tr>
                    <td className="tg-0lax">{organizationDetails.phone}  | {organizationDetails.email} </td>
                    <td className="tg-0lax"></td>
                    <td className="tg-0lax"></td>
                  </tr>


                </tbody>
              </table>
            </div>

          </div>

          <center> <div className="col-md-4 ananda-modal-header-title"> Expenses  </div></center>

          <div className="dataRow-with-one-column-center">

            {/* col 1 */}
            <div className="details-small">

              <center>
                <form className="form form-horizontal ">
                  <div className="form-body">

                    <div className="row">
                      <div className="col-md-5"> <hr /> </div>
                      <div className="col-md-2"><center> <span className="modal-title">SENDER</span> </center></div>
                      <div className="col-md-5"><hr /></div>
                    </div>
                    <div className="row">


                      <div className="col-md-4">
                        <label>Expense type</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="expenseType" className="form-control" name="expenseType" placeholder="Expense type" onChange={handleInputChangeEdit} validations={[required]} value={currentData.expenseType} />
                      </div>
                      <div className="col-md-4">
                        <label>Purpose</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="number" id="amount" className="form-control" name="amount" placeholder="Amount" onChange={handleInputChangeEdit} validations={[required]} value={currentData.amount} />
                      </div>
                      <div className="col-md-4">
                        <label>Remark</label>
                      </div>
                      <div className="col-md-8 form-group">
                        <input disabled type="text" id="other" className="form-control" name="other" placeholder="Remark" onChange={handleInputChangeEdit} validations={[required]} value={currentData.other} />
                      </div>


                      <div className="col-md-4">
                        <label> </label>
                      </div>
                      <div className="col-md-8 form-group">



                        <button
                          type="button" onClick={() => setLgShow2(false)} className="button-tools"><ImCancelCircle /> Close</button>
                        <br /> <br />
                      </div>

                      <div className="col-md-4">
                        <label> </label>
                      </div>

                      <label className="col-md-8">{message}</label>


                    </div>
                  </div>
                </form>
              </center>
            </div>


          </div>
        </Modal.Body>
        <Modal.Footer className="ananda-modal-footer">
          <center> <div>Ananda</div> </center>
        </Modal.Footer>
      </Modal>




    </>
  );
};
